import React, { useState, useMemo, } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import MarkdownIt from 'markdown-it'
import hljs from 'highlight.js'
import mila from 'markdown-it-link-attributes'
import mdKatex from '@traptitech/markdown-it-katex'

import { actionCreators } from '../../store'
import styles from './index.module.css'
import userAvatar from '../../../../statics/avatar.png'
import userWomanAvatar from '../../../../statics/avatar_woman.png'
import aiAvatar from '../../../../statics/ai_assistant_online.png'
import loadingImg from '../../../../statics/loading.gif'


function AssistantMessageItem({ItemData}) {
    let user_data = useSelector(state => state.get('login').get('user_data')).toJS()
    let is_thinking = useSelector(state => state.get('home').get('is_thinking'))

    let user_avatar = user_data.avatar ? user_data.avatar : (user_data.gender === 'woman' ? userWomanAvatar : userAvatar)

    const chatAvatar = ({role, icon, isShow}) => {
        if (!isShow) return null

        return (
            <div className={`${role === 'user' ? styles.avatar_box_right : styles.avatar_box_left}`}>
                {
                    role === 'user' ? (
                        <>
                            <div className={styles.avatar_title}>
                                {
                                    user_data.nickname ? user_data.nickname : ''
                                }
                            </div>
                            <img src={icon} alt="" className={styles.avatar_img} />
                        </>
                    ) : (
                        <>
                            <img src={icon} alt="" className={styles.ai_avatar_img} />
                            <div className={styles.avatar_title}>
                                {
                                    "灵石"
                                }
                            </div>
                        </>
                    )
                }
            </div>
        )
    }

    const renderMdHtml = (value) => {
        function highlightBlock(str, lang, code) {
            return `<pre class="code-block-wrapper"><div class="code-block-header"><span class="code-block-header__lang">${lang}</span><span class="code-block-header__copy">复制代码</span></div><code class="hljs code-block-body ${lang}">${str}</code></pre>`
        }

        const mdi = new MarkdownIt({
            html: true,
            linkify: false,     // 禁用自动链接插件
            highlight(code, language) {
              const validLang = !!(language && hljs.getLanguage(language))
              if (validLang) {
                const lang = language ?? ''
                return highlightBlock(hljs.highlight(code, { language: lang }).value, lang, code)
              }
              return highlightBlock(hljs.highlightAuto(code).value, '', code)
            }
        })

        mdi.use(mila, { attrs: { target: '_blank', rel: 'noopener' } })
        mdi.use(mdKatex, { blockClass: 'katex-block', errorColor: ' #cc0000', output: 'mathml' })

        return mdi.render(value)
    }

    const renderText = (role, text, text_extra_img, text_extra_search, status) => {
        text = text + text_extra_img + text_extra_search
        let value = text || ''
        if (role === 'user') {
            return (
                <div className="markdown-body" style={{ whiteSpace: 'pre-wrap'}}>
                    { value }
                </div>
            )
        } else if (status === 'loading' && text === '' && role === 'ai') {
            return <img src={loadingImg} alt="loading..." className={styles.loading_img} />
        } else {
            let render_md_html = renderMdHtml(value)
            return (
                <div
                    className="markdown-body"
                    dangerouslySetInnerHTML={{
                        __html: render_md_html
                    }}
                >
                </div>
            )
        }
    }

    return (
        <div className={styles.container}>
            {
                useMemo(() => {
                    return chatAvatar({
                        role: ItemData.role,
                        icon: user_avatar,
                        isShow: ItemData.role === 'user',
                    })
                }, [])
            }

            {
                useMemo(() => {
                    return chatAvatar({
                        role: ItemData.role,
                        icon: aiAvatar,
                        isShow: ItemData.role === 'ai',
                    })
                }, [])
            }

            <div className={`${styles.content_box} ${ItemData.role === 'user' ? styles.content_box_right : styles.content_box_left} ${is_thinking && ItemData.status === 'loading' ? styles.content_thinking : ''}`}>
                <div
                    className={`
                        ${styles.content_text}
                        ${ItemData.role === 'user' ? styles.content_right : styles.content_left}
                    `}
                >
                    {
                        renderText(ItemData.role, ItemData.text, ItemData.text_extra_img, ItemData.text_extra_search, ItemData.status)
                    }
                </div>
            </div>
        </div>
    )
}

export default AssistantMessageItem