function getUrlVars() {
    var vars = [], hash;
    var hashes = window.location.href
        .slice(window.location.href.indexOf("?") + 1)
        .split("&");

    for (var i = 0; i < hashes.length; i++) {
        hash = hashes[i].split("=");
        vars.push(hash[0]);
        vars[hash[0]] = hash[1];
    }

    return vars;
}

function getOriginWithExplicitPort() {
    const protocol = window.location.protocol;
    let port = window.location.port;

    // 如果端口为空，则根据协议添加默认端口
    if (!port) {
        if (protocol === 'http:') {
            port = '80';
        } else if (protocol === 'https:') {
            port = '443';
        }
    }

    // 直接拼接 origin 和显式指定的端口
    return `${protocol}//${window.location.hostname}:${port}`;
}

function isDevelopment() {
    // 检测在开发环境还是在生产环境下，然后使用不同的企业微信登录
    const currentUrl = window.location.href;

    // 检查 URL 是否包含 'test'
    if (currentUrl.includes('test')) {
      return true
    } else {
      return false
    }
}

function isQYWechatAppEnvironment() {
    const userAgent = navigator.userAgent;
    // 检查是否为企业微信内嵌浏览器
    const isEnterpriseWeChat = userAgent.match(/wxwork/i);

    return !!isEnterpriseWeChat
}


export {
    getUrlVars,
    getOriginWithExplicitPort,
    isDevelopment,
    isQYWechatAppEnvironment,
}
