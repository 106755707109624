import { useEffect, useState, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Modal, Card, Space, Spin, Table, Input } from 'antd'
import { ExclamationCircleOutlined, CheckCircleTwoTone, LoadingOutlined, CloseCircleTwoTone } from '@ant-design/icons';

import styles from './index.module.css'
import dollarImg from '../../../../statics/overview_shopping_dollar_icon.png'
import { actionCreators } from '../../store'
// import ComponentListedSingleModuleModal from '../ComponentListedSingleModuleModal'
import ComponentListedModalAdd from '../ComponentListedModalAdd'
import ComponentListedModalEdit from '../ComponentListedModalEdit'
import ComponentListedModalDocContentAdd from '../ComponentListedModalDocContentAdd'
import { componentDocumentIcons, convertUTCToLocalTime, convertBytesToMB } from '../../../../utils'
import ComponentListContentSlice from '../ComponentListContentSlice'
import addIcon from '../../../../statics/components_list_add_icon.png'
import ComponentListedPublish from '../ComponentListedPublish'
import editImg from '../../../../statics/card_box_edit.png'
import deleteImg from '../../../../statics/card_box_delete.png'
import publishImg from '../../../../statics/card_box_publish.png'


const ComponentList = ({setIsModalOpen, setIsEditModalOpen, setDetailList, setPageStatus}) => {
    const dispatch = useDispatch()
    let component_document_listed = useSelector(state => state.get('admin').get('component_document_listed'))
    // const [componentsList, setComponentsList] = useState([])
    const [unpublishedList, setUnpublishedList] = useState([])
    const [publishedList, setPublishedList] = useState([])

    useEffect(() => {
        let unpublish_list = []
        let publish_list = []
        component_document_listed.toJS().forEach(element => {
            if (element.is_active) {
                publish_list.push(element)
            } else {
                unpublish_list.push(element)
            }
        });

        setUnpublishedList(unpublish_list)
        setPublishedList(publish_list)
    }, [component_document_listed])

    const handleOpenModal = () => {
        dispatch(actionCreators.currentEditComponentType('document_kb'))
        setIsModalOpen(true)
    }

    let currentComponent = useMemo(() => {
        let list = component_document_listed.toJS()
        let obj = list.find(item => item.has_select === true)
        return obj ? obj : {}
    }, [component_document_listed])

    const handleEdit = (e, name) => {
        e.stopPropagation(); // 防止点击事件冒泡到 Menu.Item
        dispatch(actionCreators.currentEditComponentName(name))
        dispatch(actionCreators.currentEditComponentType('document_kb'))
        setIsEditModalOpen(true)
    }

    const handleDelete = (e, name) => {
        e.stopPropagation(); // 防止点击事件冒泡到 Menu.Item

        Modal.confirm({
            title: '删除组件',
            icon: <ExclamationCircleOutlined />,
            content: '请确认是否要删除该组件以及该组件的相关文件？',
            okText: '确认',
            cancelText: '取消',
            onOk() {
                let data = {
                    name: name
                }
                dispatch(actionCreators.deleteAdminSingleComponent(data))
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    const handleDetail = (e, name) => {
        e.stopPropagation(); // 防止点击事件冒泡到 Menu.Item
        dispatch(actionCreators.currentEditComponentName(name))
        dispatch(actionCreators.currentEditComponentType('document_kb'))

        dispatch(actionCreators.getComponentContentList(name))
            .then((data) => { setDetailList(data.data) })
            .catch((err) => {console.log(err);})
            .finally(() => {})
        setPageStatus('detail')
    }

    const handlePublish = (e, id) => {
        e.stopPropagation(); // 防止点击事件冒泡到 Menu.Item
        handleClickComponent(id)
        dispatch(actionCreators.currentEditComponentType('document_kb'))
        setPageStatus('publish')
    }

    const handleClickComponent = (id) => {
        let list = component_document_listed.toJS()
        let new_list = list.map((item) => {
            if (item.id === id) {
                item.has_select = true
            } else {
                item.has_select = false
            }
            return item
        })
        dispatch(actionCreators.componentDocumentListedAction(new_list))
    }

    return (
        <div className={styles.container_component_content}>
            <div className={styles.container_content_left}>
                <div className={styles.left_components_title}>未上架</div>
                <div className={styles.left_components}>
                    {
                        unpublishedList.map((item) => {
                            return (
                                <div
                                    className={styles.shopping_model_item_box}
                                    key={item.id}
                                    onClick={(e) => {handleDetail(e, item.name)}}
                                >
                                    <div
                                        className={styles.shopping_model_item}
                                    >
                                        <div className={styles.shopping_model_img_box}>
                                            <img src={item.avatar} className={styles.shopping_model_img} alt="icon" />
                                        </div>

                                        <div className={styles.shopping_model_text_box}>
                                            <div className={styles.shopping_model_text_title}>
                                                {item.name}
                                            </div>
                                            <div className={styles.shopping_model_text_info}>
                                                {item.overview}
                                            </div>
                                        </div>

                                        <div className={styles.action_list}>
                                            <div className={styles.card_action_img_box}>
                                                <img
                                                    src={editImg}
                                                    className={styles.card_action_img}
                                                    alt="edit"
                                                    onClick={(e) => {handleEdit(e, item.name)}}
                                                />
                                            </div>
                                            <div className={styles.card_action_img_box}>
                                                <img
                                                    src={deleteImg}
                                                    className={styles.card_action_img}
                                                    alt="delete"
                                                    onClick={(e) => {handleDelete(e, item.name)}}
                                                />
                                            </div>
                                            <div className={styles.card_action_img_box}>
                                                <img
                                                    src={publishImg}
                                                    className={styles.card_action_img}
                                                    alt="publish"
                                                    onClick={(e) => {handlePublish(e, item.id)}}
                                                />
                                            </div>
                                            {/* <Button onClick={(e) => {handleEdit(e, item.name)}}>编辑</Button>
                                            <Button onClick={(e) => {handleDelete(e, item.name)}}>删除</Button>
                                            <Button onClick={(e) => {handlePublish(e, item.id)}}>发布</Button> */}
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }

                    <div
                        className={styles.components_add}
                        onClick={handleOpenModal}
                    >
                        <img src={addIcon} alt="add icon" className={styles.components_add_img} />
                    </div>
                </div>
            </div>

            <div className={styles.container_content_right}>
                <div className={styles.left_components_title}>已上架</div>
                <div className={styles.left_components}>
                    {
                        publishedList.map((item) => {
                            return (
                                <div
                                    className={styles.shopping_model_item_box}
                                    key={item.id}
                                    onClick={(e) => {handleDetail(e, item.name)}}
                                >
                                    <div
                                        className={styles.shopping_model_item}
                                    >
                                        <div className={styles.shopping_model_img_box}>
                                            <img src={item.avatar} className={styles.shopping_model_img} alt="icon" />
                                        </div>

                                        <div className={styles.shopping_model_text_box}>
                                            <div className={styles.shopping_model_text_title}>
                                                {item.name}
                                            </div>
                                            <div className={styles.shopping_model_text_info}>
                                                {item.overview}
                                            </div>
                                        </div>

                                        <div className={styles.action_list}>
                                            <div className={styles.card_action_img_box}>
                                                <img
                                                    src={editImg}
                                                    className={styles.card_action_img}
                                                    alt="edit"
                                                    onClick={(e) => {handleEdit(e, item.name)}}
                                                />
                                            </div>
                                            <div className={styles.card_action_img_box}>
                                                <img
                                                    src={deleteImg}
                                                    className={styles.card_action_img}
                                                    alt="delete"
                                                    onClick={(e) => {handleDelete(e, item.name)}}
                                                />
                                            </div>
                                            {/* <Button onClick={(e) => {handleEdit(e, item.name)}}>编辑</Button>
                                            <Button onClick={(e) => {handleDelete(e, item.name)}}>删除</Button> */}
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }

                    {/* <div
                        className={styles.components_add}
                        onClick={handleOpenModal}
                    >
                        <img src={addIcon} alt="add icon" className={styles.components_add_img} />
                    </div> */}
                </div>
            </div>
        </div>
    )
}

const ComponentListDetailTable = ({setPageStatus, detailList, setDetailList, setSliceList, setIsModalOpen, setSliceFileName}) => {
    const columns = [
        {
            title: '文件名称',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '文件格式',
            dataIndex: 'file_ext',
            key: 'file_ext',
        },
        {
            title: '文件大小',
            render: (_, record) => (
                <span>{`${convertBytesToMB(record.size)} MB`}</span>
            ),
            sorter: {
                compare: (a, b) => {
                    if (a.size > b.size) {
                        return 1
                    } else if (a.size < b.size) {
                        return -1
                    } else {
                        return 0
                    }
                },
            },
            dataIndex: 'size',
            key: 'size',
        },
        {
            title: '上传时间',
            render: (_, record) => (
                <span>{`${convertUTCToLocalTime(record.created_at)}`}</span>
            ),
            sorter: {
                compare: (a, b) => {
                    if (a.created_at > b.created_at) {
                        return 1
                    } else if (a.created_at < b.created_at) {
                        return -1
                    } else {
                        return 0
                    }
                },
            },
            key: 'created_at',
        },
        {
            title: '更新时间',
            render: (_, record) => (
                <span>{`${convertUTCToLocalTime(record.updated_at)}`}</span>
            ),
            sorter: {
                compare: (a, b) => {
                    if (a.updated_at > b.updated_at) {
                        return 1
                    } else if (a.updated_at < b.updated_at) {
                        return -1
                    } else {
                        return 0
                    }
                },
            },
            key: 'updated_at',
        },
        {
            title: '文件版本',
            dataIndex: 'file_version',
            render: (_, record) => (
                <span>{`第 ${record.file_version} 版文件`}</span>
            ),
            sorter: {
                compare: (a, b) => {
                    if (a.file_version > b.file_version) {
                        return 1
                    } else if (a.file_version < b.file_version) {
                        return -1
                    } else {
                        return 0
                    }
                },
            },
            key: 'file_version',
        },
        {
            title: '状态',
            key: 'has_done',
            render: (_, record) => {
                let status_list = ['Failed', 'Cancelled']
                if (record.status === 'Success') {
                    return (
                        <Space>
                            <CheckCircleTwoTone twoToneColor="#52c41a" />
                            <span>导入完成</span>
                        </Space>
                    )
                } else if (status_list.includes(record.status)) {
                    return (
                        <Space>
                            <CloseCircleTwoTone twoToneColor="#f00" />
                            <span>导入失败</span>
                        </Space>
                    )
                } else {
                    return (
                        <Space>
                            <Spin indicator={<LoadingOutlined spin />} size="small" />
                            <span>导入中...</span>
                        </Space>
                    )
                }
            }
        },
        {
            title: '操作',
            // dataIndex: 'gender',
            key: 'id',
            render: (_, record) => (
                <Space size="middle">
                    <a onClick={() => {handleOrigin()}}>原文详情</a>
                    <a onClick={() => {handleSlice(record.name)}}>切片详情</a>
                    <a onClick={() => {handleDelete(record.name)}}>删除</a>
                </Space>
            ),
        },
    ];

    const dispatch = useDispatch()
    const intervalRef = useRef(null);
    const [baseList, setBaseList] = useState([])
    const [searchText, setSearchText] = useState('')
    let current_edit_component_name = useSelector(state => state.get('admin').get('current_edit_component_name'))

    useEffect(() => {
        // 清除之前的定时任务
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }

        let filter_file_list = detailList.filter(item =>
            item.name.includes(searchText)
        )
        setBaseList(filter_file_list)

        // 检查是否有文件的 progress 不等于 100
        let status_list = ['Success', 'Failed', 'Cancelled']
        const hasIncompleteFiles = detailList.some(file => !status_list.includes(file.status))
        if (hasIncompleteFiles) {
            // 如果有未完成的文件，则设置一个新的定时器
            intervalRef.current = setInterval(() => {
                dispatch(actionCreators.getComponentContentList(current_edit_component_name))
                    .then((data) => { setDetailList(data.data) })
                    .catch((err) => {console.log(err);})
                    .finally(() => {})
            }, 5000) // 5秒钟轮询一次
        }

        // 组件卸载时清除定时器
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        }
    }, [detailList])

    const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    }

    const handleOrigin = () => {
        console.log("原文详情");
    }

    const handleSlice = (file_name) => {
        setPageStatus('slice')
        setSliceFileName(file_name)
        dispatch(actionCreators.getComponentContentList(current_edit_component_name, file_name))
        .then((data) => { setSliceList(data.data.docs) })
        .catch((err) => {console.log(err);})
        .finally(() => {})
    }

    const handleDelete = (file_name) => {
        console.log("删除");

        Modal.confirm({
            title: '删除文件',
            icon: <ExclamationCircleOutlined />,
            content: '请确认是否要删除文件："' + file_name + '" ？',
            okText: '确认',
            cancelText: '取消',
            onOk() {
                console.log('OK');
                let d = {
                    component_name: current_edit_component_name,
                    filenames: [file_name],
                }
                dispatch(actionCreators.deleteComponentContent(d))
                    .then((data) => {
                        dispatch(actionCreators.getComponentContentList(current_edit_component_name))
                            .then((data) => { setDetailList(data.data) })
                            .catch((err) => {console.log(err);})
                            .finally(() => {})
                    })
                    .catch((e) => {console.log(e);})
                    .finally(() => {})
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    const handleReback = () => {
        setPageStatus('component')
        setDetailList([])
    }

    const handleAdd = () => {
        setIsModalOpen(true)
    }

    const handleSearch = () => {
        const filteredData = detailList.filter((item) =>
            item.name.includes(searchText.trim())
        );
        setBaseList(filteredData);
    }

    const handleChange = (e) => {
        let value = e.target.value
        setSearchText(e.target.value)

        const filteredData = detailList.filter((item) =>
            item.name.includes(value.trim())
        )
        setBaseList(filteredData)
    }

    return (
        <div className={styles.container_detail_list_main}>
            <div className={styles.container_action_list}>
                <Button onClick={handleReback}>返回</Button>
                <Button type="primary" onClick={handleAdd}>添加</Button>
                <Input.Search
                    placeholder="要查询的文件名"
                    value={searchText}
                    onSearch={handleSearch}
                    onChange={handleChange}
                    style={{ width: 200, marginBottom: 8 }}
                />
            </div>
            <div className={styles.container_detail_list_box}>
                <Table
                    style={{width: '100%'}}
                    columns={columns}
                    dataSource={baseList}
                    onChange={onChange}
                />
            </div>
        </div>
    )
}

export default function ComponentListedDocument() {
    const dispatch = useDispatch()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isEditModalOpen, setIsEditModalOpen] = useState(false)
    const [isContentModalOpen, setIsContentModalOpen] = useState(false)
    const [pageStatus, setPageStatus] = useState('component')       // component detail slice publish
    const [detailList, setDetailList] = useState([])
    const [sliceList, setSliceList] = useState([])
    const [sliceFileName, setSliceFileName] = useState('')
    let current_edit_component_name = useSelector(state => state.get('admin').get('current_edit_component_name'))

    useEffect(() => {
        dispatch(actionCreators.getComponentListed('document_kb'))
        dispatch(actionCreators.getComponentListed('picture_kb'))
        dispatch(actionCreators.getComponentCategory())
    }, [])

    const pageMap = {
        component: <ComponentList
            setIsModalOpen={setIsModalOpen}
            setIsEditModalOpen={setIsEditModalOpen}
            setDetailList={setDetailList}
            setPageStatus={setPageStatus}
        />,
        detail: <ComponentListDetailTable
            setPageStatus={setPageStatus}
            detailList={detailList}
            setDetailList={setDetailList}
            setSliceList={setSliceList}
            setIsModalOpen={setIsContentModalOpen}
            setSliceFileName={setSliceFileName}
        />,
        slice: <ComponentListContentSlice
            setPageStatus={setPageStatus}
            sliceList={sliceList}
            setSliceList={setSliceList}
        />,
        publish: <ComponentListedPublish
            setPageStatus={setPageStatus}
        />,
    };

    return (
        <div className={styles.main}>
            <div className={styles.container}>
                <div className={styles.container_header}>
                    {
                        pageStatus === 'detail' ? (current_edit_component_name) : (
                            pageStatus === 'slice' ? (sliceFileName) : "文本组件"
                        )
                    }
                </div>

                <div className={styles.container_content}>
                    { pageMap[pageStatus] || null }
                </div>
            </div>

            <ComponentListedModalAdd
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                currentComponentType={'document_kb'}
            />
            <ComponentListedModalEdit
                isModalOpen={isEditModalOpen}
                setIsModalOpen={setIsEditModalOpen}
                currentComponentType={'document_kb'}
            />
            <ComponentListedModalDocContentAdd
                isModalOpen={isContentModalOpen}
                setIsModalOpen={setIsContentModalOpen}
                setDetailList={setDetailList}
            />
            {/* <ComponentListedSingleModuleModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                currentComponent={currentComponent}
            /> */}
        </div>
    )
}