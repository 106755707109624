import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button } from 'antd'

import styles from './index.module.css'
import GroupPermissionTable from '../GroupPermissionTable'
import GroupPermissionPersonal from '../GroupPermissionPersonal'
import GroupPermissionAddModal from '../GroupPermissionAddModal'
import { actionCreators } from '../../store'

function GroupPermissionManagement() {
    let permission_group_page = useSelector(state => state.get('admin').get('permission_group_page'))
    const dispatch = useDispatch()
    const [isModalOpen, setIsModalOpen] = useState(false)

    const handleShowModal = () => {
        setIsModalOpen(true)
    }

    const handleCloseModal = () => {
        setIsModalOpen(false)
    }

    useEffect(() => {
        dispatch(actionCreators.getUsersPermission())
        dispatch(actionCreators.getGroupsPermissions())
    }, [])

    return (
        <div className={styles.main}>
            <div className={styles.header}>
                <div className={styles.title}>
                    群组权限管理
                </div>
            </div>

            <div className={styles.container}>
                {
                    permission_group_page === 'table' ? (
                        <>
                            <div className={styles.add_box}>
                                <Button
                                    type="primary"
                                    onClick={handleShowModal}
                                >
                                    添加新的群组
                                </Button>
                            </div>
                            <GroupPermissionTable />
                        </>
                    ) : (
                        <GroupPermissionPersonal />
                    )
                }
            </div>

            <GroupPermissionAddModal
                isModalOpen={isModalOpen}
                handleCloseModal={handleCloseModal}
            />
        </div>
    )
}

export default GroupPermissionManagement