import React, { Component } from "react";

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const MyZoomPanPinch = ({ img_url }) => {
    return (
        <div style={{ width: '700px', height: '500px', margin: '0 auto', border: '1px solid #ccc' }}>
            <TransformWrapper
                initialScale={1}
                centerOnInit={true}
                doubleClick={{
                    disabled: true, // 禁用双击缩放
                }}
            >
                <TransformComponent>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '700px',
                            height: '500px',
                        }}
                    >
                        <img
                            src={img_url}
                            style={{maxWidth: '100%', maxHeight: '100%', display: 'block'}}
                            alt="Zoomable"
                        />
                    </div>
                </TransformComponent>
            </TransformWrapper>
        </div>
    );
};

export default MyZoomPanPinch