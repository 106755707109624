import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Select, message, Form, Button, Input } from 'antd'

import { actionCreators } from '../../store'
import styles from './index.module.css'


const ModelManagement = () => {
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const [creating, setCreating] = useState(false)

    let knowledge_base_options = useSelector(state => state.get('admin').get('knowledge_base_options')).toJS()

    useEffect(() => {
        dispatch(actionCreators.getKnowledgeBaseConfig())
        .then(data => {
            form.setFieldsValue({
                llm_name: data.data.current_llm_name,
                min_match_score: data.data.min_match_score.toString(),
            })
        })
        .catch(error => {})
        .finally(() => {})
    }, [])


    const onFinish = (values) => {
        console.log('Success:', values);
        let llm_name = values.llm_name.trim()
        let min_match_score = values.min_match_score.trim()


        if (!isNaN(min_match_score) && min_match_score.trim() !== "") {
            let number = Number(min_match_score);
            console.log("number = ", number);
            if (0 <= number && number <= 1) {
                setCreating(true)
                dispatch(actionCreators.updateKnowledgeBaseConfig(llm_name, number))
                    .then(data => {})
                    .catch(error => {})
                    .finally(() => {setCreating(false)})
            } else {
                message.info("输入无效，请输入 0 ~ 1 之间的数字")
            }
        } else {
            message.info("输入无效，请输入 0 ~ 1 之间的数字")
        }
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    }

    return (
        <div className={styles.main}>
            <div className={styles.header}>
                <div className={styles.title}>
                    自建知识库配置项
                </div>
            </div>

            <div className={styles.container}>
                <Form
                    form={form}
                    name="basic"
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 16,
                    }}
                    style={{
                        width: '100%',
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    preserve={false}
                >
                    <Form.Item
                        label="自建知识库选择大模型"
                        name="llm_name"
                        rules={[{ required: true, message: '自建知识库选择大模型' }]}
                    >
                        <Select
                            style={{
                                width: '50%',
                            }}
                            onChange={(value) => { console.log("选择的数据为 ", value); }}
                            options={knowledge_base_options}
                        />
                    </Form.Item>

                    <Form.Item
                        label="问答出图最小匹配分数"
                        name="min_match_score"
                        rules={[{ required: true, message: '问答出图最小匹配分数' }]}
                    >
                        <Input
                            style={{
                                width: '50%',
                            }}
                            placeholder="最小匹配分数：0 ~ 1 之间"
                        />
                    </Form.Item>

                    <Form.Item
                        wrapperCol={{
                            offset: 8,
                            span: 16,
                        }}
                    >
                        {
                            creating ? (
                                <Button type="primary" loading>
                                    创建中
                                </Button>
                            ) : (
                                <Button type="primary" htmlType="submit">
                                    创建
                                </Button>
                            )
                        }
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}

export default ModelManagement;