import React, { Component, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Input, Button, Form, DatePicker, TimePicker, message } from 'antd';

import styles from './index.module.css'
import arrowImg from '../../../../statics/update_done_butn_arrow_forward.png'
import { actionCreators } from '../../store';

function GroupPermissionAddModal({ isModalOpen, handleCloseModal }) {
    const dispatch = useDispatch()
    const [uploading, setUploading] = useState(false)

    const onFinish = (values) => {
        console.log('Success:', values);
        let group_name = values.group_name.trim()
        let description = values.description.trim()

        setUploading(true)
        if (group_name === '') {
            message.info("群组名不能为空！")
        } else if (description === '') {
            message.info("群组描述不能为空！")
        } else {
            dispatch(actionCreators.createGroup(group_name, description))
                .then(data => { handleCloseModal() })
                .catch(error => {})
                .finally(() => { setUploading(false) })
        }
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    }

    return (
        <>
            <Modal
                title="添加新的群组"
                open={isModalOpen}
                onOk={() => {handleCloseModal()}}
                onCancel={() => {handleCloseModal()}}
                // okText="确认"
                // cancelText="取消"
                footer={false}
                destroyOnClose={true}
            >
                <Form
                    name="basic"
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 16,
                    }}
                    style={{
                        maxWidth: 600,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    preserve={false}
                >
                    <Form.Item
                        label="群组名"
                        name="group_name"
                        rules={[
                            {
                                required: true,
                                message: '请输入群组名',
                            },
                        ]}
                    >
                        <Input placeholder="请输入群组名" />
                    </Form.Item>

                    <Form.Item
                        label="群组描述"
                        name="description"
                        rules={[
                            {
                                required: true,
                                message: '请输入群组的描述',
                            },
                        ]}
                    >
                        <Input placeholder="请输入群组的描述" />
                    </Form.Item>

                    <Form.Item
                        wrapperCol={{
                            offset: 8,
                            span: 16,
                        }}
                    >
                        {
                            uploading ? (
                                <Button type="primary" loading>
                                    Uploading
                                </Button>
                            ) : (
                                <Button type="primary" htmlType="submit">
                                    添加
                                </Button>
                            )
                        }
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default GroupPermissionAddModal
