import React from 'react';
import styles from './index.module.css'


const MyButton = ({ height, width, onClick, text, type='default' }) => {
    let current_style = ''
    if (type === 'primary') {
        current_style = styles.primary
    } else if (type === 'light') {
        current_style = styles.light
    } else {
        // default
        current_style = styles.default
    }
    return (
        <div
            className={styles.butn_box}
            style={{ height: height, width: width }}
        >
            <div
                className={`${styles.butn} ${current_style}`}
                style={{ height: height, width: width }}
                onClick={onClick}
            >
                {
                    text
                }
            </div>
        </div>
    )
}

export default MyButton