import React, { Component, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Input, Button, Form, message, Upload, Select, Checkbox } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import styles from './index.module.css'
import { actionCreators } from '../../store';

function ComponentListedModalDocContentAdd({isModalOpen, setIsModalOpen, setDetailList}) {
    const dispatch = useDispatch()
    let current_edit_component_name = useSelector(state => state.get('admin').get('current_edit_component_name'))
    const [creating, setCreating] = useState(false)
    const [fileList, setFileList] = useState([])
    const [form] = Form.useForm();

    const onFinish = (values) => {
        console.log('Success:', values);

        let formData = new FormData();
        formData.append('component_name', current_edit_component_name)
        formData.append('file', fileList[0].originFileObj)

        setCreating(true)
        dispatch(actionCreators.createComponentDocumentContent(formData))
            .then(data => {
                dispatch(actionCreators.getComponentContentList(current_edit_component_name))
                .then((data) => { setDetailList(data.data) })
                .catch((err) => {console.log(err);})
                .finally(() => {})
                handleCloseModalLocal()
            })
            .catch(error => {})
            .finally(() => {setCreating(false)})
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    }

    const handleCloseModalLocal = () => {
        form.resetFields();
        setFileList([])
        setIsModalOpen(false)
    }

    // 在文件选择后更新 fileList，但不执行上传操作
    const beforeUpload = (file) => {
        setFileList((prevFileList) => [...prevFileList, file]);
        return false; // 返回 false 以停止自动上传
    }

    // 移除文件
    const handleRemove = (file) => {
        setFileList((prevFileList) => prevFileList.filter(item => item.uid !== file.uid));
    }

    const handleChange = ({ fileList: newFileList }) => {
        // 如果已经有文件存在，替换为最新的文件
        const latestFile = newFileList.slice(-1);
        setFileList(latestFile);
    }

    return (
        <>
            <Modal
                title={"往组件中添加新的文本"}
                open={isModalOpen}
                onOk={handleCloseModalLocal}
                onCancel={handleCloseModalLocal}
                // okText="确认"
                // cancelText="取消"
                footer={false}
                destroyOnClose={true}
            >
                <Form
                    name="basic"
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 16,
                    }}
                    style={{
                        maxWidth: 600,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    preserve={false}
                    form={form}
                >
                    <Form.Item
                        label="文本名称"
                        name="model_name"
                    >
                        <div>{current_edit_component_name}</div>
                    </Form.Item>


                    <Form.Item
                        label="上传文件"
                        name="upload_files"
                        rules={[{ required: true, message: '请选择要上传的文件' }]}
                    >
                        <Upload.Dragger
                            fileList={fileList}
                            beforeUpload={beforeUpload}
                            onRemove={handleRemove}
                            onChange={handleChange}
                            // multiple={true}
                            accept={".pdf,.doc,.docx,.xlsx,.xls"}
                        >
                            <p className="ant-upload-drag-icon">
                                <UploadOutlined />
                            </p>
                            <p className="ant-upload-text">
                                支持 .pdf, .doc, .docx, .xlsx, .xls 等格式
                            </p>
                            <p className="ant-upload-text">
                                点击或拖拽文件到此区域上传文件
                            </p>
                        </Upload.Dragger>
                    </Form.Item>

                    <Form.Item
                        wrapperCol={{
                            offset: 8,
                            span: 16,
                        }}
                    >
                        {
                            creating ? (
                                <Button type="primary" loading>
                                    创建中
                                </Button>
                            ) : (
                                <Button type="primary" htmlType="submit">
                                    创建
                                </Button>
                            )
                        }
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default ComponentListedModalDocContentAdd