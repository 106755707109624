import { combineReducers } from 'redux-immutable'

import { reducer as homeReducer } from '../pages/home/store'
import { reducer as loginReducer } from '../pages/login/store'
import { reducer as collectReducer } from '../pages/collect/store'
import { reducer as adminReducer } from '../pages/admin/store'

const reducer = combineReducers({
    home: homeReducer,
    login: loginReducer,
    collect: collectReducer,
    admin: adminReducer,
})

export default reducer