import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button } from 'antd'

import styles from './index.module.css'
import VersionTable from '../VersionTable'
import { actionCreators } from '../../store'
import VersionUpdateModal from '../VersionUpdateModal'
import VersionUpdateImageModal from '../VersionUpdateImageModal'

function VersionUpdateInfo() {
    const dispatch = useDispatch()
    const [isModalOpen, setIsModalOpen] = useState(false)

    useEffect(() => {
        console.log("获取版本更新列表");
        dispatch(actionCreators.fetchVersionUpdateList())
    }, [])

    const handleShowModal = () => {
        setIsModalOpen(true)
    }

    const handleCloseModal = () => {
        setIsModalOpen(false)
    }

    return (
        <div className={styles.main}>
            <div className={styles.header}>
                <div className={styles.title}>
                    版本更新信息管理
                </div>
            </div>

            <div className={styles.container}>
                <div className={styles.add_box}>
                    <Button
                        type="primary"
                        onClick={handleShowModal}
                    >
                        添加新的版本更新信息
                    </Button>
                </div>

                <div className={styles.table_box}>
                    <VersionTable />
                </div>
            </div>

            <VersionUpdateModal
                isModalOpen={isModalOpen}
                handleCloseModal={handleCloseModal}
            />
            <VersionUpdateImageModal />
        </div>
    )
}

export default VersionUpdateInfo