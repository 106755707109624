import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Modal, Input } from 'antd'

import { actionCreators as homeActionCreators } from '../../pages/home/store'
import styles from './index.module.css'
import bgImg from '../../statics/feedback_done_bg.png'
import MyButton from '../../common/MyButton'


class ModalFeedbackDone extends Component {
    handleOk() {
        this.props.handleIsFeedbackDoneModalOpenAction(false)
    }

    handleCancel() {
        this.props.handleIsFeedbackDoneModalOpenAction(false)
    }

    render() {
        return (
            <Modal
                open={this.props.isFeedbackDoneModalOpen}
                onOk={this.handleOk.bind(this)}
                onCancel={this.handleCancel.bind(this)}
                footer={null}
                width={560}
                className={styles.modal_container}
                closable={false}
            >
                <div className={styles.main}>
                    <div className={styles.header}>
                        <div className={styles.header_title}>感谢您的许愿</div>
                        <div className={styles.header_explain}>
                            感谢您宝贵的愿望！我们将会积极改进灵石。
                        </div>
                    </div>

                    <div className={styles.section}>
                        <img src={bgImg} className={styles.img} />
                    </div>

                    <div className={styles.footer}>
                        <MyButton
                            width={'100%'}
                            height={'48px'}
                            onClick={this.handleOk.bind(this)}
                            text="完成"
                            type="primary"
                        />
                    </div>
                </div>
          </Modal>
        )
    }

    onTextareaChange(e) {
        console.log('Change:', e.target.value)
    }
}

const mapStateToProps = (state) => {
    return {
        isFeedbackDoneModalOpen: state.get('home').get('isFeedbackDoneModalOpen'),
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleIsFeedbackDoneModalOpenAction(status) {
            dispatch(homeActionCreators.isFeedbackDoneModalOpenAction(status))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalFeedbackDone)