import { useState, useEffect } from 'react'

import { useSelector, useDispatch } from 'react-redux'
import { Button, Form, Input, Space, Select, Table, Checkbox } from 'antd'
import { actionCreators } from '../../store'
import styles from './index.module.css'
import { convertPermissionsToTableData, convertTableDataToPermissions } from '../../../../utils'


function PermissionsTable({ permissionData, handlePermissionChange }) {
    const columns = [
        {
            title: '资源',
            dataIndex: 'resource',
        },
        {
            title: 'read',
            dataIndex: 'read',
            render: (_, record) => (
                <Checkbox
                    checked={record.permissions.read}
                    onChange={() => handlePermissionChange(record.key, 'read')}
                />
            ),
        },
        {
            title: 'write',
            dataIndex: 'write',
            render: (_, record) => (
                <Checkbox
                    checked={record.permissions.write}
                    onChange={() => handlePermissionChange(record.key, 'write')}
                />
            ),
        },
        {
            title: 'execute',
            dataIndex: 'execute',
            render: (_, record) => (
                <Checkbox
                    checked={record.permissions.execute}
                    onChange={() => handlePermissionChange(record.key, 'execute')}
                />
            ),
        },
        {
            title: 'delete',
            dataIndex: 'delete',
            render: (_, record) => (
                <Checkbox
                    checked={record.permissions.delete}
                    onChange={() => handlePermissionChange(record.key, 'delete')}
                />
            ),
        },
    ];

    return <Table columns={columns} dataSource={permissionData} />;
};


function GroupPermissionPersonal() {
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const [groupInfo, setGroupInfo] = useState({})
    const [permissionData, setPermissionData] = useState([]);
    const [options, setOptions] = useState([])
    const [users, setUsers] = useState([])
    let permission_group_list = useSelector(state => state.get('admin').get('permission_group_list'))
    let permission_group_name = useSelector(state => state.get('admin').get('permission_group_name'))
    let permission_user_list = useSelector(state => state.get('admin').get('permission_user_list'))

    useEffect(() => {
        let list = permission_group_list.toJS()
        let arr = list.filter(item => item.name === permission_group_name)
        if (arr) {
            let obj = arr[0]
            setGroupInfo(obj)
            setPermissionData(convertPermissionsToTableData(obj.permissions))
            let userValues = obj.users.map(item => { return item.uuid })
            if (obj.users.includes("*")) {
                console.log("permission_user_list userValues = ", userValues);
            } else {
                console.log("obj.groups = ", userValues);
                setUsers(userValues)
                // 设置 form 里面的值
                form.setFieldsValue({
                    user: userValues
                })
            }
        }
    }, [permission_group_list, form])

    useEffect(() => {
        let list = permission_user_list.toJS()
        let a = []
        list.forEach((item) => {
            a.push({label: item.username, value: item.uuid})
        })
        setOptions(a)
    }, [permission_user_list])

    useEffect(() => {
        let a = permission_group_list.toJS().filter(item => item.name === permission_group_name)
        if (a) {
            let obj = a[0]
            if (obj.users.includes("*")) {
                // 包含全部的组
                let v = options.map((item) => { return item.value })
                setUsers(v)
                // 设置 form 里面的值
                form.setFieldsValue({
                    user: v
                })
            }
        }
    }, [options])

    const handlePermissionChange = (permissionKey, permissionType) => {
        // 更新数据源中的权限信息
        const newData = permissionData.map((record) => {
            if (record.key === permissionKey) {
                return {
                ...record,
                permissions: {
                    ...record.permissions,
                    [permissionType]: !record.permissions[permissionType],
                },
                };
            }

            return record;
        });

        console.log("newData = ", newData);
        setPermissionData(newData);
    }

    const handleGoBack = () => {
        dispatch(actionCreators.setPermissionGroupPageAction('table'))
        dispatch(actionCreators.setPermissionGroupIdAction(''))
    }

    const handleFormOnFinish = (values) => {
        console.log("values = ", values);
        let new_uuids = values.user
        let old_uuids = groupInfo.users.includes("*") ? options.map((item) => { return item.value }) : groupInfo.users.map(item => { return item.uuid })
        console.log("new_uuids = ", new_uuids);
        console.log("old_uuids = ", old_uuids);
        if (JSON.stringify(new_uuids) !== JSON.stringify(old_uuids)) {
            dispatch(actionCreators.updateGroupUsers(permission_group_name, new_uuids))
        }

        let p = convertTableDataToPermissions(permissionData)
        console.log("permissionData = ", permissionData);
        console.log("p = ", p);
        console.log("groupInfo.permissions = ", groupInfo.permissions);
        if (JSON.stringify(p) !== JSON.stringify(groupInfo.permissions)) {
            dispatch(actionCreators.updateGroupPermissions(permission_group_name, p))
        }
    }

    const handleFormOnFinishFailed = (errorInfo) => {
        console.log(errorInfo);
    }

    return (
        <div>
            <Button
                onClick={handleGoBack}
            >
                返回列表展示页
            </Button>

            <Form
                form={form}
                name="basic"
                labelCol={{ span: 3 }}
                wrapperCol={{ span: 21 }}
                onFinish={handleFormOnFinish}
                onFinishFailed={handleFormOnFinishFailed}
                labelAlign="left"
                preserve={false}
            >
                <Form.Item
                    label="名称"
                    name="name"
                >
                    <span>{groupInfo.name}</span>
                </Form.Item>

                <Form.Item
                    label="创建时间"
                    name="created_at"
                >
                    <span>{groupInfo.created_at}</span>
                </Form.Item>

                <Form.Item
                    label="用户"
                    name="user"
                >
                    <Select
                        mode="multiple"
                        allowClear
                        style={{
                            width: '100%',
                        }}
                        placeholder="请选择用户"
                        onChange={(value) => { console.log(`选择了 ${value}`); }}
                        options={options}
                    />
                </Form.Item>

                <Form.Item
                    label="权限"
                    name="permissions"
                >
                    <PermissionsTable permissionData={permissionData} handlePermissionChange={handlePermissionChange} />
                </Form.Item>

                <Form.Item
                    style={{display: 'flex', justifyContent: 'center', textAlign: 'center'}}
                >
                    <Button type="primary" htmlType="submit">
                        保存
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}

export default GroupPermissionPersonal