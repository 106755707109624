import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Modal, Select, Input, Radio, Button, Upload, message } from 'antd'

import { actionCreators as loginActionCreators } from '../../pages/login/store'
import { actionCreators as homeActionCreators } from '../../pages/home/store'
import styles from './index.module.css'
import userAvatar from '../../statics/avatar.png'
import userWomanAvatar from '../../statics/avatar_woman.png'
import changeAvatarImg from '../../statics/change_avatar.png'
import MyButton from '../MyButton';


class ModalUserInfo extends Component {
    handleOk() {
        this.props.handleIsUserinfoModalOpenAction(false)
    }

    handleCancel() {
        this.props.handleIsUserinfoModalOpenAction(false)
    }

    onLanguageChange(value) {
        this.props.handleLanguageAction(value)
    }

    onThemeChange(value) {
        this.props.handleThemeAction(value)
    }

    generateFileUploadProps() {
        let uploadProps = {
            name: 'avatar',
            accept: "image/jpeg, image/png, image/gif, image/bmp, image/webp",
            showUploadList: false,
            fileList: this.props.avatar_upload_filelist.toJS(),
            action: '/api/account/update_avatar',
            headers: {

            },
            beforeUpload: (file) => {
                console.log("beforeUpload file = ", file);
                this.props.handleAvatarUploadFilelistAction([file])

                // 限制文件类型
                const isImage = file.type === 'image/jpeg' ||
                                file.type === 'image/png' ||
                                file.type === 'image/gif' ||
                                file.type === 'image/bmp' ||
                                file.type === 'image/webp';

                if (!isImage) {
                    message.error('你只能上传图片文件！');
                }

                // 限制图片大小
                const isLt5M = file.size / 1024 / 1024 < 5;
                if (!isLt5M) {
                    message.error('头像文件限定为 5MB 以内！');
                }

                return isImage && isLt5M;
            },
            onChange: (info) => {
                console.log("info = ", info);
                let newFileList = [...info.fileList];
                // 只保留最后一个选择的文件
                newFileList = newFileList.slice(-1);
                this.props.handleAvatarUploadFilelistAction(newFileList)

                // 当上传状态改变时（例如：上传成功、上传失败）
                if (info.file.status === 'done') {
                    // 上传成功，服务器返回的响应会在 info.file.response 中
                    console.log('Upload success:', info.file.response);
                    let res = info.file.response
                    let avatar = res.data.avatar
                    let user_data = this.props.user_data.toJS()
                    user_data.avatar = avatar
                    this.props.handleUserDataAction(user_data)
                    message.success(`${info.file.name} 图片上传成功`);
                } else if (info.file.status === 'error') {
                    message.error(`${info.file.name} 图片上传失败.`);
                }
            },
        }

        return uploadProps
    }

    render() {
        let file_upload_props = this.generateFileUploadProps()
        let user_data = this.props.user_data.toJS()

        return (
            <Modal
                open={this.props.isUserInfoModalOpen}
                onOk={this.handleOk.bind(this)}
                onCancel={this.handleCancel.bind(this)}
                footer={null}
                width={690}
            >
                <div className={styles.main}>
                    <div className={styles.header}>账户设置</div>

                    <div className={styles.section}>
                        <div className={styles.section_left}>
                            <div className={styles.avatar_box}>
                                <img src={user_data.avatar ? user_data.avatar : (user_data.gender === 'woman' ? userWomanAvatar : userAvatar)} className={styles.avatar} alt="头像" />
                                <Upload
                                    {...file_upload_props}
                                >
                                    <img
                                        src={changeAvatarImg}
                                        className={styles.change_avatar}
                                        alt="更改头像"
                                    />
                                </Upload>
                            </div>
                            <span className={styles.username}>{user_data.nickname ? user_data.nickname : ''}</span>
                            <span className={styles.email}>{user_data.email ? user_data.email : ''}</span>
                        </div>
                        <div className={styles.section_right}>
                            <div className={styles.form_item}>
                                <div className={styles.item_title}>昵称</div>
                                <Input
                                    type={"text"}
                                    placeholder={"昵称"}
                                    value={this.props.nickname}
                                    className={styles.nickname_input}
                                    onChange={this.handleNicknameChange.bind(this)}
                                />
                            </div>
                            <div className={styles.form_item}>
                                <div className={styles.item_title}>语言设置</div>
                                <Select
                                    value={this.props.language}
                                    onChange={this.onLanguageChange.bind(this)}
                                    options={[
                                        {
                                            value: 'chinese',
                                            label: '中文',
                                        },
                                        {
                                            value: 'english',
                                            label: '英文',
                                        },
                                    ]}
                                    className={styles.language_select}
                                    disabled
                                />
                            </div>
                        </div>
                    </div>

                    <div className={styles.section2}>
                        <div className={styles.section_left}>
                        </div>
                        <div className={`${styles.section_right} ${styles.section2_right}`}>
                            <div className={styles.func_item}>
                                <div className={styles.item_title}>主题</div>
                                <div className={styles.radio_box}>
                                    <div
                                        className={`${styles.radio_item} ${this.props.theme === 'daytime' ? styles.radio_item_active : ''}`}
                                        // onClick={() => {this.onThemeChange('daytime')}}
                                    >
                                        白天
                                    </div>
                                    <div
                                        className={`${styles.radio_item} ${this.props.theme === 'darknight' ? styles.radio_item_active : ''}`}
                                        // onClick={() => {this.onThemeChange('darknight')}}
                                    >
                                        暗夜
                                    </div>
                                </div>
                            </div>
                            <div className={styles.func_item}>
                                <div className={styles.item_title}>删除所有对话</div>
                                <Button
                                    disabled
                                    className={styles.func_butn}
                                >
                                    删除
                                </Button>
                            </div>
                            <div className={styles.func_item}>
                                <div className={styles.item_title}>清除缓存</div>
                                <div className={styles.item_size}>4.5M</div>
                                <Button
                                    disabled
                                    className={styles.func_butn}
                                >
                                    确认
                                </Button>
                            </div>
                        </div>
                    </div>

                    <div className={styles.footer}>
                        <div className={styles.delete_account}>删除我的账户</div>
                        <MyButton
                            width={'150px'}
                            height={'50px'}
                            onClick={this.handleOk.bind(this)}
                            text="完成"
                            type="primary"
                        />
                    </div>
                </div>
          </Modal>
        )
    }

    handleUserinfo() {
        let nickname = this.props.nickname
        this.props.handlePostUserInfoAction(nickname)
    }

    handleNicknameChange(e) {
        let value = e.target.value
        this.props.handleNicknameAction(value)
    }
}

const mapStateToProps = (state) => {
    return {
        isUserInfoModalOpen: state.get('home').get('isUserInfoModalOpen'),
        language: state.get('home').get('language'),
        theme: state.get('home').get('theme'),
        avatar_upload_filelist: state.get('home').get('avatar_upload_filelist'),
        user_data: state.get('login').get('user_data'),
        nickname: state.get('home').get('nickname'),
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleIsUserinfoModalOpenAction(status) {
            dispatch(homeActionCreators.isUserinfoModalOpenAction(status))
        },
        handleLanguageAction(value) {
            dispatch(homeActionCreators.languageAction(value))
        },
        handleThemeAction(value) {
            dispatch(homeActionCreators.themeAction(value))
        },
        handleAvatarUploadFilelistAction(data) {
            dispatch(homeActionCreators.avatarUploadFilelistAction(data))
        },
        handleUserDataAction(data) {
            dispatch(loginActionCreators.userDataAction(data))
        },
        handlePostUserInfoAction(nickname) {
            dispatch(loginActionCreators.postUserInfoAction(nickname))
        },
        handleNicknameAction(value) {
            dispatch(homeActionCreators.nicknameAction(value))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalUserInfo)