import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import styles from './index.module.css'
import UserPermissionTable from '../UserPermissionTable'
import UserPermissionPersonal from '../UserPermissionPersonal'
import { actionCreators } from '../../store'

function UserPermissionManagement() {
    let permission_user_page = useSelector(state => state.get('admin').get('permission_user_page'))
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(actionCreators.getUsersPermission())
        dispatch(actionCreators.getGroupsPermissions())
    }, [])

    return (
        <div className={styles.main}>
            <div className={styles.header}>
                <div className={styles.title}>
                    用户权限管理
                </div>
            </div>

            <div className={styles.container}>
                {
                    permission_user_page === 'table' ? (
                        <UserPermissionTable />
                    ) : (
                        <UserPermissionPersonal />
                    )
                }
            </div>
        </div>
    )
}

export default UserPermissionManagement