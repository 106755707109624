import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter, Redirect } from 'react-router-dom'
import { Layout, ConfigProvider } from 'antd';
import locale from 'antd/locale/zh_CN';
import dayjs from 'dayjs';

import 'dayjs/locale/zh-cn';

import styles from './index.module.css'
import { actionCreators } from './store'
import SideMenu from './components/SideMenu'
import UserStatistics from './components/UserStatistics'
import ConversationCountStatistics from './components/ConversationCountStatistics'
import PersonalConversation from './components/PersonalConversation'
import KnowledgeBase from './components/KnowledgeBase'
import VersionUpdateInfo from './components/VersionUpdateInfo'
import UserPermissionManagement from './components/UserPermissionManagement'
import GroupPermissionManagement from './components/GroupPermissionManagement'
import ModelManagement from './components/ModelManagement'
import CategorizeUserManagement from './components/CategorizeUserManagement'
import CategorizeAllManagement from './components/CategorizeAllManagement'
import CategorizeGenerateManagement from './components/CategorizeGenerateManagement'
import CategorizeSettingManagement from './components/CategorizeSettingManagement'
import ComponentListedPicture from './components/ComponentListedPicture'
import ComponentListedDocument from './components/ComponentListedDocument'
import BannerRelated from './components/BannerRelated'


dayjs.locale('zh-cn');

class Admin extends Component {
    componentDidMount() {
        // 获取模型信息
        this.props.handleAdminInitModelInfo()
    }

    handleActiveNavbar(active_navbar) {
        let d = {
            'user_info': <UserStatistics />,
            'user_conversation_count': <ConversationCountStatistics />,
            'personal_conversation': <PersonalConversation />,
            'data_manage': <KnowledgeBase />,
            'model_manage': <ModelManagement />,
            'version_update': <VersionUpdateInfo />,
            'user_permission_management': <UserPermissionManagement />,
            'group_permission_management': <GroupPermissionManagement />,
            'categorize_user_management': <CategorizeUserManagement />,
            'categorize_all_management': <CategorizeAllManagement />,
            'categorize_generate_management': <CategorizeGenerateManagement />,
            'categorize_setting_management': <CategorizeSettingManagement />,
            'component_picture_listed': <ComponentListedPicture />,
            'component_document_listed': <ComponentListedDocument />,
            'banner_related': <BannerRelated />,
        }

        return d[active_navbar] || null
    }

    render() {
        if (!this.props.has_login) {
            return <Redirect to="/" />
        }

        let user_data = this.props.user_data.toJS()
        if (!user_data.admin) {
            return <Redirect to="/home" />
        }

        let active_navbar = this.props.active_navbar
        let content = this.handleActiveNavbar(active_navbar)

        return (
            <ConfigProvider locale={locale}>
                <Layout className={styles.main}>
                    <SideMenu />
                    <Layout>
                        <Layout.Content className={styles.content}>
                            {
                                content
                            }
                        </Layout.Content>
                    </Layout>
                </Layout>
            </ConfigProvider>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        active_navbar: state.get('admin').get('active_navbar'),
        has_login: state.get('login').get('has_login'),
        admin_chat_type_options: state.get('admin').get('admin_chat_type_options'),
        user_data: state.get('login').get('user_data'),
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleAdminInitModelInfo() {
            dispatch(actionCreators.adminInitModelInfo())
        },
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Admin))