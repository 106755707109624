import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter, Redirect } from 'react-router-dom'

import styles from './index.module.css'
import { actionCreators } from '../../store'
import collectLinkStoneImg from '../../../../statics/collect_linkstone.png'


class NoneContentBox extends Component {
    render() {
        return (
            <div className={styles.container}>
                <img src={collectLinkStoneImg} className={styles.none_img} />

                <div className={styles.none_text}>
                    你还没有任何收藏的对话记录
                </div>

                <div className={styles.none_explain}>
                    你收藏的历史聊天记录将会被储存在这里！让我们开始聊天吧！
                </div>

                <div
                    className={styles.create_butn}
                    onClick={this.redirectAndAddNewTopic.bind(this)}>
                    创建新对话
                </div>
            </div>
        )
    }

    redirectAndAddNewTopic() {
        this.props.history.push({pathname: '/home'})
    }
}

const mapStateToProps = (state) => {
    return {
        filter_butn: state.get('collect').get('filter_butn'),
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleFilterButnAction(value) {
            dispatch(actionCreators.filterButnAction(value))
        },
        handleFilterCategoryListAction(data) {
            dispatch(actionCreators.filterCategoryListAction(data))
        },
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NoneContentBox))