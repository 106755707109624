import React, { useState, useEffect } from 'react';
import { Table, Space, Input, Button, Modal, message, Form } from 'antd';
import { connect, useDispatch, useSelector } from 'react-redux'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import CategorizeSettingAddKeywordModal from '../CategorizeSettingAddKeywordModal'

import { actionCreators } from '../../store'
import styles from './index.module.css'

const EditPage = ({pageStatus, setPageStatus, currentCategory, currentId, tableData, handleFetchCategoryList}) => {
    const columns = [
        {
            title: '向量匹配关键词',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '操作',
            render: (_, record) => {
                return (
                    <Space size="middle">
                        <Button type="primary" danger onClick={() => {onSimilarDelete(record.name)}}>删除</Button>
                    </Space>
                )
            },
            key: 'value',
        },
    ];

    const dispatch = useDispatch()
    const [creating, setCreating] = useState(false)
    const [form] = Form.useForm()
    const [similarData, setSimilarData] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isSimilarChange, setIsSimilarChange] = useState(false)

    useEffect(() => {
        let l = tableData.find(item => item.name === currentCategory)
        if (l) {
            let d = l.similar_text.map((item) => {
                return {
                    name: item,
                    value: item,
                }
            })
            setSimilarData(d)
        }
    }, [tableData])

    const onFinish = (values) => {
        console.log('Success:', values);
        if (pageStatus === 'edit') {
            let new_name = values.new_category_name.trim()
            if (new_name !== '' && tableData.find(item => item.name === new_name)) {
                message.warning("该分类项名称已存在！")
            } else {
                let d = {}
                if (new_name !== '') {
                    d['name'] = new_name
                }

                if (isSimilarChange) {
                    let l = similarData.map(item => { return item.name })
                    d['similar_text'] = l
                }

                if (Object.keys(d).length !== 0) {
                    d['id'] = currentId
                    setCreating(true)
                    dispatch(actionCreators.updateCategoryList(d))
                        .then(data => {
                            handleFetchCategoryList()
                            setPageStatus('')
                            message.success("分类名操作成功")
                        })
                        .catch(error => {})
                        .finally(() => { setCreating(false) })
                } else {
                    message.info("数据没有新的更改项")
                }
            }
        } else {
            // pageStatus === 'create'
            let new_name = values.new_category_name.trim()
            if (new_name === '') {
                message.warning("新的分类项名称不能为空！")
            } else if (tableData.find(item => item.name === new_name)) {
                message.warning("该分类项名称已存在！")
            } else {
                let temp_data = {
                    name: new_name,
                }

                let l = similarData.map(item => { return item.name })
                temp_data['similar_text'] = l

                let d = {
                    "categories": [temp_data]
                }

                setCreating(true)
                dispatch(actionCreators.createCategoryList(d))
                    .then(data => {
                        handleFetchCategoryList()
                        setPageStatus('')
                        message.success("分类名操作成功")
                    })
                    .catch(error => {})
                    .finally(() => { setCreating(false) })
            }
        }
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    }

    const onSimilarDelete = (name) => {
        setSimilarData(prevData => prevData.filter(item => item.name !== name))
        setIsSimilarChange(true)
    }

    const addKeyword = () => {
        setIsModalOpen(true)
    }

    return (
        <div className={styles.content}>
            <div className={styles.header}>
                <Button
                    className={styles.back_butn}
                    onClick={() => {setPageStatus('')}}
                >
                    返回列表展示页
                </Button>
                <div className={styles.title}>
                    {
                        pageStatus === 'edit' ? "编辑分类项" : "创建分类项"
                    }
                </div>
            </div>
            <div className={styles.form_box}>
                <Form
                    name="basic"
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 16,
                    }}
                    style={{
                        minWidth: 600,
                        maxWidth: 600,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    preserve={false}
                    form={form}
                    initialValues={{new_category_name: ''}}
                >
                    {
                        pageStatus === 'edit' ? (
                            <>
                                <Form.Item
                                    label="原分类项名称"
                                    labelAlign="left"
                                    name="category_name"
                                >
                                    <span>{ currentCategory }</span>
                                </Form.Item>
                                <Form.Item
                                    label="新的分类项名称"
                                    labelAlign="left"
                                    name="new_category_name"
                                    rules={[
                                        {
                                            message: '请输入新的分类项名称',
                                        },
                                    ]}
                                >
                                    <Input placeholder="请输入新的分类项名称" />
                                </Form.Item>
                            </>
                        ) : (
                            <Form.Item
                                label="新的分类项名称"
                                labelAlign="left"
                                name="new_category_name"
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入新的分类项名称',
                                    },
                                ]}
                            >
                                <Input placeholder="请输入新的分类项名称" />
                            </Form.Item>
                        )
                    }

                    <Button type="primary" onClick={addKeyword}>添加新的向量匹配关键词</Button>
                    <Form.Item
                        label=""
                        name="similar_text_table"
                    >
                        <Table
                            style={{
                                minWidth: 600,
                                // maxWidth: 600,
                            }}
                            columns={columns}
                            dataSource={similarData}
                        />
                    </Form.Item>

                    {
                        pageStatus === 'edit' ? (
                            <Form.Item
                                wrapperCol={{
                                    offset: 8,
                                    span: 16,
                                }}
                            >
                                {
                                    creating ? (
                                        <Button type="primary" loading>
                                            修改中
                                        </Button>
                                    ) : (
                                        <Button type="primary" htmlType="submit">
                                            修改
                                        </Button>
                                    )
                                }
                            </Form.Item>
                        ) : (
                            <Form.Item
                                wrapperCol={{
                                    offset: 8,
                                    span: 16,
                                }}
                            >
                                {
                                    creating ? (
                                        <Button type="primary" loading>
                                            创建中
                                        </Button>
                                    ) : (
                                        <Button type="primary" htmlType="submit">
                                            创建
                                        </Button>
                                    )
                                }
                            </Form.Item>
                        )
                    }
                </Form>
            </div>

            <CategorizeSettingAddKeywordModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                similarData={similarData}
                setSimilarData={setSimilarData}
                setIsSimilarChange={setIsSimilarChange}
            />
        </div>
    )
}

const CategorizeSettingManagementContent = () => {
    const columns = [
        {
            title: '分类名',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '操作',
            render: (_, record) => {
                return (
                    <Space size="middle">
                        <Button type="primary" onClick={() => {onEdit(record.name, record.id)}}>编辑</Button>
                        <Button type="primary" danger onClick={() => {onDelete(record.name, record.id)}}>删除</Button>
                    </Space>
                )
            },
            key: 'id',
        },
    ];

    const dispatch = useDispatch()
    const [tableData, setTableData] = useState([])
    const [currentCategory, setCurrentCategory] = useState('')
    const [currentId, setCurrentId] = useState('')
    const [pageStatus, setPageStatus] = useState('')

    useEffect(() => {
        handleFetchCategoryList()
    }, [])

    const handleFetchCategoryList = () => {
        dispatch(actionCreators.fetchCategoryList())
        .then((data) => {
            console.log("data = ", data);
            setTableData(data.data)
        })
        .catch((error) => {
            console.log("error");
        })
    }

    const onEdit = (name, id) => {
        setPageStatus('edit')
        setCurrentCategory(name)
        setCurrentId(id)
    }

    const onDelete = (name, id) => {
        console.log("删除");

        Modal.confirm({
            title: '删除分类箱',
            icon: <ExclamationCircleOutlined />,
            content: '请确认是否要删除分类项：' + name,
            okText: '确认',
            cancelText: '取消',
            onOk() {
                console.log('OK');
                let d = {
                    id: id,
                }
                dispatch(actionCreators.deleteCategoryList(d))
                .then((data) => {
                    handleFetchCategoryList()
                    message.success(`删除分类项（${name}）成功`)
                })
                .catch((error) => {
                    message.error(`删除分类项（${name}）失败`)
                })
                .finally(() => {})
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    const createNewCategory = () => {
        setPageStatus('create')
        setCurrentCategory('')
        setCurrentId('')
    }

    return (
        <div className={styles.main}>
            {
                pageStatus === '' ? (
                    <>
                        <div>
                            <Button
                                type="primary"
                                onClick={createNewCategory}
                            >
                                创建新的分类项
                            </Button>
                        </div>
                        <Table
                            columns={columns}
                            dataSource={tableData}
                        />
                    </>
                ) : (
                    <EditPage
                        pageStatus={pageStatus}
                        setPageStatus={setPageStatus}
                        currentCategory={currentCategory}
                        currentId={currentId}
                        tableData={tableData}
                        handleFetchCategoryList={handleFetchCategoryList}
                    />
                )
            }
        </div>
    )
}

export default CategorizeSettingManagementContent