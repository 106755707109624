import React, { Component, useState, useRef, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom';
import { Divider } from 'antd'
import { LoadingOutlined } from '@ant-design/icons';

import styles from './index.module.css'
import { Button, Input, Checkbox } from 'antd';
import { actionCreators } from '../../store'
import QYWXLogin from '../QYWXLogin';
import WXLogin from '../WXLogin'
import logoImg from '../../../../statics/logo.png'
import logoFooterImg from '../../../../statics/logo_footer.png'
import VerificationCodeInput from '../VerificationCodeInput'
import ResendVerificationCode from '../ResendVerificationCode'


function LoginCard() {
    const dispatch = useDispatch()
    const [errorInfo, setErrorInfo] = useState('')
    let email = useSelector(state => state.get('login').get('email'))
    let password = useSelector(state => state.get('login').get('password'))
    let history = useSelector(state => state.get('login').get('history'))
    const loginButtonRef = useRef(null);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Enter') {
                // 触发登录按钮的点击事件
                if (loginButtonRef.current) {
                    loginButtonRef.current.click();
                }
            }
        }

        // 添加键盘事件监听器
        window.addEventListener('keydown', handleKeyDown);

        // 在组件卸载时清除键盘事件监听器
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [])

    const handleSubmit = () => {
        if (email.trim() === '') {
            setErrorInfo("请输入邮箱！")
        } else if (password.trim() === '') {
            setErrorInfo("请输入密码！")
        } else {
            // this.props.handleTestPostAccountLoginAction(email, password, history)
            dispatch(actionCreators.postAccountLoginAction(email, password, history))
        }
    }

    const handleEmailChange = (e) => {
        dispatch(actionCreators.setEmailAction(e.target.value))
    }

    const handlePasswordChange = (e) => {
        dispatch(actionCreators.setPasswordAction(e.target.value))
    }

    return (
        <div className={styles.form}>
            <div className={`${styles.error_info} ${errorInfo === '' ? styles.no_error_info : ''}`}>
                {
                    errorInfo
                }
            </div>
            <div className={styles.form_item}>
                <div className={styles.item_title}>邮箱</div>
                <Input
                    type={"text"}
                    placeholder={"请输入邮箱地址"}
                    value={email}
                    className={styles.item_input}
                    onChange={(e) => { handleEmailChange(e) }}
                    autoComplete='off'
                />
            </div>
            <div className={styles.form_item}>
                <div className={styles.item_title}>密码</div>
                <Input.Password
                    placeholder={"请输入密码"}
                    value={password}
                    className={styles.item_input}
                    onChange={(e) => { handlePasswordChange(e) }}
                    autoComplete='off'
                />
            </div>
            <div className={styles.remember_forget_box}>
                <Checkbox
                    className={styles.item_remember}
                    onChange={() => {}}
                >
                    记住密码
                </Checkbox>
                <div className={styles.item_forget}>
                    忘记密码
                </div>
            </div>
            <div className={styles.submit_box}>
                <div
                    className={`${styles.submit_butn} ${email !== '' || password !== '' ? styles.submit_butn_active : ''}`}
                    onClick={handleSubmit}
                    ref={loginButtonRef}
                >
                    登录
                </div>
            </div>
        </div>
    )
}

function RegisterCard() {
    const dispatch = useDispatch()
    const [errorInfo, setErrorInfo] = useState('')
    const [checked, setChecked] = useState(false);
    let register_nickname = useSelector(state => state.get('login').get('register_nickname'))
    let register_email = useSelector(state => state.get('login').get('register_email'))
    let register_password = useSelector(state => state.get('login').get('register_password'))
    let register_second_password = useSelector(state => state.get('login').get('register_second_password'))

    const handleSubmit = () => {
        // 邮箱验证的正则表达式
        const emailRegex = /\S+@\S+\.\S+/;
        // 密码至少 8 位，并且不能全是数字的正则表达式
        const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;

        if (register_nickname.trim() === '') {
            setErrorInfo("用户名不能为空")
        } else if (register_email.trim() === '') {
            setErrorInfo("请输入邮箱！")
        } else if (!emailRegex.test(register_email.trim())) {
            setErrorInfo("请输入正确的邮箱格式")
        } else if (register_password.trim() === '') {
            setErrorInfo("请输入密码！")
        } else if (!passwordRegex.test(register_password)) {
            setErrorInfo("密码至少 8 位，并且不能全是数字")
        } else if (register_second_password.trim() === '') {
            setErrorInfo("请输入确认密码！")
        } else if (register_password.trim() !== register_second_password.trim()) {
            setErrorInfo("您输入的两次密码不一致！")
        } else if (!checked) {
            setErrorInfo("请确认已经同意了【所有条款】和【隐私政策】中的条例！")
        } else {
            dispatch(actionCreators.getEmailVerificationCode(register_email))
            dispatch(actionCreators.setPageShowAction('code'))
        }
    }

    return (
        <div className={`${styles.form} ${styles.register_form}`}>
            <div className={`${styles.error_info} ${styles.register_error_info} ${errorInfo === '' ? styles.no_error_info : ''}`}>
                {
                    errorInfo
                }
            </div>
            <div className={styles.form_item}>
                <div className={styles.item_title}>用户名</div>
                <Input
                    type={"text"}
                    placeholder={"请输入你的用户名"}
                    value={register_nickname}
                    className={styles.item_input}
                    onChange={(e) => { dispatch(actionCreators.setRegisterNickname(e.target.value)) }}
                />
            </div>
            <div className={styles.form_item}>
                <div className={styles.item_title}>邮箱</div>
                <Input
                    type={"text"}
                    placeholder={"请输入邮箱地址"}
                    value={register_email}
                    className={styles.item_input}
                    onChange={(e) => { dispatch(actionCreators.setRegisterEmail(e.target.value)) }}
                />
            </div>
            <div className={styles.form_item}>
                <div className={styles.item_title}>密码</div>
                <Input.Password
                    placeholder={"请输入密码"}
                    value={register_password}
                    className={styles.item_input}
                    onChange={(e) => { dispatch(actionCreators.setRegisterPassword(e.target.value.trim())) }}
                />
            </div>
            <div className={styles.form_item}>
                <div className={styles.item_title}>确认密码</div>
                <Input.Password
                    placeholder={"请再次输入"}
                    value={register_second_password}
                    className={styles.item_input}
                    onChange={(e) => { dispatch(actionCreators.setRegisterSecondPassword(e.target.value.trim())) }}
                />
            </div>
            <div className={styles.protocol_box}>
                <Checkbox
                    checked={checked}
                    className={styles.item_agree}
                    onChange={() => {setChecked(!checked)}}
                >
                    我同意
                    <span className={styles.item_protocol} onClick={(e) => {e.preventDefault(); e.stopPropagation()}}>
                        所有条款
                    </span>
                    和
                    <span className={styles.item_protocol} onClick={(e) => {e.preventDefault(); e.stopPropagation()}}>
                        隐私政策
                    </span>
                </Checkbox>
            </div>
            <div className={styles.submit_box}>
                <div
                    className={`
                        ${styles.submit_butn}
                        ${register_email !== '' || register_password !== '' || register_second_password !== '' ? styles.submit_butn_active : ''}
                    `}
                    onClick={handleSubmit}
                >
                    注册
                </div>
            </div>
        </div>
    )
}

function CodeCard() {
    const dispatch = useDispatch()
    const [creating, setCreating] = useState(false)
    let length = 6
    const [errorInfo, setErrorInfo] = useState('')
    const [inputs, setInputs] = useState(new Array(length).fill(''));
    let register_nickname = useSelector(state => state.get('login').get('register_nickname'))
    let register_email = useSelector(state => state.get('login').get('register_email'))
    let register_password = useSelector(state => state.get('login').get('register_password'))
    console.log("inputs = ", inputs);

    const handleSubmit = () => {
        if (inputs.join('').length !== 6) {
            setErrorInfo("请先将 6 位验证码输入！")
        } else {
            console.log("发送验证");
            setErrorInfo("")
            setCreating(true)
            dispatch(actionCreators.postAccountEmailRegister(register_nickname.trim(), register_email.trim(), register_password.trim(), inputs.join('')))
                .then(data => {
                    dispatch(actionCreators.setPageShowAction('login'))
                    setInputs(new Array(length).fill(''));
                    dispatch(actionCreators.setRegisterNickname(''))
                    dispatch(actionCreators.setRegisterEmail(''))
                    dispatch(actionCreators.setRegisterPassword(''))
                    dispatch(actionCreators.setRegisterSecondPassword(''))
                })
                .catch(error => {})
                .finally(() => {setCreating(false)})
        }
    }

    return (
        <div className={styles.code_card_main}>
            <div className={styles.right_header}>
                <div className={styles.login_text}>查看你的邮箱</div>
                <div className={styles.login_explain}>
                    我们发送了一个 6 位代码到你的邮箱 { register_email }，请输入邮件中提到的 6 位代码
                </div>
            </div>
            <div className={styles.code_card_error_info_box}>

            </div>
            <div className={`${styles.error_info} ${errorInfo === '' ? styles.no_error_info : ''}`}>
                {
                    errorInfo
                }
            </div>
            <div className={styles.code_card_code_box}>
                <VerificationCodeInput length={length} inputs={inputs} setInputs={setInputs} />
            </div>
            <div className={styles.submit_box}>
                {
                    creating ? (
                        <div
                            className={`
                                ${styles.submit_butn}
                            `}
                        >
                            注册中<LoadingOutlined />
                        </div>
                    ) : (
                        <div
                            className={`
                                ${styles.submit_butn}
                                ${inputs.join('') !== '' ? styles.submit_butn_active : ''}
                            `}
                            onClick={() => {
                                inputs.join('') !== '' ? handleSubmit() : console.log("不能被点击")
                            }}
                        >
                            确认代码
                        </div>
                    )
                }
            </div>
        </div>
    )
}

class PageCard extends Component {
    render() {
        let page_header = ''
        let page_show = ''
        if (this.props.page_show === 'login') {
            page_header = (
                <div className={styles.right_header}>
                    <div className={styles.login_text}>登录</div>
                    <div className={styles.login_explain}>很高兴再次见到你！</div>
                </div>
            )
            page_show = <LoginCard />
        } else if (this.props.page_show === 'register') {
            page_header = (
                <div className={styles.right_header}>
                    <div className={styles.login_text}>注册</div>
                    <div className={styles.login_explain}>欢迎使用灵石！很高兴认识你！</div>
                </div>
            )
            page_show = <RegisterCard />
        } else {
            page_header = (
                <div
                    className={styles.code_card_header}
                    onClick={() => { this.props.handleSetPageShowAction('register') }}
                ></div>
            )
            page_show = <CodeCard />
        }

        return (
            <div className={styles.container}>
                <div className={styles.left}>
                    <div className={styles.left_header}>
                        <img className={styles.logo} src={logoImg} />
                        <div className={styles.logo_text}>LinkStone</div>
                    </div>
                    <div className={styles.left_middle}>
                        <div className={styles.welcome}>欢迎！</div>
                        <Divider style={{
                            width: 90,
                            minWidth: 90,
                            height: 6,
                            backgroundColor: '#fff',
                            borderWidth: 6,
                            borderRadius: 6,
                        }} />
                        <div className={styles.explain}>
                            欢迎触碰灵石！灵石可以帮助你回答室内设计行业的各种问题，加入我们一起体验前所未有的Ai设计时代
                        </div>
                    </div>
                </div>

                <div className={styles.right}>
                    {
                        page_header
                    }

                    {
                        page_show
                    }

                    {
                        this.props.page_show === 'login' || this.props.page_show === 'register' ?
                        (
                            <>
                                <Divider style={{borderColor: "#000"}}>或者</Divider>

                                <div className={styles.third_login}>
                                    <QYWXLogin />
                                    <WXLogin />
                                </div>

                                <div className={styles.register_box}>
                                    {
                                        this.props.page_show === 'login' ? (
                                            <div className={styles.register}>
                                                没有账户？
                                                <a className={styles.register_link}
                                                    onClick={() => { this.props.handleSetPageShowAction('register') }}
                                                >
                                                        注册
                                                </a>
                                            </div>
                                        ) : (
                                            <div className={styles.register}>
                                                已有账户？
                                                <a className={styles.register_link}
                                                    onClick={() => { this.props.handleSetPageShowAction('login') }}
                                                >
                                                        登录
                                                </a>
                                            </div>
                                        )
                                    }
                                </div>
                            </>
                        ) : (
                            <div className={styles.register_box}>
                                <ResendVerificationCode />
                            </div>
                        )
                    }

                    <div className={styles.right_footer}>
                        <div className={styles.footer}>
                            <img
                                src={logoFooterImg}
                                className={styles.footer_img}
                            />
                            <span className={styles.footer_text}>@2024 LinkStone</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        page_show: state.get('login').get('page_show'),
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleTestPostAccountLoginAction(email, password, history) {
            dispatch(actionCreators.testPostAccountLoginAction(email, password, history))
        },
        handleSetPageShowAction(value) {
            dispatch(actionCreators.setPageShowAction(value))
        },
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PageCard))