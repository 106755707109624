import React, { useState, useEffect } from 'react';
import { Card, Statistic } from 'antd'
import { useSelector } from 'react-redux'
import { getLast7DaysNewUsers } from '../../../../utils'

import styles from './index.module.css'

const generateItems = (data, today_num, week_num, month_avg) => {
    let items = [
        {
            key: 'total_user',
            title: '总注册人数',
            value: data.length,
            // precision: 2,
            suffix: '人',
            style: { color: '#157efb' },
        },
        {
            key: 'today_user',
            title: '今天访问人数',
            value: today_num,
            // precision: 2,
            suffix: '人',
            style: { color: '#f25961' },
        },
        {
            key: 'week_user',
            title: '本周访问人数',
            value: week_num,
            // precision: 2,
            suffix: '人',
            style: { color: '#00f' },
        },
        {
            key: 'week_add',
            title: '近七日新增人数',
            value: getLast7DaysNewUsers(data),
            // precision: 2,
            suffix: '人',
            style: { color: '#2bb930' },
        },
        {
            key: 'month_today_avg',
            title: '本月平均日活',
            value: month_avg,
            // precision: 2,
            suffix: '人',
            style: { color: '#f25961' },
        },
    ]

    return items
}

const UserUsageCard = () => {
    let user_statistics_data = useSelector(state => state.get('admin').get('user_statistics_data'))
    let user_statistics_today_num = useSelector(state => state.get('admin').get('user_statistics_today_num'))
    let user_statistics_week_num = useSelector(state => state.get('admin').get('user_statistics_week_num'))
    let user_statistics_month_day_avg = useSelector(state => state.get('admin').get('user_statistics_month_day_avg'))
    const [items, setItems] = useState([])
    useEffect(() => {
        setItems(generateItems(user_statistics_data.toJS(), user_statistics_today_num, user_statistics_week_num, user_statistics_month_day_avg))
    }, [user_statistics_data, user_statistics_today_num, user_statistics_week_num, user_statistics_month_day_avg])

    return (
        <div className={styles.main}>
            {
                items.map((item) => {
                    return (
                        <Card className={styles.card} key={item.key}>
                            <Statistic
                                title={item.title}
                                value={item.value}
                                precision={item.precision}
                                suffix={item.suffix}
                                valueStyle={item.style}
                            />
                        </Card>
                    )
                })
            }
        </div>
    )
}

export default UserUsageCard