import { useState, useEffect } from 'react'

import { useSelector, useDispatch } from 'react-redux'
import { Button, Form, Input, Space, Select, Table, Checkbox } from 'antd'
import { actionCreators } from '../../store'
import styles from './index.module.css'
import { convertPermissionsToTableData, convertTableDataToPermissions } from '../../../../utils'


function PermissionsTable({ permissionData, handlePermissionChange }) {
    const columns = [
        {
            title: '资源',
            dataIndex: 'resource',
        },
        {
            title: 'read',
            dataIndex: 'read',
            render: (_, record) => (
                <Checkbox
                    checked={record.permissions.read}
                    onChange={() => handlePermissionChange(record.key, 'read')}
                />
            ),
        },
        {
            title: 'write',
            dataIndex: 'write',
            render: (_, record) => (
                <Checkbox
                    checked={record.permissions.write}
                    onChange={() => handlePermissionChange(record.key, 'write')}
                />
            ),
        },
        {
            title: 'execute',
            dataIndex: 'execute',
            render: (_, record) => (
                <Checkbox
                    checked={record.permissions.execute}
                    onChange={() => handlePermissionChange(record.key, 'execute')}
                />
            ),
        },
        {
            title: 'delete',
            dataIndex: 'delete',
            render: (_, record) => (
                <Checkbox
                    checked={record.permissions.delete}
                    onChange={() => handlePermissionChange(record.key, 'delete')}
                />
            ),
        },
    ];

    return <Table columns={columns} dataSource={permissionData} />;
};


function UserPermissionPersonal() {
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const [userInfo, setUserInfo] = useState({})
    const [permissionData, setPermissionData] = useState([]);
    const [options, setOptions] = useState([])
    const [groups, setGroups] = useState([])
    let permission_user_list = useSelector(state => state.get('admin').get('permission_user_list'))
    let permission_user_uuid = useSelector(state => state.get('admin').get('permission_user_uuid'))
    let permission_group_list = useSelector(state => state.get('admin').get('permission_group_list'))

    useEffect(() => {
        let a = permission_user_list.toJS().filter(item => item.uuid === permission_user_uuid)
        if (a) {
            let obj = a[0]
            setUserInfo(obj)
            let groupValues = obj.groups
            if (groupValues.includes("*")) {
                console.log("obj.groups = ", groupValues);
            } else {
                console.log("obj.groups = ", groupValues);
                setGroups(groupValues)
                // 设置 form 里面的值
                form.setFieldsValue({
                    group: groupValues
                })
            }
            setPermissionData(convertPermissionsToTableData(obj.permissions))
        }
        console.log("=====================================");
    }, [permission_user_list, form])

    useEffect(() => {
        let data = permission_group_list.toJS()
        let a = []
        data.forEach((item) => {
            a.push({label: item.name, value: item.name})
        })
        // [{label: 11, value: 11}, {label: 1, value: 1}]
        console.log("setOptions(a) = ", a);
        setOptions(a)
    }, [permission_group_list])

    useEffect(() => {
        let a = permission_user_list.toJS().filter(item => item.uuid === permission_user_uuid)
        if (a) {
            let obj = a[0]
            let groupValues = obj.groups
            if (groupValues.includes("*")) {
                if (options.length !== 0) {
                    // 包含全部的组
                    let v = options.map((item) => { return item.value })
                    console.log("permission_user_list v = ", v);
                    setGroups(v)
                    // 设置 form 里面的值
                    form.setFieldsValue({
                        group: v
                    })
                }
            }
        }
    }, [options])

    const handlePermissionChange = (permissionKey, permissionType) => {
        // 更新数据源中的权限信息
        const newData = permissionData.map((record) => {
            if (record.key === permissionKey) {
                return {
                ...record,
                permissions: {
                    ...record.permissions,
                    [permissionType]: !record.permissions[permissionType],
                },
                };
            }

            return record;
        });

        console.log("newData = ", newData);
        setPermissionData(newData);
    }

    const handleGoBack = () => {
        dispatch(actionCreators.setPermissionUserPageAction('table'))
        dispatch(actionCreators.setPermissionUserUuidAction(''))
    }

    const handleFormOnFinish = (values) => {
        console.log("values = ", values);

        let new_group = values.group
        let old_group = userInfo.groups.includes("*") ? options.map((item) => { return item.value }) : userInfo.groups
        console.log("new_group = ", new_group);
        console.log("old_group = ", old_group);
        if (JSON.stringify(new_group) !== JSON.stringify(old_group)) {
            dispatch(actionCreators.updateUserGroups(permission_user_uuid, new_group))
        }

        let p = convertTableDataToPermissions(permissionData)
        console.log("p = ", p);
        console.log("userInfo.permissions = ", userInfo.permissions);
        if (JSON.stringify(p) !== JSON.stringify(userInfo.permissions)) {
            dispatch(actionCreators.updateUserPermissions(permission_user_uuid, p))
        }
    }

    const handleFormOnFinishFailed = (errorInfo) => {
        console.log(errorInfo);
    }

    return (
        <div>
            <Button
                onClick={handleGoBack}
            >
                返回列表展示页
            </Button>

            <Form
                form={form}
                name="basic"
                labelCol={{ span: 3 }}
                wrapperCol={{ span: 21 }}
                onFinish={handleFormOnFinish}
                onFinishFailed={handleFormOnFinishFailed}
                labelAlign="left"
                preserve={false}
            >
                <Form.Item
                    label="姓名"
                    name="username"
                >
                    <span>{userInfo.username}</span>
                </Form.Item>

                <Form.Item
                    label="用户名"
                    name="nickname"
                >
                    <span>{userInfo.nickname}</span>
                </Form.Item>

                <Form.Item
                    label="头像"
                    name="avatar"
                >
                    <span>{userInfo.avatar}</span>
                </Form.Item>

                <Form.Item
                    label="创建时间"
                    name="registered_on"
                >
                    <span>{userInfo.registered_on}</span>
                </Form.Item>

                <Form.Item
                    label="邮箱"
                    name="email"
                >
                    <span>{userInfo.email}</span>
                </Form.Item>

                <Form.Item
                    label="年龄"
                    name="age"
                >
                    <span>{userInfo.age}</span>
                </Form.Item>

                <Form.Item
                    label="性别"
                    name="gender"
                >
                    <span>{userInfo.gender}</span>
                </Form.Item>

                <Form.Item
                    label="城市"
                    name="city"
                >
                    <span>{userInfo.city}</span>
                </Form.Item>

                <Form.Item
                    label="职业"
                    name="occupation"
                >
                    <span>{userInfo.occupation}</span>
                </Form.Item>

                <Form.Item
                    label="群组"
                    name="group"
                >
                    <Select
                        mode="multiple"
                        allowClear
                        style={{
                            width: '100%',
                        }}
                        placeholder="请选择群组"
                        onChange={(value) => { console.log(`选择了 ${value}`); }}
                        options={options}
                    />
                </Form.Item>

                <Form.Item
                    label="权限"
                    name="permissions"
                >
                    <PermissionsTable permissionData={permissionData} handlePermissionChange={handlePermissionChange} />
                </Form.Item>

                <Form.Item
                    style={{display: 'flex', justifyContent: 'center', textAlign: 'center'}}
                >
                    <Button type="primary" htmlType="submit">
                        保存
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}

export default UserPermissionPersonal