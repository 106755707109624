import React, { useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom';
import { Layout, Menu, Button } from 'antd';

import { actionCreators } from '../../store'
import styles from './index.module.css'

const SideMenu = () => {
    const dispatch = useDispatch()
    let items = useSelector(state => state.get('admin').get('navbar_menu')).toJS()
    let active_navbar = useSelector(state => state.get('admin').get('active_navbar'))
    const history = useHistory()

    const onClick = (e) => {
        dispatch(actionCreators.setActiveNavbarAction(e.key))
        dispatch(actionCreators.setKnowledgeBaseShowContentAction(''))
        dispatch(actionCreators.setPermissionUserPageAction('table'))
        dispatch(actionCreators.setPermissionUserUuidAction(''))
        dispatch(actionCreators.setPermissionGroupPageAction('table'))
        dispatch(actionCreators.setPermissionGroupIdAction(''))
    };

    const goToHome = () => {
        history.push('/home')
    }

    return (
        <>
            <Layout.Sider
                breakpoint="lg"
                collapsedWidth="0"
                onBreakpoint={(broken) => {
                    console.log(broken);
                }}
                onCollapse={(collapsed, type) => {
                    console.log(collapsed, type);
                }}
            >
                <div className={styles.home}>
                    <div
                        className={styles.home_butn}
                        onClick={goToHome}
                    >
                        Home
                    </div>
                </div>
                <Menu
                    theme="dark"
                    onClick={onClick}
                    selectedKeys={[active_navbar]}
                    mode="inline"
                    items={items}
                />
            </Layout.Sider>
        </>
    );
};

export default SideMenu;