const chatTypeIcons = {
    llm_chat: require('../statics/chat_type_sel_project.png'),
    bailian_chat: require('../statics/chat_type_sel_standard.png'),
    tiangong_search: require('../statics/chat_type_sel_work.png'),
    project_manager_expert: require('../statics/chat_type_sel_project_manager.png'),
    sustainable_experts: require('../statics/sustainable_experts.png'),
    material_experts: require('../statics/material_experts.png'),
    software_experts: require('../statics/software_experts.png'),
    business_experts: require('../statics/business_experts.png'),
    office_experts: require('../statics/office_experts.png'),
    agent_chat: require('../statics/ai_link_stone.gif'),
}

const chatTypeSidebarIcons = {
    llm_chat: require('../statics/sidebar_chat_type_project.png'),
    bailian_chat: require('../statics/sidebar_chat_type_standard.png'),
    tiangong_search: require('../statics/sidebar_chat_type_work.png'),
    project_manager_expert: require('../statics/sidebar_chat_type_project_manager.png'),
    sustainable_experts: require('../statics/sidebar_chat_type_sustainable.png'),
    material_experts: require('../statics/sidebar_chat_type_material.png'),
    software_experts: require('../statics/sidebar_chat_type_software.png'),
    business_experts: require('../statics/sidebar_chat_type_business.png'),
    office_experts: require('../statics/sidebar_chat_type_office.png'),
    agent_chat: require('../statics/sidebar_chat_type_line_stone.png'),
}

var chatTypeOptionsSortByWeight = function(chatTypeOptions) {
    return chatTypeOptions.slice().sort((a, b) => b.weight - a.weight);
}

var getTopActiveModelsByWeight = function(chatTypeOptions, topCount) {
    // 过滤出 active 为 true 的模型
    const activeModels = chatTypeOptions.filter(option => option.active && option.permissions.includes('execute'));

    // 根据 weight 降序排序
    const sortedActiveModels = activeModels.sort((a, b) => b.weight - a.weight);

    // 获取 weight 最大的 topCount 个模型
    return sortedActiveModels.slice(0, topCount);
}

export {
    chatTypeIcons,
    chatTypeSidebarIcons,
    chatTypeOptionsSortByWeight,
    getTopActiveModelsByWeight,
}