import { useState } from 'react'
import { Form, Button, Input, Upload, Checkbox } from 'antd'
import { connect, useDispatch, useSelector } from 'react-redux'
import { UploadOutlined } from '@ant-design/icons';

import styles from './index.module.css'
import { actionCreators } from '../../store';
import KnowledgeBaseGoBack from '../KnowledgeBaseGoBack'

function KnowledgeBaseImageUpload() {
    const dispatch = useDispatch()
    const [fileList, setFileList] = useState([])
    const [zipFileList, setZipFileList] = useState([])
    const [isOverride, setIsOverride] = useState(false)
    const [uploading, setUploading] = useState(false);
    let active_model_name = useSelector(state => state.get('admin').get('active_model_name'))

    // 在文件选择后更新 fileList，但不执行上传操作
    const beforeUpload = (file) => {
        setFileList((prevFileList) => [...prevFileList, file]);
        return false; // 返回 false 以停止自动上传
    }

    // 移除文件
    const handleRemove = (file) => {
        setFileList((prevFileList) => prevFileList.filter(item => item.uid !== file.uid));
    }

    const handleChange = ({ fileList: newFileList }) => {
        // 如果已经有文件存在，替换为最新的文件
        const latestFile = newFileList.slice(-1);
        setFileList(latestFile);
    }

    const handleZipChange = ({ fileList: newFileList }) => {
        // 如果已经有文件存在，替换为最新的文件
        const latestFile = newFileList.slice(-1);
        setZipFileList(latestFile);
    }

    // 在文件选择后更新 fileList，但不执行上传操作
    const beforeZipUpload = (file) => {
        setZipFileList((prevFileList) => [...prevFileList, file]);
        return false; // 返回 false 以停止自动上传
    }

    // 移除文件
    const handleZipRemove = (file) => {
        setZipFileList((prevFileList) => prevFileList.filter(item => item.uid !== file.uid));
    }

    const handleFormOnFinish = (values) => {
        if (uploading) {
            console.log("返回");
            return
        }

        let formData = new FormData();
        formData.append('override', isOverride)
        formData.append('image_excel', fileList[0].originFileObj)
        formData.append('image_zip', zipFileList[0].originFileObj)

        setUploading(true)
        dispatch(actionCreators.fetchUploadImageFiles(formData))
            .then(data => {
                // 处理上传成功
                console.log('Upload Success:', data);
            })
            .catch(error => {
                // 处理上传失败
                console.error('Upload Failed:', error);
            })
            .finally(() => {
                setUploading(false); // 重置上传状态
                setFileList([])
                setZipFileList([])
                setIsOverride(false)
            });
    }

    const handleFormOnFinishFailed = (errorInfo) => {
        console.log(errorInfo);
    }

    return (
        <div className={styles.main}>
            <KnowledgeBaseGoBack />

            <Form
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                onFinish={handleFormOnFinish}
                onFinishFailed={handleFormOnFinishFailed}
                labelAlign="left"
                // ref={this.formRef}
                preserve={false}
            >
                <Form.Item
                    label="知识库"
                    name="knowledge_base"
                >
                    <span>{active_model_name}</span>
                </Form.Item>

                <Form.Item
                    label="知识库类型"
                    name="knowledge_base_type"
                >
                    <span>图片类型</span>
                </Form.Item>

                <Form.Item
                    label="是否覆盖上传"
                    name="override"
                >
                    <Checkbox
                        checked={isOverride}
                        onChange={() => {setIsOverride(!isOverride)}}
                    >
                        tips：同名文件支持覆盖
                    </Checkbox>
                </Form.Item>

                <Form.Item
                    label="上传图片的描述文件(.xlsx)"
                    name="upload_files"
                    rules={[{ required: true, message: '请选择要上传的文件' }]}
                >
                    <Upload.Dragger
                        fileList={fileList}
                        beforeUpload={beforeUpload}
                        onRemove={handleRemove}
                        accept={".xlsx,.xls"}
                        onChange={handleChange}
                    >
                        <p className="ant-upload-drag-icon">
                            <UploadOutlined />
                        </p>
                        <p className="ant-upload-text">
                            支持 .xlsx, .xls 等格式
                        </p>
                        <p className="ant-upload-text">
                            点击或拖拽文件到此区域上传文件
                        </p>
                    </Upload.Dragger>
                </Form.Item>

                <Form.Item
                    label="上传图片的压缩包(.zip)"
                    name="upload_zip_files"
                    rules={[{ required: true, message: '请选择要上传的文件' }]}
                >
                    <Upload.Dragger
                        fileList={zipFileList}
                        beforeUpload={beforeZipUpload}
                        onRemove={handleZipRemove}
                        accept={".zip"}
                        onChange={handleZipChange}
                    >
                        <p className="ant-upload-drag-icon">
                            <UploadOutlined />
                        </p>
                        <p className="ant-upload-text">
                            支持 .zip 等格式
                        </p>
                        <p className="ant-upload-text">
                            点击或拖拽文件到此区域上传文件
                        </p>
                    </Upload.Dragger>
                </Form.Item>

                <Form.Item
                    label="文档识别"
                    name="document_recognition"
                >
                    <span>文档智能解析</span>
                </Form.Item>

                <Form.Item
                    style={{display: 'flex', justifyContent: 'center', textAlign: 'center'}}
                >
                    {
                        uploading ? (
                            <Button type="primary" loading>
                                Uploading
                            </Button>
                        ) : (
                            <Button type="primary" htmlType="submit">
                                上传文件
                            </Button>
                        )
                    }
                </Form.Item>
            </Form>
        </div>
    )
}

export default KnowledgeBaseImageUpload