import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Confetti from 'react-confetti'

import { actionCreators as homeActionCreators } from '../../pages/home/store'


const MyConfetti = () => {
    const dispatch = useDispatch()
    let isExperienceConfettiOpen = useSelector(state => state.get('home').get('isExperienceConfettiOpen'))
    console.log("isExperienceConfettiOpen = ", isExperienceConfettiOpen);

    useEffect(() => {
        setTimeout(() => {
            dispatch(homeActionCreators.setIsExperienceConfettiOpen(false))
        }, 5000); // 5秒后停止礼花
    }, [isExperienceConfettiOpen])

    return (
        <>
            {isExperienceConfettiOpen && (
                <Confetti
                    recycle={false} // 设置为 false 以确保礼花在一定时间后停止
                    numberOfPieces={600} // 设置礼花的数量
                    gravity={0.3} // 控制飘落速度
                    wind={0.01} // 控制水平飘落方向
                    style={{ zIndex: 1001 }}
                />
            )}
        </>
    )
}

export default MyConfetti