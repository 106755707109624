import React, { Component } from 'react'
import { connect } from 'react-redux'

// import { actionCreators } from '../../store'
import ModalImage from '../ModalImage'
import styles from './index.module.css'

export default function ModalImageView({isModalOpen, setIsModalOpen, showImageUrl, setShowImageUrl}) {
    const handleFileImgVideoVisible = (e) => {
        let id = e.target.id
        if (id !== 'id_show_img_url') {
            setIsModalOpen(false)
            setShowImageUrl('')
        }
    }

    return (
        isModalOpen ? (
            <div
                className={styles.main}
                onClick={handleFileImgVideoVisible}
            >
                <ModalImage
                    setIsModalOpen={setIsModalOpen}
                    showImageUrl={showImageUrl}
                    setShowImageUrl={setShowImageUrl}
                />
            </div>
        ) : null
    )
}
