import * as actionTypes from './actionTypes'
import { actionCreators as loginActionCreators} from '../../login/store'
import { message } from 'antd'
import { transformknowledgeBase, findConversationId, extractConversationIdAndId,
    getMessagesFromAllChatData, handleReponseStreamChatData, extractFirstLink,
    extractFirstImageContainer, handleStreamDataTruncation,
    createNewMessage, createNewConversation, formatResponseDataToLocal,
    fetchWithCookieRefresh, chatTypeOptionsSortByWeight, createController,
    uniqueByImageId, addKeyWithExtrainfoIdAndScore,
    mergeWithIsActive, transformDateList, formatResponseConversationsDataToLocal,
    formatResponseMessagesDataToLocal, handleResponseError,
} from '../../../utils'


let currentController = null;
let timerId = null

const abortControllerAbort = () => {
    if (currentController) {
        currentController.abort();
        clearTimeout(timerId)
        // console.log("~~~~~clearTimeout(timerId)~~~~~");
    }
}

export const cancelFetchAction = () => {
    return (dispatch, getState) => {
        let is_thinking = getState().get("home").get("is_thinking")
        if (is_thinking) {
            abortControllerAbort()
            dispatch(isThinkingAction(false))
            dispatch(cancelFetchRequest())
        }
    }
}

const cancelFetchRequest = () => {
    return (dispatch, getState) => {
        let all_chat_data = getState().get("home").get("all_chat_data").toJS()
        let old_date = getState().get("home").get("active_date")
        let old_active_topicid = getState().get("home").get("active_topicid")
        let conversation_id = ''
        let thinking_message_id = getState().get("home").get("thinking_message_id")
        let temp_messages = []

        // 将原先激活的 conversation 置灰
        all_chat_data[old_date]?.forEach((item, index) => {
            if (item.topic_id === old_active_topicid) {
                conversation_id = item.conversation_id
                temp_messages = item.data
            }
        })

        // 查找到对应的 index
        let obj = temp_messages.find(item => item.topic_message_id === thinking_message_id)
        let m = ''
        if (obj) {
            m = obj.text
            m = removeSuffix(m)
        }
        console.log("m = ", m);

        fetchWithCookieRefresh('/api/llm/chat/cancel', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                conversation_id: conversation_id,
                message_id: thinking_message_id,
                response: m,
            }),
        }).then(data => {
            console.log("/api/llm/chat/cancel data = ", data);
        }).catch(error => {})
    }
}

export const setPromptAction = (value) => {
    return {
        type: actionTypes.SET_PROMPT_ACTION,
        value: value,
    }
}

export const createNewMessageChatDataAction = (text) => {
    return (dispatch, getState) => {
        let all_chat_data = getState().get("home").get("all_chat_data").toJS()
        let active_date = getState().get("home").get("active_date")
        let active_topicid = getState().get("home").get("active_topicid")
        let temp_prompt = text.trim()
        let active_index = ''

        let user_message = createNewMessage({role: 'user', text: temp_prompt, status: 'pass'})
        let ai_message = createNewMessage({role: 'ai', topic_message_id: user_message.topic_message_id, text: '', status: 'loading'})
        user_message.topic_message_id = `${user_message.topic_message_id}query`

        all_chat_data[active_date]?.forEach((item, index) => {
            if (item.topic_id === active_topicid) {
                if (item.data.length === 0) {
                    item.conversation_name = temp_prompt
                }
                item.data.push(user_message)
                item.data.push(ai_message)
                active_index = item.data.length - 1
            }
        })

        dispatch(allChatDataAction(all_chat_data))
        dispatch(setPromptAction(''))
        dispatch(postChatCompletionsAction({value: temp_prompt, message_id: ai_message.message_id, active_index: active_index}))
    }
}

export const replaceAIChatDataAction = ({role='user', text='', status='pass', active_index=-1, active_date='today', active_topicid='', message_id=''}={}) => {
    return (dispatch, getState) => {
        let all_chat_data = getState().get("home").get("all_chat_data").toJS()
        let messages = getMessagesFromAllChatData(all_chat_data, active_date, active_topicid)

        if (messages.length >= 1) {
            if (active_index === -1) {
                active_index = messages.length - 1
            }

            messages[active_index].role = role
            messages[active_index].text = text
            messages[active_index].status = status
            if (message_id !== '') {
                messages[active_index].message_id = message_id
                messages[active_index].topic_message_id = message_id
            }

            dispatch(allChatDataAction(all_chat_data))
        } else {
            console.log("没有可替换的数据，之前的问答已结束");
        }
    }
}

export const sendAskAction = (prompt) => {
    return (dispatch, getState) => {
        let active_date = getState().get("home").get("active_date")
        let active_topicid = getState().get("home").get("active_topicid")

        dispatch(allowProgramScrollAction(true))
        dispatch(isThinkingAction(true))
        dispatch(suggestionListAction([]))

        if (active_date === '' && active_topicid === '') {
            // 在一个最新创建的 conversation 中发送的第一个请求
            console.log("sendAskAction 全新的 conversation 的问答");
            dispatch(postServerForCreateNewConversation(prompt.trim()))
        } else {
            // 在已有 message 的 conversation 里面再次发送新的问题
            console.log("sendAskAction 在已有的 conversation 里面问答");
            dispatch(createNewMessageChatDataAction(prompt))
        }
    }
}

const postChatCompletionsAction = ({value='', message_id='', active_index=-1}={}) => {
    // 每次请求之前创建一个新的 AbortController 实例
    if (currentController) {
        currentController.abort();
    }
    currentController = createController();

    return async (dispatch, getState) => {
        let temp_all_chat_data = getState().get("home").get("all_chat_data").toJS()
        let active_date = getState().get("home").get("active_date")
        let active_topicid = getState().get("home").get("active_topicid")
        let conversation_id = findConversationId(temp_all_chat_data, active_date, active_topicid)
        // if (conversation_id === '') {
        //     let conversation_id_status = await dispatch(postServerForCreateConversation(active_date, active_topicid, active_index))
        //     if (conversation_id_status === false || !currentController) {
        //         return;
        //     } else {
        //         conversation_id = conversation_id_status
        //     }
        // }
        // 设置当前正在对话的 message_id
        dispatch(thinkingMessageIdAction(message_id))

        let body = {
            query: value,
            conversation_id: conversation_id,
            message_id: message_id,
            knowledge_base_name: '灵石1.1',
        }
        console.log("body = ", body);

        // let url = ''
        // if (r_type === 'ai_chat') {
        //     url = '/api/llm/chat'
        // } else if (r_type === 'link_stone') {
        //     url = '/api/agent/chat'
        // } else if (r_type === 'web_search') {
        //     url = '/api/search_engine/tiangong/chat'
        // } else {
        //     // r_type = 'bailian_chat' || 'knowledge_base_chat'
        //     // body['knowledge_base_name'] = expert_interface_name
        //     url = '/api/knowledge_base/chat'
        // }
        let url = '/api/agent/chat'
        let messageId = ''

        fetchWithCookieRefresh(url, {
            method: 'POST',
            headers: {
                'Accept': 'text/event-stream',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
            signal: currentController.signal,
        }, { processResponse: false })
        .then(response => {
            if (!response.ok) {
                throw new Error('HTTP error: ' + response.status);
            }

            // 设置经验值
            let current_experience = getState().get("home").get("current_experience")
            dispatch(setCurrentExperience(current_experience + 1))
            let temp_mall_components_list = getState().get("home").get("mall_components_list").toJS()

            // 获取响应头中的 message_id
            messageId = response.headers.get('X-Message-ID');
            console.log("获取响应头中的 messageId = ", messageId);
            if (messageId) {
                dispatch(thinkingMessageIdAction(messageId))
            }
            let reader = response.body.getReader();
            let allContent = ''
            let all_image_ids = []
            let all_suggestion = []
            let all_search_result = []
            let isCodeBlock = false
            let stream_data_truncation = ''
            let all_error_counts = 0

            // 逐步接收流式数据
            function readStream() {
                reader.read().then(({ done, value }) => {
                    // 判断流式数据的接收是否结束
                    if (done) {
                        console.log('Stream finished');
                        // 更改最后一个 ai 回答的状态
                        let all_chat_data = getState().get("home").get("all_chat_data").toJS()
                        let index_n = 0
                        // 去重图片
                        all_image_ids = uniqueByImageId(all_image_ids)
                        all_image_ids = addKeyWithExtrainfoIdAndScore(all_image_ids)
                        all_chat_data[active_date]?.forEach((item) => {
                            if (item.topic_id === active_topicid) {
                                if (active_index !== -1) {
                                    item.data[active_index].status = 'pass'
                                    item.data[active_index].message_id = messageId
                                    item.data[active_index].topic_message_id = messageId
                                    item.data[active_index].image_ids = [...all_image_ids]
                                    item.data[active_index].suggestion = [...all_suggestion]
                                    item.data[active_index].search_result = [...all_search_result]
                                    item.has_get_images = true
                                    if (allContent !== '') {
                                        item.data[active_index].text = allContent
                                    } else {
                                        item.data[active_index].text = '请求超时，请重新提问'
                                    }
                                    index_n = active_index
                                } else if (item.data.length >= 1) {
                                    let last_index = item.data.length - 1
                                    item.data[last_index].status = 'pass'
                                    item.data[last_index].message_id = messageId
                                    item.data[last_index].topic_message_id = messageId
                                    item.data[last_index].image_ids = [...all_image_ids]
                                    item.data[last_index].suggestion = [...all_suggestion]
                                    item.data[last_index].search_result = [...all_search_result]
                                    if (allContent !== '') {
                                        item.data[last_index].text = allContent
                                    } else {
                                        item.data[last_index].text = '请求超时，请重新提问'
                                    }
                                    item.has_get_images = true
                                    index_n = last_index
                                }
                            }
                        })
                        dispatch(allChatDataAction(all_chat_data))
                        dispatch(isThinkingAction(false))

                        // 获取图片等额外的数据
                        if (all_image_ids.length !== 0) {
                            // all_image_ids.forEach((item) => {
                            //     dispatch(postChatImage(item, active_topicid, index_n))
                            // })
                            // dispatch(getIndexImage(all_image_ids, active_date, active_topicid, index_n))
                            dispatch(getMessageImage(all_image_ids, active_date, active_topicid, index_n, messageId))
                        }

                        if (all_suggestion.length !== 0) {
                            // dispatch(suggestionAction(all_suggestion, active_topicid, index_n))
                            dispatch(suggestionListAction(all_suggestion))
                        }

                        if (all_search_result.length !== 0) {
                            dispatch(renderSearchResult(all_search_result, active_date, active_topicid, index_n))
                        }

                        return;
                    }

                    // 将 Uint8Array 转换为字符串
                    let json_data = new TextDecoder('utf-8').decode(value)
                    let temp_json_data = json_data
                    // console.log("json_data origin = ", json_data);

                    let pattern = /: ping - (\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}\.\d{6})(\r\n)*/g;
                    json_data = json_data.replace(pattern, "");
                    // console.log("json_data replace ping = ", json_data);
                    // 处理流数据可能会被截断的情况
                    let [stream_error, correct_str_value] = handleStreamDataTruncation(stream_data_truncation, json_data)
                    if (stream_error) {
                        all_error_counts += 1
                        if (all_error_counts >= 3) {
                            dispatch(sendLogToServer("问答中-文本解析错误", '', {'origin_data': temp_json_data, 'before_handle_stream_error': stream_data_truncation, 'before_handle_json_data': json_data, 'after_handle_stream_error': stream_error, 'after_handle_stream_data': correct_str_value}))
                        }
                    }
                    stream_data_truncation = stream_error
                    console.log("避免数据流断层的处理函数 stream_data_truncation = ", stream_data_truncation);
                    console.log("避免数据流断层的处理函数 correct_str_value = ", correct_str_value);
                    // console.log("correct_str_value = ", correct_str_value);
                    // console.log("========================");

                    let object_data = handleReponseStreamChatData({text: correct_str_value, mall_components_list: temp_mall_components_list})
                    console.log("转换为浏览器可用的数据 object_data = ", object_data);

                    let image_ids_arr = object_data.image_ids_arr
                    if (image_ids_arr.length > 0) {
                        all_image_ids = all_image_ids.concat(image_ids_arr)
                    }

                    let suggestion_arr = object_data.suggestion_arr
                    if (suggestion_arr.length > 0) {
                        all_suggestion = [...suggestion_arr]
                    }

                    let search_result_arr = object_data.search_result_arr
                    if (search_result_arr.length > 0) {
                        all_search_result = [...search_result_arr]
                    }

                    let text = object_data.result_text
                    processTextChunk(text, active_date, active_topicid)
                })
            }

            function processTextChunk(chunk, active_date, active_topicid, index = 0) {
                if (index < chunk.length) {
                    let content = chunk[index];
                    let nextIndex = index + 1; // 默认情况下，每次递增索引

                    // 如果当前处于代码块内部，并且遇到了结束的 ```
                    if (isCodeBlock && chunk.substring(index).startsWith('```')) {
                        content = '```'; // 将 ``` 添加到内容中
                        nextIndex = index + 3; // 移动索引跳过 ```

                        // 先添加到 allContent 然后再更改状态
                        allContent += content;
                        updateDisplay(allContent); // 更新显示内容

                        isCodeBlock = false; // 更新状态为不在代码块内

                        // 继续处理后续文本
                        timerId = setTimeout(() => {
                            processTextChunk(chunk, active_date, active_topicid, nextIndex);
                        }, 20);
                        return; // 提前返回防止执行后续代码
                    }

                    // 检查是否进入代码块
                    if (!isCodeBlock && chunk.substring(index).startsWith('```')) {
                        isCodeBlock = true; // 切换代码块状态
                        content = '```'; // 将 ``` 添加到内容中
                        nextIndex = index + 3; // 移动索引跳过 ```
                    } else if (!isCodeBlock && content === '[') {
                        // 特殊处理链接格式但只在非代码块的情况下
                        let ex = extractFirstLink(chunk, index);
                        if (ex !== null) {
                            content = ex.link;
                            nextIndex = ex.index + ex.link.length; // 移动索引到链接之后
                        }
                    } else if (!isCodeBlock && content === '<') {
                        let ex = extractFirstImageContainer(chunk, index)
                        if (ex !== null) {
                            content = ex.link;
                            nextIndex = ex.index + ex.link.length; // 移动索引到链接之后
                        }
                    }

                    allContent += content; // 将当前内容添加到 allContent
                    updateDisplay(allContent); // 更新显示内容

                    timerId = setTimeout(() => {
                        processTextChunk(chunk, active_date, active_topicid, nextIndex);
                    }, 20); // 延迟50ms显示下一个字符
                } else {
                    readStream(); // 当前块处理完毕，读取下一块数据
                }
            }

            // 分离出更新显示内容的函数以便复用
            function updateDisplay(content) {
                const textToDisplay = isCodeBlock ?
                    content + '⬤' :
                    content + '<span style="margin-left: 10px;color: #74bdc6;">⬤</span>';

                dispatch(replaceAIChatDataAction({
                    role: 'ai',
                    text: textToDisplay,
                    status: 'loading',
                    active_index: active_index,
                    active_date: active_date,
                    active_topicid: active_topicid,
                    message_id: messageId,
                }));
            }

            // 开始接收流式数据
            readStream();
        })
        .catch(error => {
            // let msg = error.message
            // console.log(url, " ~~~~~~~~~~~~ error = ", error);
            // console.log(url, " ~~~~~~~~~~~~ error.name = ", error.name);
            // console.log(url, " ~~~~~~~~~~~~ error.message = ", error.message);
            clearTimeout(timerId)
            if (error.name === 'AbortError') {
                console.log(url, " error 停止 fetch 的请求");
                // msg = "网络出现问题，请重新提问！"
            } else {
                dispatch(sendLogToServer("问答函数的统一错误处理", error.message, {'name': error.name}))
            }

            let all_chat_data = getState().get("home").get("all_chat_data").toJS()

            all_chat_data[active_date]?.forEach((item) => {
                if (item.topic_id === active_topicid) {
                    if (active_index !== -1) {
                        item.data[active_index].status = 'pass'
                        if (messageId !== '') {
                            item.data[active_index].message_id = messageId
                            item.data[active_index].topic_message_id = messageId
                        }
                    } else if (item.data.length >= 1) {
                        item.data[item.data.length - 1].status = 'pass'
                        // item.data[item.data.length - 1].text = msg
                        if (messageId !== '') {
                            item.data[active_index].message_id = messageId
                            item.data[active_index].topic_message_id = messageId
                        }
                    }
                }
            })
            dispatch(allChatDataAction(all_chat_data))
            dispatch(isThinkingAction(false))
        })
    }
}

const insertStringAt = (original, index, stringToInsert) => {
    // 使用 slice 分割原始字符串并插入新字符串
    return original.slice(0, index) + stringToInsert + original.slice(index);
}

export const getSingleConversationChatIndexImages = (active_date, active_topicid, active_conversation_messages) => {
    return (dispatch, getState) => {
        active_conversation_messages.forEach((item, index) => {
            if (item.role !== 'user') {
                dispatch(getIndexImage(item.image_ids, active_date, active_topicid, index))
            }
        })
    }
}

// 检查变量是否是字符串
// function isString(variable) {
//     return typeof variable === 'string' || variable instanceof String;
// }

  // 检查变量是否是对象（非null且不是数组）
function isObject(variable) {
    return typeof variable === 'object' && variable !== null && !Array.isArray(variable);
}

const getIndexImage = (image_ids, active_date, active_topicid, index_n) => {
    return (dispatch, getState) => {
        let all_chat_data = getState().get("home").get("all_chat_data").toJS()
        let messages = getMessagesFromAllChatData(all_chat_data, active_date, active_topicid)

        image_ids.forEach((item) => {
            if (isObject(item)) {
                let template = indexImageTemplate(item.imgs)
                let item_index = parseInt(item.index, 10)
                if (messages[index_n].text.length >= item_index) {
                    let newText = insertStringAt(messages[index_n].text, item_index, template)
                    messages[index_n].text = newText
                } else {
                    console.log("文本太多，给定的下标 index 太大");
                }
            }
        })

        dispatch(allChatDataAction(all_chat_data))
    }
}

const indexImageTemplate = (data) => {
    let temp = ''
    data.forEach((item) => {
        if (item === '') {
            temp += ''
        } else {
            temp += `<div class="msg_images_id_container"><img src="${item}" class="msg_images_id"/><div class="msg_images_id_overlay" data-url="${item}"></div></div>`
        }
    })

    if (temp === '') {
        return temp
    } else {
        let template = `\n<div style="display: flex;flex-wrap: wrap;margin-top: 20px;">${temp}</div>\n`
        return template
    }
}

const getSingleConversationMessageImage = (active_date, active_topicid, active_conversation_messages) => {
    return (dispatch, getState) => {
        active_conversation_messages.forEach((item, index) => {
            if (item.role !== 'user') {
                dispatch(getMessageImage(item.image_ids, active_date, active_topicid, index, item.message_id))
            }
        })
    }
}

const getMessageImage = (image_ids, active_date, active_topicid, index_n, message_id) => {
    return (dispatch, getState) => {
        let all_chat_data = getState().get("home").get("all_chat_data").toJS()

        let imgs = image_ids.slice(0, 3)
        let templates = messageImageTemplate(imgs, message_id, index_n)

        all_chat_data[active_date]?.forEach((item) => {
            if (item.topic_id === active_topicid && item.data.length > index_n) {
                item.data[index_n].text_extra_img = templates
            }
        })

        dispatch(allChatDataAction(all_chat_data))
    }
}

export const postChatImage = (image_id, active_date, active_topicid, index_n) => {
    return (dispatch, getState) => {
        fetchWithCookieRefresh('/api/knowledge_base/bailian/chat/images', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ image_id: image_id }),
        }, { processResponse: false })
        .then(response => {
            if (!response.ok) {
                throw new Error('/knowledge_base/bailian/chat/images Network response was not ok');
            }
            return response.blob();
        })
        .then(blob => {
            let imageUrl = URL.createObjectURL(blob);

            let all_chat_data = getState().get("home").get("all_chat_data").toJS()
            all_chat_data[active_date]?.forEach((item) => {
                if (item.topic_id === active_topicid && item.data.length > index_n) {
                    item.data[index_n].image_urls.push(imageUrl)
                    if (item.data[index_n].image_ids.length === item.data[index_n].image_urls.length) {
                        let img_template = messageImageTemplate(item.data[index_n].image_urls)
                        item.data[index_n].text += img_template
                    }
                }
            })

            dispatch(allChatDataAction(all_chat_data))
        })
        .catch(error => {
            console.error('/knowledge_base/bailian/chat/images 获取图片失败', error);
        })
    }
}

const messageImageTemplate = (data, message_id, index_msg) => {
    let temp = ''
    let msg_images_id_container_class = ''
    if (data.length === 3) {
        msg_images_id_container_class = 'msg_images_id_container_num3'
    } else if (data.length === 2) {
        msg_images_id_container_class = 'msg_images_id_container_num2'
    } else {
        msg_images_id_container_class = 'msg_images_id_container_num1'
    }

    data.forEach((item) => {
        if (item === '') {
            temp += ''
        } else {
            temp += `<div class="msg_images_id_container ${msg_images_id_container_class}"><img src="${item.thumbnail_url ? item.thumbnail_url : item.url}" class="msg_images_id"/><div class="msg_images_id_overlay" data-url="${item.url}"><div class="msg_images_id_like ${item.extrainfo_score && item.extrainfo_score === 1 ? 'msg_images_id_like_active' : ''}" data-message_id="${message_id}" data-url="${item.url}" data-id="${ item.extrainfo_id ? item.extrainfo_id : '' }" data-score="${item.extrainfo_score ? item.extrainfo_score : ''}" data-index_msg="${index_msg}"></div></div></div>`
        }
    })

    if (temp === '') {
        return temp
    } else {
        let template = `<div style="margin-top: 20px;"><div style="font-size: 16px; font-weight: bold;">希望下列示意图对您的设计有所帮助：</div><div class="msg_images_id_box">${temp}</div></div>\n`
        return template
    }
}

export const renderSingleConversationMessageSearchResult = (active_date, active_topicid, active_conversation_messages) => {
    return (dispatch, getState) => {
        active_conversation_messages.forEach((item, index) => {
            if (item.role !== 'user') {
                dispatch(renderSearchResult(item.search_result, active_date, active_topicid, index))
            }
        })
    }
}

const renderSearchResult = (search_result, active_date, active_topicid, index_n) => {
    return (dispatch, getState) => {
        let all_chat_data = getState().get("home").get("all_chat_data").toJS()

        let templates = searchResultTemplate(search_result)

        all_chat_data[active_date]?.forEach((item) => {
            if (item.topic_id === active_topicid && item.data.length > index_n) {
                item.data[index_n].text_extra_search = templates
            }
        })

        dispatch(allChatDataAction(all_chat_data))
    }
}

const searchResultTemplate = (data) => {
    let temp = ''
    data.forEach((item, index) => {
        temp += `<div class="msg_search_result_item"><div class="msg_search_result_item_n">${index + 1}</div><a class="msg_search_result_item_link" href="${item.seeMoreUrl}" target="_blank"><div class="msg_search_result_item_info">${item.snippet ? item.snippet : ''}</div></a></div>`
        if (data.length !== index + 1) {
            temp += `<div class="msg_search_result_item_line"></div>`
        }
    })

    if (temp === '') {
        return temp
    } else {
        let template = `<div class="msg_search_result_container msg_search_result_container_shrink"><div class="msg_search_result_title_box"><span class="msg_search_result_title">${data.length}处资料引用</span><div class="msg_search_result_title_icon"></div></div><div class="msg_search_result_item_box">${temp}</div></div>\n`
        return template
    }
}

// const singleConversationSuggestionAction = (active_date, active_topicid, active_conversation_messages) => {
//     return (dispatch, getState) => {
//         active_conversation_messages.forEach((item, index) => {
//             if (item.role !== 'user' && item.suggestion.length !== 0) {
//                 dispatch(suggestionAction(item.suggestion, active_date, active_topicid, index))
//             }
//         })
//     }
// }

// const suggestionAction = (suggestion, active_date, active_topicid, index_n) => {
//     return (dispatch, getState) => {
//         let templateSuggestion = function(e) {
//             var t = `<div class="response_extra_suggestion_item" data-info="${e.text}" style="display: flex;justify-content: center;align-items: center;background-color: #fff; border-radius: 20px; padding: 5px 20px; border: 1px solid #ccc; cursor: pointer; margin: 10px 10px 0 0;">
// <span>${e.text}</span><svg t="1712043223258" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5154" width="16" height="16"><path d="M426.666667 384V213.333333l-298.666667 298.666667 298.666667 298.666667v-174.933334c213.333333 0 362.666667 68.266667 469.333333 217.6-42.666667-213.333333-170.666667-426.666667-469.333333-469.333333z" p-id="5155"></path></svg>
// </div>`
//             return t
//         }

//         let content = ''
//         suggestion.forEach((item) => {
//             content += templateSuggestion(item)
//         })

//         let template = `<div class="response_extra_suggestion" style="display: flex; flex-wrap: wrap;">${content}</div>`

//         let all_chat_data = getState().get("home").get("all_chat_data").toJS()
//         all_chat_data[active_date]?.forEach((item) => {
//             if (item.topic_id === active_topicid) {
//                 if (item.data.length > index_n) {
//                     item.data[index_n].text += template
//                 }
//             }
//         })
//         dispatch(allChatDataAction(all_chat_data))
//     }
// }

// const postSingleConversationChatImages = (active_date, active_topicid, active_conversation_messages) => {
//     return (dispatch, getState) => {
//         active_conversation_messages.forEach((item, index) => {
//             if (item.role !== 'user') {
//                 item.image_ids.forEach((element) => {
//                     dispatch(postChatImage(element, active_date, active_topicid, index))
//                 })
//             }
//         })
//     }
// }

export const isThinkingAction = (status) => ({
    type: actionTypes.IS_THINKING_ACTION,
    status: status,
})

export const isStopFetchResponding = (status) => ({
    type: actionTypes.IS_STOP_FETCH_RESPONDING_ACTION,
    status: status,
})

export const testPostAccountLoginAction = () => {
    return (dispatch) => {
        fetch('/api/account/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                username: "zhangsan",
                password: "123456",
            })
        })
        .then(response => {
            if (response.ok) {
                dispatch(loginActionCreators.userDataAction({
                    "uuid": "ea311f4d7b374a7b8298fe352973e51b",
                    "username": "测试",
                    "avatar": "",
                }))
            }
        })
        .then(data => {
            console.log("/account/login data = ", data);
        })
        .catch(error => {
            console.log("/account/login error = ", error);
        })
    }
}

export const allChatDataAction = (data) => ({
    type: actionTypes.ALL_CHAT_DATA_ACTION,
    data: data,
})

export const switchItemAction = (active_date, active_topicid) => {
    return async (dispatch, getState) => {
        // 终止旧的响应，切换到新的对话
        dispatch(cancelFetchAction())

        let all_chat_data = getState().get("home").get("all_chat_data").toJS()
        let old_date = getState().get("home").get("active_date")
        let old_active_topicid = getState().get("home").get("active_topicid")

        // 将原先激活的 conversation 置灰
        all_chat_data[old_date]?.forEach((item, index) => {
            if (item.topic_id === old_active_topicid) {
                item.active = false
                if (item.data.length >= 1) {
                    item.data[item.data.length - 1].status = 'pass'
                }
            }
        })

        let request_data = false
        let active_conversation_messages = []
        let display_suggestion = []
        // 将新激活的 conversation 置亮
        all_chat_data[active_date]?.forEach((item, index) => {
            if (item.topic_id === active_topicid) {
                item.active = true
                if (item.data.length === 0) {
                    // 如果数据为空，则去请求数据
                    request_data = true
                } else {
                    active_conversation_messages = item.data
                    display_suggestion = item.data?.[item.data.length - 1]?.suggestion
                }
            }
        })
        // 每次切换都允许代码去设置滚动条
        dispatch(allowProgramScrollAction(true))
        // 聊天记录数据相关
        dispatch(allChatDataAction(all_chat_data))
        dispatch(activeTopicIdAction(active_date, active_topicid))
        // 隐藏 个人概览、专家详情画面、组件商城、我的背包
        dispatch(isShowScreenPersonalOverview(false))
        dispatch(isShowScreenStoneModelMall(false))
        dispatch(isShowScreenPersonalBackpack(false))

        if (request_data) {
            // 激活刚点击的 conversation
            for (let item of all_chat_data[active_date]) {
                if (item.topic_id === active_topicid) {
                    console.log("before await");
                    let d = await dispatch(getMessagesForConversation(item.conversation_id))
                    console.log("await d = ", d);
                    if (d) {
                        active_conversation_messages = d
                        display_suggestion = d?.[d.length - 1]?.suggestion
                    }
                    item.data = d ? d : [];
                    break;
                }
            }
            dispatch(allChatDataAction(all_chat_data))
        }

        // 展示 images
        dispatch(getSingleConversationMessageImage(active_date, active_topicid, active_conversation_messages))
        dispatch(renderSingleConversationMessageSearchResult(active_date, active_topicid, active_conversation_messages))

        // 展示 suggestion
        if (display_suggestion) {
            dispatch(suggestionListAction(display_suggestion))
        } else {
            dispatch(suggestionListAction([]))
        }
    }
}

export const getMessagesForConversation = (conversation_id) => {
    return (dispatch, getState) => {
        // return fetchWithCookieRefresh(`/api/get_messages.json`, {
        return fetchWithCookieRefresh(`/api/history/get_messages?conversation_id=${conversation_id}`, {
            method: 'GET',
        })
        .then(data => {
            // 从后端获取的记录转换成前端需要的类型
            let temp_data = data.data
            console.log("/api/history/get_messages data = ", temp_data);
            let temp_mall_components_list = getState().get("home").get("mall_components_list").toJS()
            let d = formatResponseMessagesDataToLocal({data: temp_data, mall_components_list: temp_mall_components_list})
            return d
        })
        .catch(error => {})
    }
}

export const activeTopicIdAction = (active_date, active_topicid) => ({
    type: actionTypes.ACTIVE_TOPICID_ACTION,
    active_date: active_date,
    active_topicid: active_topicid,
})

export const createNewConversationAction = () => {
    return (dispatch, getState) => {
        // 终止旧的响应，切换到新的对话
        dispatch(cancelFetchAction())

        dispatch(isShowScreenPersonalOverview(false))
        dispatch(isShowScreenStoneModelMall(false))
        dispatch(isShowScreenPersonalBackpack(false))
        dispatch(clearChatHistoryActive())
        dispatch(allowProgramScrollAction(true))
    }
}

export const initAllChatHistory = () => {
    return async (dispatch, getState) => {
        let chat_date_list = getState().get("home").get("chat_date_list").toJS()
        let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

        // 使用 for...of 循环来支持 await
        for (let item of chat_date_list) {
            // 等待每个 getChatHistoryForDate 请求完成后再继续下一个
            await dispatch(getChatHistoryForDate(item.value, item.days_start, item.days_end, timezone))
        }

        // 如果从收藏页跳转过来，则勾选之前已选中的数据
        let all_chat_data = getState().get("home").get("all_chat_data").toJS()
        let active_date = getState().get("home").get("active_date")
        let active_topicid = getState().get("home").get("active_topicid")
        if (active_date !== '' && active_topicid !== '') {
            for (const item of all_chat_data[active_date]) {
                if (item.topic_id === active_topicid) {
                    item.active = true
                    break
                }
            }
            dispatch(allChatDataAction(all_chat_data))
        }
    }
}

const getChatHistoryForDate = (date_key, days_start, days_end, timezone) => {
    return (dispatch, getState) => {
        return fetchWithCookieRefresh(`/api/history/all_conversation_history?days_start=${days_start}&days_end=${days_end}&timezone=${timezone}`, {
            method: 'GET',
        })
        .then(data => {
            // 从后端获取的记录转换成前端需要的类型
            let temp_data = data.data
            console.log("历史记录 temp_data = ", temp_data);
            let format_data = formatResponseDataToLocal(temp_data)

            let all_chat_data = getState().get("home").get("all_chat_data").toJS()
            all_chat_data[date_key] = format_data

            dispatch(allChatDataAction(all_chat_data))
        })
        .catch(error => {})
    }
}

export const initAllConversationHistory = () => {
    return async (dispatch, getState) => {
        await dispatch(initAllConversationHistoryAction())

        // 如果是从收藏页跳转过来的，则跳转到指定的激活对话中
        let active_date = getState().get("home").get("active_date")
        let active_topicid = getState().get("home").get("active_topicid")
        if (active_date !== '' && active_topicid !== '') {
            dispatch(switchItemAction(active_date, active_topicid))
        }
    }
}

export const initAllConversationHistoryAction = () => {
    return async (dispatch, getState) => {
        let chat_date_list = getState().get("home").get("chat_date_list").toJS()
        let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
        let body = {
            timezone: timezone,
            days_split: transformDateList(chat_date_list)
        }

        // return fetchWithCookieRefresh(`/api/get_conversations.json`, {
        //     method: 'GET',
        return fetchWithCookieRefresh(`/api/history/get_conversations`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        })
        .then(data => {
            console.log("获取聊天记录 data = ", data);
            // 从后端获取的记录转换成前端需要的类型
            let temp_data = data.data
            let conversations = temp_data.conversations
            let all_chat_data = getState().get("home").get("all_chat_data").toJS()

            // 使用 for...of 循环
            for (let item of chat_date_list) {
                all_chat_data[item.value] = formatResponseConversationsDataToLocal(conversations[`${item.days_end}`])
            }

            dispatch(allChatDataAction(all_chat_data))
        })
        .catch(error => {})
    }
}

export const isZoomAction = (status) => ({
    type: actionTypes.IS_ZOOM_ACTION,
    status: status,
})

export const handleUserinfoModalOpenAction = () => {
    return (dispatch, getState) => {
        dispatch(isUserinfoModalOpenAction(true))
        let user_data = getState().get("login").get("user_data").toJS()
        dispatch(nicknameAction(user_data.nickname))
    }
}

export const isUserinfoModalOpenAction = (status) => ({
    type: actionTypes.IS_USERINFO_MODAL_OPEN_ACTION,
    status: status,
})

export const collectConversationAction = (active_date, active_topicid, favorites_type, conversation_id) => {
    return (dispatch, getState) => {
        fetchWithCookieRefresh('/api/history/collection_conversation', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                conversation_id: conversation_id,
                favorites_type: favorites_type,
            })
        })
        .then(data => {
            let all_chat_data = getState().get("home").get("all_chat_data").toJS()
            all_chat_data[active_date]?.forEach((item) => {
                if (item.topic_id === active_topicid) {
                    item.favorites_type = favorites_type
                }
            })

            dispatch(allChatDataAction(all_chat_data))
        })
        .catch(error => {
            handleResponseError(message, error)
        })
    }
}

const deleteConversation = async (dispatch, getState, active_date, active_topicid) => {
    let all_chat_data = getState().get("home").get("all_chat_data").toJS()
    let current_active_topicid = getState().get("home").get("active_topicid")

    // 当前分类中已不存在任何对话
    if (all_chat_data[active_date]?.length === 1) {
        all_chat_data[active_date] = [];
        dispatch(activeTopicIdAction('', ''))
        dispatch(isShowScreenPersonalOverview(true))
        dispatch(allChatDataAction(all_chat_data))
        return;
    }

    // 不属于正在激活的 conversation
    if (current_active_topicid !== active_topicid) {
        let index = all_chat_data[active_date]?.findIndex(item => item.topic_id === active_topicid);
        if (index > -1) {
            all_chat_data[active_date].splice(index, 1);
            dispatch(allChatDataAction(all_chat_data))
            return;
        }
    }

    let next_active_topic = ''

    // 找到具有给定 topic_id 的元素的索引
    let index = all_chat_data[active_date]?.findIndex(item => item.topic_id === active_topicid);
    let active_conversation_messages = []
    let display_suggestion = []
    let request_data = false

    if (index > -1) {
        // 检查该元素的 active 属性是否为 true
        if (all_chat_data[active_date]?.[index].active && all_chat_data[active_date].length > 1) {
            if (index === 0) {
                // 由于将要删除第一个元素，因此将第二个元素的 active 属性设置为 true
                let conversation_1 = all_chat_data[active_date][1]
                conversation_1.active = true
                next_active_topic = conversation_1.topic_id
                if (conversation_1.data.length === 0) {
                    request_data = true
                } else {
                    active_conversation_messages = conversation_1.data
                    display_suggestion = conversation_1.data?.[conversation_1.data.length - 1]?.suggestion
                }
            } else {
                // 将数组中第一个元素的 active 属性设置为 true
                let conversation_0 = all_chat_data[active_date][0]
                conversation_0.active = true;
                next_active_topic = conversation_0.topic_id
                if (conversation_0.data.length === 0) {
                    request_data = true
                } else {
                    active_conversation_messages = conversation_0.data
                    display_suggestion = conversation_0.data?.[conversation_0.data.length - 1]?.suggestion
                }
            }
        }

        // 删除数组中的该元素
        all_chat_data[active_date].splice(index, 1);
    }

    dispatch(allChatDataAction(all_chat_data))
    dispatch(activeTopicIdAction(active_date, next_active_topic))

    if (request_data) {
        // 激活刚点击的 conversation
        for (let item of all_chat_data[active_date]) {
            if (item.topic_id === next_active_topic) {
                console.log("before await");
                let d = await dispatch(getMessagesForConversation(item.conversation_id))
                console.log("await d = ", d);
                if (d) {
                    active_conversation_messages = d
                    display_suggestion = d?.[d.length - 1]?.suggestion
                }
                item.data = d ? d : [];
                break;
            }
        }
        dispatch(allChatDataAction(all_chat_data))
    }

    dispatch(getSingleConversationMessageImage(active_date, next_active_topic, active_conversation_messages))
    dispatch(renderSingleConversationMessageSearchResult(active_date, next_active_topic, active_conversation_messages))

    if (display_suggestion) {
        dispatch(suggestionListAction(display_suggestion))
    } else {
        dispatch(suggestionListAction([]))
    }
}

export const deleteConversationAction = (active_date, active_topicid, conversation_id) => {
    return (dispatch, getState) => {
        if (conversation_id === "") {
            deleteConversation(dispatch, getState, active_date, active_topicid)
        } else {
            fetchWithCookieRefresh('/api/history/conversation', {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    conversation_id: conversation_id,
                })
            })
            .then(data => {
                deleteConversation(dispatch, getState, active_date, active_topicid)
            })
            .catch(error => {
                handleResponseError(message, error)
            })
        }
    }
}

export const isFeedbackModalOpenAction = (status) => ({
    type: actionTypes.IS_FEEDBACK_MODAL_OPEN_ACTION,
    status: status,
})

export const feedbackScoreAction = (value) => ({
    type: actionTypes.FEEDBACK_SCORE_ACTION,
    value: value,
})

export const feedbackContentAction = (value) => ({
    type: actionTypes.FEEDBACK_CONTENT_ACTION,
    value: value,
})

export const languageAction = (value) => ({
    type: actionTypes.LANGUAGE_ACTION,
    value: value,
})

export const themeAction = (value) => ({
    type: actionTypes.THEME_ACTION,
    value: value,
})


export const avatarUploadFilelistAction = (data) => ({
    type: actionTypes.AVATAR_UPLOAD_FILELIST_ACTION,
    data: data,
})

export const zoomButnTextAction = (value) => {
    return (dispatch, getState) => {
        setTimeout(() => {
            dispatch({
                type: actionTypes.ZOOM_BUTN_TEXT_ACTION,
                value: value
            })
        }, 300)
    }
}

export const isShowIntroVideoAction = (status) => ({
    type: actionTypes.IS_SHOW_INTRO_VIDEO_ACTION,
    status: status,
})

export const isShowGuideAction = (status) => ({
    type: actionTypes.IS_SHOW_GUIDE_ACTION,
    status: status,
})

export const isShowImageMoverAction = (status) => ({
    type: actionTypes.IS_SHOW_IMAGE_MOVER_ACTION,
    status: status
})

export const isShowBlockUsageAction = (status) => ({
    type: actionTypes.IS_SHOW_BLOCK_USAGE_ACTION,
    status: status
})

export const isShowUserResearchAction = (status) => ({
    type: actionTypes.IS_SHOW_USER_RESEARCH_ACTION,
    status: status,
})

export const userResearchDataAction = (data) => ({
    type: actionTypes.USER_RESEARCH_DATA_ACTION,
    data: data,
})

export const isFeedbackDoneModalOpenAction = (status) => ({
    type: actionTypes.IS_FEEDBACK_DONE_MODAL_OPEN_ACTION,
    status: status,
})

export const postFeedbackAction = (feedback_score, feedback_content) => {
    return (dispatch, getState) => {
        fetchWithCookieRefresh('/api/feedback/create', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                feedback_score: feedback_score,
                feedback_content: feedback_content,
            }),
        })
        .then(data => {
            dispatch(isFeedbackModalOpenAction(false))
            dispatch(isFeedbackDoneModalOpenAction(true))
            dispatch(feedbackScoreAction(0))
            dispatch(feedbackContentAction(""))
        })
        .catch(error => {
            handleResponseError(message, error)
        })
    }
}

export const menuSearchContentAction = (value) => ({
    type: actionTypes.MENU_SEARCH_CONTENT_ACTION,
    value: value,
})

export const messageImageVisibleAction = (status) => ({
    type: actionTypes.MESSAGE_IMAGE_VISIABLE_ACTION,
    status: status,
})

export const messageItemImagePathAction = (value) => ({
    type: actionTypes.MESSAGE_ITEM_IMAGE_PATH_ACTION,
    value: value,
})


export const postMessageScoreAction = (message_id, message_score, active_date, active_topicid) => {
    return (dispatch, getState) => {
        fetchWithCookieRefresh('/api/history/score_message', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                message_id: message_id,
                feedback_score: message_score,
            }),
        })
        .then(data => {
            message.info("我们已经接收到您的反馈，感谢。")
            let all_chat_data = getState().get("home").get("all_chat_data").toJS()

            all_chat_data[active_date]?.forEach((item) => {
                if (item.topic_id === active_topicid) {
                    item.data.forEach((message_item) => {
                        if (message_item.message_id === message_id) {
                            message_item.message_score = message_score
                        }
                    })
                }
            })

            dispatch(allChatDataAction(all_chat_data))
        })
        .catch(error => {
            handleResponseError(message, error)
        })
    }
}

export const postMessageRegenerateAction = (message_id, active_index, active_date, active_topicid) => {
    return (dispatch, getState) => {
        let all_chat_data = getState().get("home").get("all_chat_data").toJS()

        let value = ''
        let temp_message_id = ''
        all_chat_data[active_date]?.forEach((item) => {
            if (item.topic_id === active_topicid) {
                item.data.forEach((message_item, i) => {
                    if (i === active_index - 1) {
                        value = message_item.text
                    } else if (i === active_index) {
                        message_item.text = ''
                        message_item.status = 'loading'
                        message_item.text_extra_img = ''
                        message_item.text_extra_search = ''
                        message_item.image_ids = []
                        message_item.image_urls = []
                        message_item.suggestion = []
                        message_item.search_result = []
                        temp_message_id = message_item.message_id
                    }
                })
            }
        })

        dispatch(isThinkingAction(true))
        dispatch(allChatDataAction(all_chat_data))
        if (message_id === '') {
            message_id = temp_message_id
        }

        // 发送新的问答请求
        dispatch(postChatCompletionsAction({value: value, message_id: message_id, active_index: active_index}))
    }
}

export const expertRelatedAction = () => {
    return (dispatch, getState) => {
        // 终止旧的响应，切换到新的对话
        dispatch(cancelFetchAction())
        dispatch(clearChatHistoryActive())
    }
}

const clearChatHistoryActive = () => {
    return (dispatch, getState) => {
        let all_chat_data = getState().get("home").get("all_chat_data").toJS()
        let active_date = getState().get("home").get("active_date")
        let active_topicid = getState().get("home").get("active_topicid")

        all_chat_data[active_date]?.forEach((item, index) => {
            if (item.topic_id === active_topicid) {
                item.active = false
            }
        })

        dispatch(activeTopicIdAction('', ''))
        dispatch(allChatDataAction(all_chat_data))
        // 清楚悬浮的 suggestion
        dispatch(suggestionListAction([]))
        dispatch(setPromptAction(''))
    }
}

export const suggestionListAction = (data) => ({
    type: actionTypes.SUGGESTION_LIST_ACTION,
    data: data,
})

export const allowProgramScrollAction = (status) => ({
    type: actionTypes.ALLOW_PROGRAM_SCROLL_ACTION,
    status: status,
})

export const postServerForCreateNewConversation = (prompt='') => {
    // 每次请求之前创建一个新的 AbortController 实例
    if (currentController) {
        currentController.abort();
    }
    currentController = createController();

    return (dispatch, getState) => {
        let user_data = getState().get("login").get("user_data").toJS()

        return fetchWithCookieRefresh('/api/history/conversation', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                uuid: user_data.uuid,
                conversation_name: prompt,
                chat_type: "link_stone",
            }),
            signal: currentController.signal,
        })
        .then(data => {
            let temp_data = extractConversationIdAndId(data.data)

            // 创建临时的 conversation
            let temp_conversation = createNewConversation()
            // 创建新的 user message 和 ai message
            let user_message = createNewMessage({role: 'user', text: prompt, status: 'pass'})
            let ai_message = createNewMessage({role: 'ai', topic_message_id: user_message.topic_message_id, text: '', status: 'loading'})
            user_message.topic_message_id = `${user_message.topic_message_id}query`
            temp_conversation.data.push(user_message)
            temp_conversation.data.push(ai_message)
            // 更改 conversation 的信息
            temp_conversation.id = temp_data['id']
            temp_conversation.conversation_id = temp_data['conversation_id']
            temp_conversation.created_at = temp_data['created_at']
            temp_conversation.conversation_name = prompt
            temp_conversation.topic_id = temp_data['conversation_id']

            // 往 today 中添加对话
            let all_chat_data = getState().get("home").get("all_chat_data").toJS()
            all_chat_data['today'].unshift(temp_conversation)
            dispatch(allChatDataAction(all_chat_data))
            dispatch(activeTopicIdAction('today', temp_conversation.topic_id))

            // 清空临时的 conversation
            dispatch(setPromptAction(''))
            dispatch(postChatCompletionsAction({value: prompt, message_id: ai_message.message_id, active_index: 1}))
        })
        .catch(error => {
            handleResponseError(message, error)
            dispatch(isThinkingAction(false))
        })
    }
}

export const nicknameAction = (value) => ({
    type: actionTypes.NICKNAME_ACTION,
    value: value,
})

export const messageImageScoreLikeAction = (element, id, url, score, message_id, index_msg) => {
    return (dispatch, getState) => {
        let active_date = getState().get("home").get("active_date")
        let active_topicid = getState().get("home").get("active_topicid")

        let body_data = {
            message_id: message_id,
            url: url,
            score: score,
        }

        if (id !== '') {
            body_data['id'] = id
        }

        return fetchWithCookieRefresh('/api/history/score_extra_info ', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body_data),
        })
        .then(data => {
            let temp_data = data.data
            let data_id = temp_data.id
            let data_score = temp_data.score

            // 检查是否存在特定的 class
            if (score > 0) {
                if (!element.classList.contains('msg_images_id_like_active')) {
                    element.classList.add('msg_images_id_like_active')
                }
            } else {
                element.classList.remove('msg_images_id_like_active')
            }

            // 给 data-id 属性赋值
            element.setAttribute('data-id', data_id);
            // 给 data-score 属性赋值
            element.setAttribute('data-score', data_score);

            let all_chat_data = getState().get("home").get("all_chat_data").toJS()

            // 将原先激活的 conversation 置灰
            all_chat_data[active_date]?.forEach((item, index) => {
                if (item.topic_id === active_topicid) {
                    if (item.data.length >= 1 && index_msg >= 0) {
                        // TODO
                        // dispatch(getMessageImage(item.image_ids, active_date, active_topicid, index, item.message_id))
                        // 旧的 img 的信息如何处理
                        item.data[index_msg].image_ids.forEach((img_item, img_index) => {
                            if (img_item.url === url) {
                                img_item['extrainfo_id'] = data_id
                                img_item['extrainfo_score'] = data_score
                            }
                        })
                    }
                }
            })
            dispatch(allChatDataAction(all_chat_data))
        })
        .catch(error => {
            handleResponseError(message, error)
        })
    }
}

export const resetState = () => {
    return {
        type: actionTypes.RESET_STATE,
    }
}

export const thinkingMessageIdAction = (value) => {
    return {
        type: actionTypes.THINKING_MESSAGE_ID_ACTION,
        value: value,
    }
}

export const conversationRenameAction = (active_date, active_topicid) => {
    return (dispatch, getState) => {
        let all_chat_data = getState().get("home").get("all_chat_data").toJS()
        let obj = all_chat_data[active_date]?.find(item => item.topic_id === active_topicid)
        if (!obj.is_edit_name) {
            obj.is_edit_name = true
            obj.conversation_name_edit = obj.conversation_name
            dispatch(allChatDataAction(all_chat_data))
        }
    }
}

export const conversationNameBlurAction = (active_date, active_topicid) => {
    return (dispatch, getState) => {
        let all_chat_data = getState().get("home").get("all_chat_data").toJS()
        let obj = all_chat_data[active_date]?.find(item => item.topic_id === active_topicid)
        if (obj.conversation_name_edit === obj.conversation_name) {
            // 前后名字一样，没有改变
            obj.is_edit_name = false
            obj.conversation_name_edit = ''
            dispatch(allChatDataAction(all_chat_data))
        } else {
            fetchWithCookieRefresh('/api/history/conversation', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "conversation_id": obj.conversation_id,
                    "conversation_name": obj.conversation_name_edit
                }),
            })
            .then(data => {
                console.log("/api/history/conversation success");
                obj.is_edit_name = false
                obj.conversation_name = obj.conversation_name_edit
                obj.conversation_name_edit = ''
                dispatch(allChatDataAction(all_chat_data))
            })
            .catch(error => {
                handleResponseError(message, error)
                obj.is_edit_name = false
                obj.conversation_name_edit = ''
                dispatch(allChatDataAction(all_chat_data))
            })
        }
    }
}

export const conversationNameChangeAction = (value, active_date, active_topicid) => {
    return (dispatch, getState) => {
        let all_chat_data = getState().get("home").get("all_chat_data").toJS()
        let obj = all_chat_data[active_date]?.find(item => item.topic_id === active_topicid)
        if (obj.is_edit_name) {
            obj.conversation_name_edit = value
            dispatch(allChatDataAction(all_chat_data))
        }
    }
}

export const postUserResearchData = () => {
    return (dispatch, getState) => {
        dispatch(isShowUserResearchAction(false))

        let user_data = getState().get("login").get("user_data").toJS()
        if (user_data.is_first_login) {
            dispatch(isShowIntroVideoAction(true))
        }

        let user_research_data = getState().get("home").get("user_research_data").toJS()

        fetchWithCookieRefresh('/api/account/set_user_detail', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "gender": user_research_data.gender,
                "age": user_research_data.age,
                "occupation": user_research_data.occupation,
                "city": user_research_data.city,
            }),
        })
        .then(data => {
            console.log("用户调研保存成功");
        })
        .catch(error => {
            handleResponseError(message, error)
        })
    }
}

export const isUpdateInfoModalOpenAction = (status) => {
    return {
        type: actionTypes.IS_UPDATE_INFO_MODAL_OPEN_ACTION,
        status: status,
    }
}

export const knowledgeBaseListAction = (data) => ({
    type: actionTypes.KNOWLEDGE_BASE_LIST_ACTION,
    data: data,
})

export const getReleaseContent = () => {
    return (dispatch, getState) => {
        fetchWithCookieRefresh('/api/release_record/get_release_content', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(data => {
            console.log("/api/release_record/get_release_content data = ", data);
            let temp_data = data.data
            if (temp_data.has_update) {
                dispatch(setUpdateInfoAction(temp_data))

                let is_show_user_research = getState().get("home").get("is_show_user_research")
                let is_show_intro_video = getState().get("home").get("is_show_intro_video")
                let is_show_image_mover = getState().get("home").get("is_show_image_mover")
                let is_show_guide = getState().get("home").get("is_show_guide")
                if (!is_show_user_research && !is_show_intro_video &&
                    !is_show_image_mover && !is_show_guide) {
                    // is_show_user_research
                    // is_show_intro_video
                    // is_show_image_mover
                    // is_show_guide
                    dispatch(isUpdateInfoModalOpenAction(true))
                }
            }
        })
        .catch(error => {
            handleResponseError(message, error)
        })
    }
}

export const fetchUserHasReadUpdate = () => {
    return (dispatch, getState) => {
        fetchWithCookieRefresh('/api/release_record/user_read', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(data => {
            console.log("/api/release_record/user_read data = ", data);
        })
        .catch(error => {})
    }
}

export const setUpdateInfoAction = (data) => ({
    type: actionTypes.SET_UPDATE_INFO_ACTION,
    data: data,
})

export const stopFetchResponding = () => {
    return (dispatch, getState) => {
        // 终止旧的响应，切换到新的对话
        dispatch(cancelFetchAction())

        let all_chat_data = getState().get("home").get("all_chat_data").toJS()
        let active_date = getState().get("home").get("active_date")
        let active_topicid = getState().get("home").get("active_topicid")

        all_chat_data[active_date]?.forEach((item) => {
            if (item.topic_id === active_topicid) {
                if (item.data.length >= 1) {
                    if (item.data[item.data.length - 1].text === '请重新提问'
                        && item.data[item.data.length - 1].status
                    ) {
                        item.data[item.data.length - 1].text = "已停止回答，请重新提问！"
                    }

                    item.data[item.data.length - 1].status = 'pass'
                }
            }
        })
        console.log("========== handleStopResponding handleAllChatDataAction ==========");
        dispatch(allChatDataAction(all_chat_data))
    }
}

export const sendLogToServer = (log_description, log_info, meta_data) => {
    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/log/create_log', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                // log_description: str = Field(description="日志类型")
                // log_info: str = Field(description="日志信息")
                // meta_data: dict = Field(default=None, description="额外的详细信息")
                log_description: log_description,
                log_info: log_info,
                meta_data: meta_data,
            }),
        })
        .then(data => {
            console.log("/api/log/create_log data = ", data);
        })
        .catch(error => {})
    }
}

export const setIsModalExperienceOpen = (status) => ({
    type: actionTypes.SET_IS_MODAL_EXPERIENCE_OPEN,
    status: status,
})

export const setIsExperienceConfettiOpen = (status) => ({
    type: actionTypes.SET_IS_EXPERIENCE_CONFETTI_OPEN,
    status: status,
})

export const setCurrentExperience = (value) => ({
    type: actionTypes.SET_CURRENT_EXPERIENCE,
    value: value,
})

export const setLevelThresholds = (data) => ({
    type: actionTypes.SET_LEVEL_THRESHOLDS,
    data: data,
})

export const getLevelInfo = () => {
    return (dispatch, getState) => {
        fetchWithCookieRefresh('/api/level/level_info', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(data => {
            console.log("/api/level/level_info data = ", data);
            // "level_info"： [{"level": "Lv1", "level_start": 0, "level_end": 50}, ]
            // 提取 level_end 的值并放入数组中
            if (data.data?.level_info) {
                let endValues = data.data.level_info.map(info => info.level_end);
                dispatch(setLevelThresholds(endValues))
            }
        })
        .catch(error => {})
    }
}

export const getLevelChatCount = () => {
    return (dispatch, getState) => {
        fetchWithCookieRefresh('/api/level/chat_count', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(data => {
            console.log("/api/level/get_chat_count data = ", data);
            let query_count = data.data.query_count
            let re_query_count = data.data.re_query_count
            dispatch(setCurrentExperience(query_count + re_query_count))
        })
        .catch(error => {})
    }
}

export const isShowScreenPersonalOverview = (status) => ({
    type: actionTypes.IS_SHOW_SCREEN_PERSONAL_OVERVIEW,
    status: status,
})

export const isShowScreenStoneModelMall = (status) => ({
    type: actionTypes.IS_SHOW_SCREEN_STONE_MODEL_MALL,
    status: status,
})

export const isShowScreenPersonalBackpack = (status) => ({
    type: actionTypes.IS_SHOW_SCREEN_PERSONAL_BACKPACK,
    status: status,
})

export const setMallComponentsList = (data) => ({
    type: actionTypes.SET_MALL_COMPONENTS_LIST,
    data: data,
})


export const setSelectMallComponentName = (value) => ({
    type: actionTypes.SELECT_MALL_COMPONENT_NAME,
    value: value,
})

export const setMallComponentsCategorys = (data) => ({
    type: actionTypes.SET_MALL_COMPONENTS_CATEGORYS,
    data: data,
})

export const getStoreComponentsList = () => {
    return (dispatch, getState) => {
        // fetchWithCookieRefresh('/api/store_components_list.json', {
        fetchWithCookieRefresh('/api/store/components/list', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(data => {
            console.log("/api/store/components/list data = ", data);
            let d = data?.data.map((item, index) => {
                item['has_add'] = 0
                if (index === 0) {
                    item['has_select'] = true
                } else {
                    item['has_select'] = false
                }
                //     is_limit_time_free: true,
                return item
            })
            dispatch(setMallComponentsList(d))

            // 使用 Array.map() 提取所有的 category 值
            let categories = d.map(item => item.category)
            // 使用 Set 去除重复的 category 值，然后将结果转换回数组
            let uniqueCategories = Array.from(new Set(categories))
            uniqueCategories.unshift('热门专区');
            let l = uniqueCategories.map((item, index) => {
                if (index === 0) {
                    return {
                        label: item,
                        active: true,
                    }
                } else {
                    return {
                        label: item,
                        active: false,
                    }
                }
            })
            dispatch(setMallComponentsCategorys(l))

            // 获取当前用户商城中已经添加的组件列表
            dispatch(getStoreComponentsListUserForge())
        })
        .catch(error => {})
    }
}

export const getStoreComponentsListUserForge = () => {
    return (dispatch, getState) => {
        fetchWithCookieRefresh('/api/store/components/list/get_user_forge', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(data => {
            console.log("/api/store/components/list/get_user_forge data = ", data);
            let mall_components_list = getState().get("home").get("mall_components_list").toJS()

            // 找出 mall_components_list 中当前最大的 has_add 值
            let maxHasAdd = Math.max(...mall_components_list.map(item => item.has_add));

            // 遍历 data.data 并更新 mall_components_list 中的对应元素
            data.data.forEach(addItem => {
                let componentItem = mall_components_list.find(item => item.name === addItem.name);
                if (componentItem) {
                    maxHasAdd += 1;
                    componentItem.has_add = maxHasAdd;
                }
            })

            dispatch(setMallComponentsList(mall_components_list))
        })
        .catch(error => {})
    }
}

export const postStoreComponentsListUserForge = (data) => {
    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/store/components/list/post_user_forge', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then(data => {
            console.log("/api/store/components/list/post_user_forge data = ", data);
            return data
        })
        .catch(error => {})
    }
}

export const deleteStoreComponentsListUserForge = (data) => {
    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/store/components/list/delete_user_forge', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then(data => {
            console.log("/api/store/components/list/delete_user_forge data = ", data);
            return data
        })
        .catch(error => {})
    }
}

export const setAssistantPrompt = (value) => ({
    type: actionTypes.SET_ASSISTANT_PROMPT,
    value: value,
})

export const setIsAssistantAsk = (status) => ({
    type: actionTypes.SET_IS_ASSISTANT_ASK,
    status: status,
})

export const setAssistantChatMessageDataAction = (data) => ({
    type: actionTypes.SET_ASSISTANT_CHAT_MESSAGE_DATA,
    data: data,
})

export const sendAssistantAskAction = (prompt, signal) => {
    return (dispatch, getState) => {
        // 允许在助手回答时滚动
        dispatch(allowAssistantContentScrollAction(true))
        dispatch(setIsAssistantAsk(true))

        // 创建新的 用户和ai的 message
        let assistant_chat_message_data = getState().get("home").get("assistant_chat_message_data").toJS()
        let user_query = prompt.trim()

        let user_message = createNewMessage({role: 'user', text: user_query, status: 'pass'})
        let ai_message = createNewMessage({role: 'ai', topic_message_id: user_message.topic_message_id, text: '', status: 'loading'})
        user_message.topic_message_id = `${user_message.topic_message_id}query`

        assistant_chat_message_data.push(user_message)
        assistant_chat_message_data.push(ai_message)

        // 提交 message
        dispatch(setAssistantChatMessageDataAction(assistant_chat_message_data))
        dispatch(setAssistantPrompt(''))
        dispatch(postAssistantChatCompletionsAction({value: user_query, message_id: ai_message.message_id, signal: signal,}))
    }
}

const postAssistantChatCompletionsAction = ({value='', message_id='', signal=''}={}) => {
    return async (dispatch, getState) => {
        let user_data = getState().get("login").get("user_data").toJS()

        let body = {
            query: value,
            conversation_id: user_data.uuid,
            message_id: message_id,
            knowledge_base_name: '',
            is_assistant: true,
        }

        let url = '/api/llm/chat'
        let messageId = ''

        fetchWithCookieRefresh(url, {
            method: 'POST',
            headers: {
                'Accept': 'text/event-stream',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
            signal: signal,
        }, { processResponse: false })
        .then(response => {
            if (!response.ok) {
                throw new Error('HTTP error: ' + response.status);
            }

            // 获取响应头中的 message_id
            messageId = response.headers.get('X-Message-ID');
            console.log("获取响应头中的 messageId = ", messageId);
            let reader = response.body.getReader();
            let allContent = ''
            let isCodeBlock = false
            let stream_data_truncation = ''
            let all_error_counts = 0

            // 逐步接收流式数据
            function readStream() {
                reader.read().then(({ done, value }) => {
                    // 判断流式数据的接收是否结束
                    if (done) {
                        console.log('Stream finished');
                        // 更改最后一个 ai 回答的状态
                        let assistant_chat_message_data = getState().get("home").get("assistant_chat_message_data").toJS()
                        let last_index = assistant_chat_message_data.length - 1
                        assistant_chat_message_data[last_index].status = 'pass'
                        assistant_chat_message_data[last_index].message_id = messageId
                        assistant_chat_message_data[last_index].topic_message_id = messageId
                        if (allContent !== '') {
                            assistant_chat_message_data[last_index].text = allContent
                        } else {
                            assistant_chat_message_data[last_index].text = '请求超时，请重新提问'
                        }
                        dispatch(setAssistantChatMessageDataAction(assistant_chat_message_data))
                        dispatch(setIsAssistantAsk(false))

                        return;
                    }

                    // 将 Uint8Array 转换为字符串
                    let json_data = new TextDecoder('utf-8').decode(value)
                    let temp_json_data = json_data
                    console.log("=============== origin json_data = ", json_data);

                    let pattern = /: ping - (\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}\.\d{6})(\r\n)*/g;
                    json_data = json_data.replace(pattern, "");
                    // 处理流数据可能会被截断的情况
                    let [stream_error, correct_str_value] = handleStreamDataTruncation(stream_data_truncation, json_data)
                    if (stream_error) {
                        all_error_counts += 1
                        if (all_error_counts >= 3) {
                            dispatch(sendLogToServer("问答中-文本解析错误", '', {'origin_data': temp_json_data, 'before_handle_stream_error': stream_data_truncation, 'before_handle_json_data': json_data, 'after_handle_stream_error': stream_error, 'after_handle_stream_data': correct_str_value}))
                        }
                    }
                    stream_data_truncation = stream_error
                    console.log("避免数据流断层的处理函数 stream_data_truncation = ", stream_data_truncation);
                    console.log("避免数据流断层的处理函数 correct_str_value = ", correct_str_value);

                    let object_data = handleReponseStreamChatData({text: correct_str_value})
                    console.log("转换为浏览器可用的数据 object_data = ", object_data);

                    let text = object_data.result_text
                    processTextChunk(text)
                })
            }

            function processTextChunk(chunk, index = 0) {
                if (index < chunk.length) {
                    let content = chunk[index];
                    let nextIndex = index + 1; // 默认情况下，每次递增索引

                    // 如果当前处于代码块内部，并且遇到了结束的 ```
                    if (isCodeBlock && chunk.substring(index).startsWith('```')) {
                        content = '```'; // 将 ``` 添加到内容中
                        nextIndex = index + 3; // 移动索引跳过 ```

                        // 先添加到 allContent 然后再更改状态
                        allContent += content;
                        updateDisplay(allContent); // 更新显示内容

                        isCodeBlock = false; // 更新状态为不在代码块内

                        // 继续处理后续文本
                        timerId = setTimeout(() => {
                            processTextChunk(chunk, nextIndex);
                        }, 20);
                        return; // 提前返回防止执行后续代码
                    }

                    // 检查是否进入代码块
                    if (!isCodeBlock && chunk.substring(index).startsWith('```')) {
                        isCodeBlock = true; // 切换代码块状态
                        content = '```'; // 将 ``` 添加到内容中
                        nextIndex = index + 3; // 移动索引跳过 ```
                    } else if (!isCodeBlock && content === '[') {
                        // 特殊处理链接格式但只在非代码块的情况下
                        let ex = extractFirstLink(chunk, index);
                        if (ex !== null) {
                            content = ex.link;
                            nextIndex = ex.index + ex.link.length; // 移动索引到链接之后
                        }
                    } else if (!isCodeBlock && content === '<') {
                        let ex = extractFirstImageContainer(chunk, index)
                        if (ex !== null) {
                            content = ex.link;
                            nextIndex = ex.index + ex.link.length; // 移动索引到链接之后
                        }
                    }

                    allContent += content; // 将当前内容添加到 allContent
                    updateDisplay(allContent); // 更新显示内容

                    timerId = setTimeout(() => {
                        processTextChunk(chunk, nextIndex);
                    }, 20); // 延迟50ms显示下一个字符
                } else {
                    readStream(); // 当前块处理完毕，读取下一块数据
                }
            }

            // 分离出更新显示内容的函数以便复用
            function updateDisplay(content) {
                const textToDisplay = isCodeBlock ?
                    content + '⬤' :
                    content + '<span style="margin-left: 10px;color: #74bdc6;">⬤</span>';

                dispatch(replaceAssistantChatDataAction({
                    role: 'ai',
                    text: textToDisplay,
                    status: 'loading',
                    message_id: messageId,
                }));
            }

            // 开始接收流式数据
            readStream();
        })
        .catch(error => {
            clearTimeout(timerId)
            dispatch(sendLogToServer("问答函数的统一错误处理", error.message, {'name': error.name}))

            let assistant_chat_message_data = getState().get("home").get("assistant_chat_message_data").toJS()
            let last_index = assistant_chat_message_data.length - 1

            assistant_chat_message_data[last_index].status = 'pass'
            if (messageId !== '') {
                assistant_chat_message_data[last_index].message_id = messageId
                assistant_chat_message_data[last_index].topic_message_id = messageId
            }

            dispatch(setAssistantChatMessageDataAction(assistant_chat_message_data))
            dispatch(setIsAssistantAsk(false))
        })
    }
}

export const replaceAssistantChatDataAction = ({role='user', text='', status='pass', message_id=''}={}) => {
    return (dispatch, getState) => {
        let messages = getState().get("home").get("assistant_chat_message_data").toJS()

        if (messages.length >= 1) {
            let last_index = messages.length - 1

            messages[last_index].role = role
            messages[last_index].text = text
            messages[last_index].status = status
            if (message_id !== '') {
                messages[last_index].message_id = message_id
                messages[last_index].topic_message_id = message_id
            }

            dispatch(setAssistantChatMessageDataAction(messages))
        } else {
            console.log("没有可替换的数据，之前的问答已结束");
        }
    }
}

export const allowAssistantContentScrollAction = (status) => ({
    type: actionTypes.ALLOW_ASSISTANT_CONTENT_SCROLL,
    status: status,
})

function removeSuffix(str) {
    const suffix1 = "⬤";
    const suffix2 = "<span style=\"margin-left: 10px;color: #74bdc6;\">⬤</span>";

    if (str.endsWith(suffix1)) {
        return str.slice(0, -suffix1.length);
    } else if (str.endsWith(suffix2)) {
        return str.slice(0, -suffix2.length);
    }
    return str;
}

export const cancelAssistantFetchRequest = () => {
    return (dispatch, getState) => {
        let assistant_chat_message_data = getState().get("home").get("assistant_chat_message_data").toJS()
        let data_len = assistant_chat_message_data.length
        let thinking_message_id = ''
        let response_text = ''
        if (data_len > 0) {
            thinking_message_id = assistant_chat_message_data[data_len - 1].topic_message_id
            let temp_text = assistant_chat_message_data[data_len - 1].text
            response_text = removeSuffix(temp_text)
        }

        let user_data = getState().get("login").get("user_data").toJS()
        let user_uuid = user_data.uuid

        // 数据状态的更新
        if (data_len >= 1) {
            if (assistant_chat_message_data[data_len - 1].text === '请重新提问'
                && assistant_chat_message_data[data_len - 1].status
            ) {
                assistant_chat_message_data[data_len - 1].text = "已停止回答，请重新提问！"
            }

            assistant_chat_message_data[data_len - 1].status = 'pass'
        }
        dispatch(setAssistantChatMessageDataAction(assistant_chat_message_data))
        dispatch(setIsAssistantAsk(false))

        fetchWithCookieRefresh('/api/llm/chat/cancel', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                conversation_id: user_uuid,
                message_id: thinking_message_id,
                response: response_text,
            }),
        }).then(data => {
            console.log("/api/llm/chat/cancel data = ", data);
        }).catch(error => {})
    }
}

export const getAssistantMessageForConversation = () => {
    return (dispatch, getState) => {
        let user_data = getState().get("login").get("user_data").toJS()
        let user_uuid = user_data.uuid

        fetchWithCookieRefresh(`/api/history/get_messages?conversation_id=${user_uuid}`, {
            method: 'GET',
        })
        .then(data => {
            // 从后端获取的记录转换成前端需要的类型
            let temp_data = data.data
            console.log("/api/history/get_messages data = ", temp_data);
            let d = formatResponseMessagesDataToLocal({data: temp_data})
            console.log("/api/history/get_messages data = ", d);
            dispatch(setAssistantChatMessageDataAction(d))
        })
        .catch(error => {})
    }
}

export const getBannerImagesList = () => {
    return (dispatch, getState) => {
        return fetchWithCookieRefresh(`/api/banner/images/list`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(data => {
            console.log("/api/banner/images/list data = ", data);
            return data
        })
        .catch(error => { handleResponseError(message, error) })
    }
}
