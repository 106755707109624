import { Carousel } from 'antd'
import styles from './index.module.css'

import adImg from '../../statics/mall_banner_ad.png'


const MyBannerCarousel = ({data}) => {
    return (
        <div className={styles.carousel_container}>
            <Carousel
                autoplay={true}
                autoplaySpeed={8000}
                dotPosition="right"
                dots
            >
                {
                    data.length === 0 ? (
                        <div className={styles.carousel_item}>
                            <img src={adImg} className={styles.carousel_item_bg_img} />
                        </div>
                    ) : (
                        data.map((item, index) => {
                            return (
                                <div className={styles.carousel_item} key={`item_${index}`}>
                                    <img src={item} className={styles.carousel_item_bg_img} />
                                </div>
                            )
                        })
                    )
                }
            </Carousel>
        </div>
    )
}

export default MyBannerCarousel