import React, { Component } from 'react';
import { connect } from 'react-redux'

import styles from './index.module.css'
import { actionCreators } from '../../store'

import myStoneMallImg from '../../../../statics/side_my_stone_mall.png'


class SideMyStoneMall extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isCollapsed: false, // 默认不收缩
            maxHeight: null,
        }
        // this.contentRef = React.createRef();
    }

    // toggleCollapse() {
    //     const contentHeight = this.contentRef.current.scrollHeight; // 获取内容的实际高度
    //     this.setState(prevState => ({
    //         isCollapsed: !prevState.isCollapsed,
    //         maxHeight: !prevState.isCollapsed ? 0 : contentHeight + 'px', // 设置 maxHeight 为内容高度或 0
    //     }))
    // }

    // componentDidUpdate(prevProps, prevState) {
    //     if (!this.state.isCollapsed) {
    //         const contentHeight = this.contentRef.current.scrollHeight;
    //         if (prevState.maxHeight !== contentHeight + 'px') {
    //             this.setState({
    //                 maxHeight: contentHeight + 'px' // 更新 maxHeight 为当前内容的高度
    //             });
    //         }
    //     }
    // }

    handleItemKeep(e) {
        e.preventDefault()
        e.stopPropagation()
    }

    render() {
        return (
            <div className={styles.container}>
                <div className={styles.main}>
                    <div className={styles.title_item}>
                        <div
                            className={styles.expert_butn_box}
                            onClick={this.handleExpertDetail.bind(this)}
                        >
                            <img src={myStoneMallImg} className={styles.img} alt="icon" />
                            <div className={styles.text}>灵石商店</div>
                        </div>

                        {/* <div className={styles.shrink_box} onClick={this.toggleCollapse.bind(this)}>
                            {
                                this.state.isCollapsed ? (
                                    <img src={shrinkImg} className={styles.shrink_img} alt="icon" />
                                ) : (
                                    <img src={expandImg} className={styles.shrink_img} alt="icon" />
                                )
                            }
                        </div> */}
                    </div>

                    {/* <div
                        ref={this.contentRef}
                        className={`${styles.collapsibleContent} ${this.state.isCollapsed ? styles.item_collapsed : styles.item_expanded}`}
                        style={{maxHeight: this.state.maxHeight}}
                    >
                        <div style={{color: '#FFF', textAlign: 'center'}}>
                            灵石商店
                        </div>
                    </div> */}
                </div>
            </div>
        )
    }

    handleExpertDetail() {
        this.props.handleIsShowScreenPersonalOverview(false)
        this.props.handleIsShowScreenStoneModelMall(true)
        this.props.handleIsShowScreenPersonalBackpack(false)
        this.props.handleExpertRelatedAction()
    }

    handleItemMenu(e) {
        e.preventDefault()
        e.stopPropagation(); // 阻止事件冒泡
    }
}

const mapStateToProps = (state) => {
    return {

    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleIsShowScreenPersonalOverview(status) {
            dispatch(actionCreators.isShowScreenPersonalOverview(status))
        },
        handleIsShowScreenStoneModelMall(status) {
            dispatch(actionCreators.isShowScreenStoneModelMall(status))
        },
        handleIsShowScreenPersonalBackpack(status) {
            dispatch(actionCreators.isShowScreenPersonalBackpack(status))
        },
        handleExpertRelatedAction() {
            dispatch(actionCreators.expertRelatedAction())
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SideMyStoneMall)