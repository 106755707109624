import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import UserUsageCard from '../../components/UserUsageCard'
import UserTable from '../../components/UserTable'
import { actionCreators } from '../../store'
import styles from './index.module.css'


const UserStatistics = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(actionCreators.fetchUserStatisticsInfo())
        dispatch(actionCreators.getUserStatisticsTodayInfo())
        dispatch(actionCreators.getUserStatisticsWeekInfo())
        dispatch(actionCreators.fetchAvgDailyActiveInfo())
    }, [])

    return (
        <div>
            <div className={styles.header}>
                <div className={styles.title}>
                    用户统计信息
                </div>
            </div>

            <div className={styles.card_box}>
                <UserUsageCard />
            </div>

            <div className={styles.table_box}>
                <UserTable />
            </div>
        </div>
    )
}

export default UserStatistics;