import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux'
import { Table, Button, Modal, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { compareVersions, convertUTCToLocalTime, getCurrentFormattedTime, } from '../../../../utils'
import { actionCreators } from '../../store'

const onChange = (pagination, filters, sorter, extra) => {
  console.log('params', pagination, filters, sorter, extra);
};

const VersionTable = () => {
    const dispatch = useDispatch()
    let version_update_list = useSelector(state => state.get('admin').get('version_update_list')).toJS()

    const handleShowImage = (id) => {
        dispatch(actionCreators.showVersionUpdateImageId(id))
        dispatch(actionCreators.isShowVersionUpdateImage(true))
    }

    const handleDelete = (record_obj) => {
        console.log("record_obj = ", record_obj);
        let published_at = record_obj.published_at
        if (convertUTCToLocalTime(published_at) < getCurrentFormattedTime()) {
            message.info("当前已经过了发布时间，版本已发布")
            dispatch(actionCreators.fetchVersionUpdateList())
        } else {
            Modal.confirm({
                title: '删除版本更新',
                icon: <ExclamationCircleOutlined />,
                content: '请确认是否要删除该版本更新？',
                okText: '确认',
                cancelText: '取消',
                onOk() {
                    console.log('OK');
                    dispatch(actionCreators.deleteReleaseRecord(record_obj.id))
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        }
    }

    const columns = [
        {
            title: 'release的版本号',
            dataIndex: 'release_v',
            sorter: {
                compare: (a, b) => {
                    return compareVersions(a.release_v, b.release_v)
                },
            },
            key: 'release_v',
        },
        {
            title: '发布时间',
            render: (_, record) => (
                <span>{`${convertUTCToLocalTime(record.published_at)}`}</span>
            ),
            sorter: {
                compare: (a, b) => {
                    if (a.published_at > b.published_at) {
                        return 1
                    } else if (a.published_at < b.published_at) {
                        return -1
                    } else {
                        return 0
                    }
                },
            },
            key: 'published_at',
        },
        {
            title: '发布状态',
            render: (_, record) => (
                <a
                    onClick={() => {handleShowImage(record.id)}}
                >
                    查看【更新公告】的预览效果
                </a>
            ),
            key: 'id',
        },
        {
            title: '版本状态',
            render: (_, record) => {
                return (
                    convertUTCToLocalTime(record.published_at) < getCurrentFormattedTime() ? (
                        <span>已发布</span>
                    ) : (
                        <div>
                            <div style={{display: 'inline-block', marginRight: '20px'}}>还未发布</div>
                            <Button
                                type="primary"
                                danger
                                onClick={() => {handleDelete(record)}}
                            >
                                删除
                            </Button>
                        </div>
                    )
                )
            },
            key: 'status',
        },
    ];

    return (
        <Table
            columns={columns}
            dataSource={version_update_list}
            onChange={onChange}
        />
    )
}

export default VersionTable