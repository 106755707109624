import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styles from './index.module.css'
import personalImg from '../../../../statics/overview_personal_information.png'
import modelImg from '../../../../statics/overview_purchased_model.png'
import shoppingImg from '../../../../statics/overview_header_shopping.png'
import moreImg from '../../../../statics/chat_type_sel_more.png'
import dollarImg from '../../../../statics/overview_shopping_dollar_icon.png'
import bgImg from '../../../../statics/ai_link_stone.gif'
import experienceImg from '../../../../statics/grade_img_experience.png'
import gradDollarImg from '../../../../statics/grade_img_dollar.png'
import chatImg from '../../../../statics/grade_img_chat.png'
import { actionCreators } from '../../store'
import useUserLevel from '../../../../utils/useUserLevel'
import { experienceIcons } from '../../../../utils'
import MyButton from '../../../../common/MyButton'

const level_description = {
    'lv1': '原石',
    'lv2': '银石',
    'lv3': '金石',
    'lv4': '玉石',
    'lv5': '钻石',
    'lv6': '灵石',
}

export default function ScreenPersonalOverview() {
    const dispatch = useDispatch()
    let user_data = useSelector(state => state.get('login').get('user_data')).toJS()
    const mall_components_list = useSelector(state => state.get('home').get('mall_components_list'))
    const [added, setAdded] = useState([])
    const current_experience = useSelector(state => state.get('home').get('current_experience'))
    const { level, totalExperience } = useUserLevel(current_experience);

    useEffect(() => {
        let component_list = mall_components_list.toJS()
        let hasAddGreaterThanZero = component_list
            .filter(component => component.has_add > 0)
            .sort((a, b) => a.has_add - b.has_add);

        // 确保数组长度为 5，不足的部分用空对象填充
        const requiredLength = 5;
        if (hasAddGreaterThanZero.length < requiredLength) {
            hasAddGreaterThanZero = hasAddGreaterThanZero.concat(Array(requiredLength - hasAddGreaterThanZero.length).fill({}));
        } else (
            hasAddGreaterThanZero = hasAddGreaterThanZero.slice(0, requiredLength)
        )

        setAdded(hasAddGreaterThanZero)
    }, [mall_components_list])

    const addNewConversation = () => {
        dispatch(actionCreators.createNewConversationAction())
    }

    const handleShowMore = () => {
        dispatch(actionCreators.isShowScreenPersonalOverview(false))
        dispatch(actionCreators.isShowScreenStoneModelMall(true))
        dispatch(actionCreators.isShowScreenPersonalBackpack(false))
        dispatch(actionCreators.expertRelatedAction())
    }

    const handleAdjustComponents = () => {
        dispatch(actionCreators.isShowScreenPersonalOverview(false))
        dispatch(actionCreators.isShowScreenStoneModelMall(false))
        dispatch(actionCreators.isShowScreenPersonalBackpack(true))
        dispatch(actionCreators.expertRelatedAction())
    }

    return (
        <div className={styles.main}>
            <div className={styles.container}>
                <div className={styles.container_left}>
                    <div className={styles.personal_area}>
                        <div className={styles.area_header}>
                            <img src={personalImg} className={styles.header_img} alt="icon" />
                            <div className={styles.header_title}>个人概览</div>
                        </div>
                        <div className={styles.personal_box}>
                            <div className={styles.personal_info}>
                                <img src={bgImg} className={styles.personal_info_img} alt="icon" />

                                <div className={styles.personal_info_text_box}>
                                    <div className={styles.welcome_title}>
                                        Hi, {user_data.nickname ? user_data.nickname : ''}!
                                    </div>
                                    <div className={styles.welcome_title}>
                                        欢迎来到灵石
                                    </div>
                                    <div className={styles.welcome_info}>
                                        为您量身定制的AI室内设计伙伴！
                                    </div>
                                </div>
                            </div>
                            <div
                                className={styles.create_butn_box}
                                id="tour6"
                            >
                                <MyButton
                                    width={'100%'}
                                    height={'58px'}
                                    onClick={addNewConversation}
                                    text={<><span>创建新对话</span></>}
                                    type="primary"
                                />
                            </div>
                            <div className={`${styles.personal_grade_box} ${styles.first_personal_grade_box}`}>
                                <div className={styles.personal_grade_item}>
                                    <div className={styles.grade_img_box}>
                                        <img src={experienceImg} className={styles.grade_img} alt="icon" />
                                    </div>
                                    <div className={styles.grade_text_box}>
                                        <div className={styles.grade_text_title}>经验值</div>
                                        <div className={styles.grade_text_value}>{current_experience}/{totalExperience}XP</div>
                                    </div>
                                </div>
                                <div className={styles.personal_grade_item}>
                                    <div className={styles.grade_img_box}>
                                        <img src={experienceIcons[`lv${level}`]} className={styles.grade_img} alt="icon" />
                                    </div>
                                    <div className={styles.grade_text_box}>
                                        <div className={styles.grade_text_title}>等级</div>
                                        <div className={styles.grade_text_value}>{level_description[`lv${level}`]}</div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.personal_grade_box}>
                                <div className={styles.personal_grade_item}>
                                    <div className={styles.grade_img_box}>
                                        <img src={gradDollarImg} className={styles.grade_img} alt="icon" />
                                    </div>
                                    <div className={styles.grade_text_box}>
                                        <div className={styles.grade_text_title}>金币</div>
                                        <div className={styles.grade_text_value}>0</div>
                                    </div>
                                </div>
                                <div className={styles.personal_grade_item}>
                                    <div className={styles.grade_img_box}>
                                        <img src={chatImg} className={styles.grade_img} alt="icon" />
                                    </div>
                                    <div className={styles.grade_text_box}>
                                        <div className={styles.grade_text_title}>对话</div>
                                        <div className={styles.grade_text_value}>{current_experience}个</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.purchased_model_area}>
                        <div className={styles.area_header}>
                            <img src={modelImg} className={styles.header_img} alt="icon" />
                            <div className={styles.header_title}>我的背包</div>
                        </div>
                        <div className={styles.purchased_model_box}>
                            <div className={styles.model_box}>
                                {
                                    added.map((item, index) => {
                                        if (item && item.id) {
                                            return (
                                                <div className={styles.model} key={item.id}>
                                                    <img src={item.avatar} className={styles.model_img} alt="icon" />
                                                </div>
                                            )
                                        } else {
                                            return (
                                                <div className={`${styles.model} ${styles.model_unactive}`} key={`empty-${index}`}></div>
                                            )
                                        }
                                    })
                                }
                            </div>
                            <MyButton
                                width={'100%'}
                                height={'48px'}
                                onClick={handleAdjustComponents}
                                text="调整组件"
                                type="default"
                            />
                        </div>
                    </div>
                </div>

                <div className={styles.container_right}>
                    <div className={styles.shopping_area}>
                        <div className={styles.area_header}>
                            <img src={shoppingImg} className={styles.header_img} alt="icon" />
                            <div className={styles.header_title}>灵石商店</div>

                            <div className={styles.more_box}>
                                <div
                                    className={styles.more_text}
                                    onClick={handleShowMore}
                                >
                                    查看更多
                                </div>
                                <img src={moreImg} className={styles.more_img} alt="icon" />
                            </div>
                        </div>
                        <div className={styles.shopping_model_box}>
                            {
                                mall_components_list.toJS().map((item) => {
                                    return (
                                        <div className={styles.shopping_model_item} key={item.id}>
                                            <div className={styles.shopping_model_img_box}>
                                                <img src={item.avatar} className={styles.shopping_model_img} alt="icon" />
                                            </div>
                                            <div className={`${styles.shopping_model_text_box} ${item.permissions.includes('execute') ? styles.shopping_model_text_box_owned : item.shopping_model_text_box_unowned}`}>
                                                <div className={styles.shopping_model_text_title}>
                                                    {item.name}
                                                </div>
                                                <div className={styles.shopping_model_text_info}>
                                                    {item.overview}
                                                </div>
                                            </div>
                                            {
                                                item.permissions.includes('execute') ? (
                                                    <div className={`${styles.shopping_model_butn} ${styles.shopping_model_owned}`}>
                                                        已拥有
                                                    </div>
                                                ) : (
                                                    item.is_limit_time_free ? (
                                                        <div className={`${styles.shopping_model_butn} ${styles.shopping_model_purchase}`}>
                                                            <div>限时免费</div>
                                                        </div>
                                                    ) : (
                                                        <div className={`${styles.shopping_model_butn} ${styles.shopping_model_purchase}`}>
                                                            <div>需支付：</div>
                                                            <img src={dollarImg} className={styles.model_status_img} alt={'icon'} />
                                                            <div>500</div>
                                                        </div>
                                                    )
                                                )
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}