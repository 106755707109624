import { fromJS } from 'immutable'

import * as actionTypes from './actionTypes'

// 尝试从 localStorage 获取状态
const persistedState = localStorage.getItem('loginState')
  ? JSON.parse(localStorage.getItem('loginState'))
  : {};
console.log("persistedState = ", persistedState);

const defaultState = fromJS({
    email: '',
    password: '',
    is_login_loading: false,
    has_login: persistedState.has_login || false,
    user_data: persistedState.user_data || {
        // "id": 4,
        // "uuid": "20240705",
        // "username": null,
        // "nickname": "小明",
        // "department": "研发测试部",
        // "employeeNum": null,
        // "email": "xiaom@jaid.cn",
        // "phone_num": null,
        // "registered_on": "2024-07-05 16:50:33",
        // "last_login": "2024-08-05 09:27:01",
        // "admin": false,
        // "vip_level": 1,
        // "surname": null,
        // "vip_start_time": "2024-07-05",
        // "vip_end_time": "2024-07-05",
        // "gender": "man",
        // "age": "18-24",
        // "city": "北京",
        // "occupation": "学生",
        // "avatar": "",
        // "this_login": "2024-08-08 08:40:31",
        // "has_details": true,
        // "is_first_login": false
    },
    page_show: 'login',   // login register code
    register_nickname: '',
    register_email: '',
    register_password: '',
    register_second_password: '',
})


const reducer = (state=defaultState, action) => {
    let newState;

    switch(action.type) {
        case actionTypes.SET_EMAIL_ACTION:
            newState = state.set('email', action.value)
            break;
        case actionTypes.SET_PASSWORD_ACTION:
            newState = state.set('password', action.value)
            break;
        case actionTypes.IS_LOGIN_LOADING_ACTION:
            newState = state.set('is_login_loading', action.status)
            break;
        case actionTypes.HAS_LOGIN_ACTION:
            newState = state.set('has_login', action.status)
            break;
        case actionTypes.USER_DATA_ACTION:
            newState = state.merge({
                'user_data': fromJS(action.data),
            })
            break;
        case actionTypes.SET_PAGE_SHOW_ACTION:
            newState = state.set('page_show', action.value)
            break;
        case actionTypes.SET_REGISTER_NICKNAME:
            newState = state.set('register_nickname', action.value)
            break;
        case actionTypes.SET_REGISTER_EMAIL:
            newState = state.set('register_email', action.value)
            break;
        case actionTypes.SET_REGISTER_PASSWORD:
            newState = state.set('register_password', action.value)
            break;
        case actionTypes.SET_REGISTER_SECOND_PASSWORD:
            newState = state.set('register_second_password', action.value)
            break;
        default:
            return state
    }

    // 更新 localStorage 中的状态
    const loginStateToPersist = {
        has_login: newState.get('has_login'),
        user_data: newState.get('user_data').toJS(),
    };

    localStorage.setItem('loginState', JSON.stringify(loginStateToPersist));

    return newState;
}

export default reducer
