import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux'
import { Table, Input, Space } from 'antd';
import { actionCreators } from '../../store'


const UserTable = () => {
    const columns = [
        {
            title: '姓名',
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: '用户名',
            render: (_, record) => (
                <span>{record.nickname ? record.nickname : ''}</span>
            ),
            key: 'nickname',
        },
        {
            title: '邮箱',
            dataIndex: 'email',
            sorter: {
                compare: (a, b) => {
                    if (a.email > b.email) {
                        return 1
                    } else if (a.email < b.email) {
                        return -1
                    } else {
                        return 0
                    }
                },
            },
            key: 'email',
        },
        {
            title: '年龄',
            dataIndex: 'age',
            key: 'age',
        },
        {
            title: '性别',
            dataIndex: 'gender',
            key: 'gender',
        },
        {
            title: '城市',
            dataIndex: 'city',
            key: 'city',
        },
        {
            title: '职业',
            dataIndex: 'occupation',
            key: 'occupation',
        },
        {
            title: '首次登录时间',
            dataIndex: 'registered_on',
            sorter: {
                compare: (a, b) => {
                    if (a.registered_on > b.registered_on) {
                        return 1
                    } else if (a.registered_on < b.registered_on) {
                        return -1
                    } else {
                        return 0
                    }
                },
            },
            key: 'registered_on',
        },
        {
            title: '最后活跃时间',
            dataIndex: 'last_login',
            sorter: {
                compare: (a, b) => {
                    if (a.last_login > b.last_login) {
                        return 1
                    } else if (a.last_login < b.last_login) {
                        return -1
                    } else {
                        return 0
                    }
                },
            },
            key: 'last_login',
        },
        {
            title: '查看个人的对话信息',
            render: (_, record) => {
                return (
                    <Space size="middle">
                        <a onClick={() => {onConversationsClick(record.uuid)}}>查看</a>
                    </Space>
                )
            },
            key: 'uuid',
        },
        {
            title: '对话分类统计',
            render: (_, record) => {
                return (
                    <Space size="middle">
                        <a onClick={() => {onCategorizeClick(record.uuid)}}>查看</a>
                    </Space>
                )
            },
            key: 'uuid',
        },
    ];
    const dispatch = useDispatch()
    const [searchText, setSearchText] = useState('');
    const [data, setData] = useState([]);

    let user_statistics_data = useSelector(state =>
        state.get('admin').get('user_statistics_data')
    )

    // 监听 user_statistics_data 的变化，并设置到本地 state
    useEffect(() => {
        setData(user_statistics_data.toJS());
    }, [user_statistics_data]);

    const handleSearch = () => {
        const filteredData = user_statistics_data.toJS().filter((item) =>
            !item.username || item.username.includes(searchText.trim())
        );
        setData(filteredData);
    };

    const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    };

    const onConversationsClick = (uuid) => {
        dispatch(actionCreators.setActionUuidAction(uuid))
        dispatch(actionCreators.setActiveNavbarAction('personal_conversation'))
    }

    const onCategorizeClick = (uuid) => {
        dispatch(actionCreators.setActionUuidAction(uuid))
        dispatch(actionCreators.setActiveNavbarAction('categorize_user_management'))
    }

    return (
        <>
            <div style={{width: 200}}>
                <Input.Search
                    placeholder="搜索用户名"
                    value={searchText}
                    onSearch={handleSearch}
                    onChange={(e) => setSearchText(e.target.value)}
                    style={{ marginBottom: 8 }}
                />
            </div>
            <Table
                columns={columns}
                dataSource={data}
                onChange={onChange}
            />
        </>
    )
}

export default UserTable