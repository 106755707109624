import React, { useState, useEffect } from 'react';
import { Table, Space, Input } from 'antd';
import { connect, useDispatch, useSelector } from 'react-redux'

import { actionCreators } from '../../store'
import styles from './index.module.css'
import ConversationCountDatePicker from '../ConversationCountDatePicker'


const ConversationCountTable = () => {
    const columns = [
        {
            title: '姓名',
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: '用户名',
            render: (_, record) => (
                <span>{record.nickname ? record.nickname : ''}</span>
            ),
            key: 'nickname',
        },
        {
            title: '用户对话次数',
            render: (_, record) => (
                <span>{`${record.query_count + record.re_query_count}`}</span>
            ),
            sorter: {
                compare: (a, b) => {
                    let num_a = a.query_count + a.re_query_count
                    let num_b = b.query_count + b.re_query_count
                    if (num_a > num_b) {
                        return 1
                    } else if (num_a < num_b) {
                        return -1
                    } else {
                        return 0
                    }
                },
            },
            key: 'times',
        },
        {
            title: '查看个人的对话信息',
            render: (_, record) => {
                return (
                    <Space size="middle">
                        <a onClick={() => {onClick(record.uuid)}}>查看</a>
                    </Space>
                )
            },
            key: 'uuid',
        },
    ];
    const dispatch = useDispatch()
    const [searchText, setSearchText] = useState('');
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
    });
    let conversation_count_data = useSelector(state => state.get('admin').get('conversation_count_data'))

    useEffect(() => {
        setData(conversation_count_data.toJS());
    }, [conversation_count_data]);

    const handleSearch = () => {
        const filteredData = conversation_count_data.toJS().filter((item) =>
            !item.username || item.username.includes(searchText.trim())
        );
        setData(filteredData);
    }

    const onClick = (uuid) => {
        dispatch(actionCreators.setActionUuidAction(uuid))
        dispatch(actionCreators.setActiveNavbarAction('personal_conversation'))
    }

    const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
        setPagination(pagination);
    }

    const handleRefresh = () => {
        setPagination(prev => ({ ...prev, current: 1 }));
    }

    return (
        <>
            <div className={styles.filter}>
                <Input.Search
                    placeholder="搜索用户名"
                    value={searchText}
                    onSearch={handleSearch}
                    onChange={(e) => setSearchText(e.target.value)}
                    style={{ width: 200, marginBottom: 8 }}
                />
                <ConversationCountDatePicker handleRefresh={handleRefresh} />
            </div>
            <Table
                columns={columns}
                dataSource={data}
                onChange={onChange}
                pagination={pagination}
            />
        </>
    )
}

export default ConversationCountTable