import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import ConversationCountUsageCard from '../ConversationCountUsageCard'
import ConversationCountTable from '../ConversationCountTable'
import { actionCreators } from '../../store'
import styles from './index.module.css'


const ConversationCountStatistics = () => {
    const dispatch = useDispatch()

    // useEffect(() => {
    //     dispatch(actionCreators.fetchConversationCountInfo())
    // }, [])

    return (
        <div>
            <div className={styles.header}>
                <div className={styles.title}>
                    对话统计信息
                </div>
            </div>

            <div className={styles.card_box}>
                <ConversationCountUsageCard />
            </div>

            <div className={styles.table_box}>
                <ConversationCountTable />
            </div>
        </div>
    )
}

export default ConversationCountStatistics;