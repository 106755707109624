var findConversationId = function(all_chat_data, active_date, active_topicid) {
    if (all_chat_data.hasOwnProperty(active_date)) {
        let conversations = all_chat_data[active_date]
        for (let conversation of conversations) {
            if (conversation.topic_id === active_topicid) {
                return conversation.conversation_id
            }
        }
    }

    return ''
}

var extractConversationIdAndId = function(data) {
    // 检验 data 是否为空对象或者 data.conversation 是否为空对象
    if (Object.keys(data).length === 0 || !data.conversation || Object.keys(data.conversation).length === 0) {
        return {'conversation_id': '', 'id': '', 'created_at': '',}
    } else {
        // 如果存在值，则返回真正的 conversation_id 和 id
        return {
            'conversation_id': data.conversation.conversation_id,
            'id': data.conversation.id,
            'created_at': data.conversation.created_at,
        }
    }
}

var getSingleConversationFromAllChatData = function(all_chat_data, active_date, active_topicid) {
    if (all_chat_data.hasOwnProperty(active_date)) {
        let conversations = all_chat_data[active_date]
        for (let conversation of conversations) {
            if (conversation.topic_id === active_topicid) {
                return conversation
            }
        }
    }

    return {} // 如果没有找到对应的日期或者 topic_id，返回空对象
}

var getMessagesFromAllChatData = function(all_chat_data, active_date, active_topicid) {
    if (all_chat_data.hasOwnProperty(active_date)) {
        let conversations = all_chat_data[active_date]
        for (let conversation of conversations) {
            if (conversation.topic_id === active_topicid) {
                return conversation.data // 返回找到的 data 数组
            }
        }
    }

    return [] // 如果没有找到对应的日期或者 topic_id，返回空数组
}

var isMessagesEmpty = function(all_chat_data, active_date, active_topicid) {
    if (all_chat_data.hasOwnProperty(active_date)) { // 检查对象是否有这个日期作为属性
        let conversations = all_chat_data[active_date]
        for (let conversation of conversations) {
            if (conversation.topic_id === active_topicid && Array.isArray(conversation.data)) {
                return conversation.data.length === 0 // 如果是空数组返回 true
            }
        }
    }

    return false // 如果没有找到对应的日期或者 topic_id，则返回 false
}

var uniqueByImageId = (arr) => {
    const seen = {};
    return arr.filter(item => {
        if (!seen[item.id]) {
            seen[item.id] = true;
            return true;
        }
        return false;
    });
}

var addKeyWithExtrainfoIdAndScore = (arr) => {
    arr.forEach(element => {
        if (!element.extrainfo_id) {
            element['extrainfo_id'] = null
        }

        if (!element.extrainfo_score) {
                // 1 点赞
                // -1 取消点赞（未点赞的状态）
            element['extrainfo_score'] = -1
        }
    });

    return arr
}

var filterSearchInfo = (a) => {
    let combinedArray = [];
    for (let key in a) {
        // 检查属性是否是对象自己的属性而不是继承而来的
        if (a.hasOwnProperty(key)) {
            let array = a[key];
            // 遍历数组，为每个对象添加 date 属性
            let modifiedArray = array.map(item => ({ ...item, date: key }));
            // 将修改后的数组合并到 combinedArray 中
            combinedArray = combinedArray.concat(modifiedArray);
        }
    }

    return combinedArray
}

export {
    findConversationId,
    extractConversationIdAndId,
    getSingleConversationFromAllChatData,
    getMessagesFromAllChatData,
    isMessagesEmpty,
    uniqueByImageId,
    addKeyWithExtrainfoIdAndScore,
    filterSearchInfo,
}