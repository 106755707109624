import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Button, Input } from 'antd'
import { CloudUploadOutlined } from '@ant-design/icons';

import { actionCreators } from '../../store';
import styles from './index.module.css'


function KnowledgeBaseFileUploadButton() {
    const dispatch = useDispatch()
    let active_model_name = useSelector(state => state.get('admin').get('active_model_name'))
    let knowledge_base_list_search_name = useSelector(state => state.get('admin').get('knowledge_base_list_search_name'))
    let knowledge_base_list = useSelector(state => state.get('admin').get('knowledge_base_list'))
    const [activeModelRType, setActiveModelRType] = useState('picture_kb')

    useEffect(() => {
        return () => {
            dispatch(actionCreators.setKnowledgeBaseListSearchName(''))
        }
    }, [])

    useEffect(() => {
        let obj = knowledge_base_list.toJS().find(item => item.name === active_model_name)
        if (obj) {
            if (obj.r_type === 'picture_kb') {
                setActiveModelRType('picture_kb')
            } else {
                setActiveModelRType('document_kb')
            }
        }
    }, [active_model_name, knowledge_base_list])

    const handleClick = () => {
        dispatch(actionCreators.setKnowledgeBaseShowContentAction('upload'))
    }

    const handleOnChange = (e) => {
        let value = e.target.value
        dispatch(actionCreators.setKnowledgeBaseListSearchName(value))
    }

    return (
        <div className={styles.main}>
            <Button
                type="primary"
                shape="round"
                icon={<CloudUploadOutlined />}
                onClick={handleClick}
            >
                {activeModelRType === 'picture_kb' ? '上传图片' : '上传文件'}
            </Button>

            <div className={styles.search_box}>
                <Input
                    placeholder="要查询的文件名"
                    value={knowledge_base_list_search_name}
                    onChange={handleOnChange}
                    style={{width: '70%'}}
                />
            </div>
        </div>
    )
}

export default KnowledgeBaseFileUploadButton