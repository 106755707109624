import * as actionTypes from './actionTypes'
import { actionCreators as homeActionCreators } from '../../home/store'


export const filterButnAction = (value) => {
    return {
        type: actionTypes.FILTER_BUTN_ACTION,
        value: value,
    }
}

// export const redirectAndAddNewTopicAction = () => {
//     return (dispatch, getState) => {
//         dispatch(homeActionCreators.addNewConversationAction())
//     }
// }
export const filterCategoryListAction = (data) => ({
    type: actionTypes.FILTER_CATEGORY_LIST_ACTION,
    data: data,
})