import React from 'react'
import { connect } from 'react-redux'

import styles from './index.module.css'
// import { actionCreators } from '../../store'

class ModalImage extends React.Component {
    constructor(props) {
        super(props)
        this.imgRef = React.createRef()
        this.containerRef = React.createRef()
        this.logRef = React.createRef()
        this.closeButtonRef = React.createRef();

        this.state = {
            result: {
                width: 0,
                height: 0,
            },
            x: 0,
            y: 0,
            scale: 1,
            minScale: 0.5,
            maxScale: 4,
            isPointerdown: false, // 按下标识
            point: { x: 0, y: 0 }, // 第一个点坐标
            diff: { x: 0, y: 0 }, // 相对于上一次pointermove移动差值
            lastPointermove: { x: 0, y: 0 }, // 用于计算diff
            isLoading: true, // 添加加载状态
        }

        this.handleImgLoad = this.handleImgLoad.bind(this)
        this.handlePointerdown = this.handlePointerdown.bind(this)
        this.handlePointermove = this.handlePointermove.bind(this)
        this.handlePointerup = this.handlePointerup.bind(this)
        this.handlePointercancel = this.handlePointercancel.bind(this)
        this.hanldeWheel = this.hanldeWheel.bind(this)
        this.updateCloseButtonPosition = this.updateCloseButtonPosition.bind(this);
    }

    componentDidMount() {
        this.loadImgInfo()
        this.updateCloseButtonPosition(); // 初始化关闭按钮的位置
        window.addEventListener('resize', this.updateCloseButtonPosition); // 页面尺寸改变时更新位置
    }

    componentWillUnmount() {
        // 去除事件的绑定
        // this.imgRef.current.removeEventListener('load', this.handleImgLoad)
        this.imgRef.current.removeEventListener('pointerdown', this.handlePointerdown)
        this.imgRef.current.removeEventListener('pointermove', this.handlePointermove)
        this.imgRef.current.removeEventListener('pointerup', this.handlePointerup)
        this.imgRef.current.removeEventListener('pointercancel', this.handlePointercancel)
        this.containerRef.current.removeEventListener('wheel', this.hanldeWheel)
        window.removeEventListener('resize', this.updateCloseButtonPosition);
    }

    initStateInfo() {
        this.setState({
            result: {
                width: 0,
                height: 0,
            },
            x: 0,
            y: 0,
            scale: 1,
            minScale: 0.5,
            maxScale: 4,
            isPointerdown: false,
            point: { x: 0, y: 0 },
            diff: { x: 0, y: 0 },
            lastPointermove: { x: 0, y: 0 },
        })

        // 去除事件的绑定
        this.imgRef.current.removeEventListener('pointerdown', this.handlePointerdown)
        this.imgRef.current.removeEventListener('pointermove', this.handlePointermove)
        this.imgRef.current.removeEventListener('pointerup', this.handlePointerup)
        this.imgRef.current.removeEventListener('pointercancel', this.handlePointercancel)
        this.containerRef.current.removeEventListener('wheel', this.hanldeWheel)
    }

    loadImgInfo() {
        // 图片加载完成后再绑定事件
        // this.imgRef.current.addEventListener('load', this.handleImgLoad);

        // this.imgRef.current.src = "https://img-blog.csdnimg.cn/20210515113558981.png?x-oss-process=image/watermark,type_ZmFuZ3poZW5naGVpdGk,shadow_10,text_aHR0cHM6Ly9ibG9nLmNzZG4ubmV0L3FxXzE2NTI1Mjc5,size_16,color_FFFFFF,t_70#pic_center"
    }

    getImgSize(naturalWidth, naturalHeight, maxWidth, maxHeight) {
        // 获取图片缩放尺寸
        const imgRatio = naturalWidth / naturalHeight;
        const maxRatio = maxWidth / maxHeight;
        let width, height;
        // 如果图片实际宽高比例 >= 显示宽高比例
        if (imgRatio >= maxRatio) {
            if (naturalWidth > maxWidth) {
                width = maxWidth;
                height = maxWidth / naturalWidth * naturalHeight;
            } else {
                width = naturalWidth;
                height = naturalHeight;
            }
        } else {
            if (naturalHeight > maxHeight) {
                width = maxHeight / naturalHeight * naturalWidth;
                height = maxHeight;
            } else {
                width = naturalWidth;
                height = naturalHeight;
            }
        }

        return { width: width, height: height }
    }

    drag() {
        // 拖拽查看

        // 绑定 pointerdown
        this.imgRef.current.addEventListener('pointerdown', this.handlePointerdown);

        // 绑定 pointermove
        this.imgRef.current.addEventListener('pointermove', this.handlePointermove);

        // 绑定 pointerup
        this.imgRef.current.addEventListener('pointerup', this.handlePointerup);

        // 绑定 pointercancel
        this.imgRef.current.addEventListener('pointercancel', this.handlePointercancel);
    }

    wheelZoom() {
        // 滚轮缩放
        this.containerRef.current.addEventListener('wheel', this.hanldeWheel, { passive: false });
    }

    handleImgLoad(e) {
        // 将 state 里面的值重新初始化
        this.initStateInfo()

        var result = this.getImgSize(this.imgRef.current.naturalWidth, this.imgRef.current.naturalHeight, window.innerWidth - 100, window.innerHeight - 100)
        this.setState({
            result: result,
        })

        this.imgRef.current.style.width = result.width + 'px';
        this.imgRef.current.style.height = result.height + 'px';

        let x = (window.innerWidth - result.width) * 0.5
        let y = (window.innerHeight - result.height) * 0.5
        this.setState({
            x: x,
            y: y,
        })
        this.imgRef.current.style.transform = 'translate3d(' + x + 'px, ' + y + 'px, 0) scale(1)';

        this.setState({
            isLoading: false,
        });

        // 拖拽查看
        this.drag();
        // 滚轮缩放
        this.wheelZoom();

        this.updateCloseButtonPosition(); // 图片加载后更新按钮位置
    }

    handlePointerdown(e) {
        e.preventDefault()

        this.setState({
            isPointerdown: true,
        })

        this.imgRef.current.setPointerCapture(e.pointerId);

        var pointData = Object.assign({}, this.state.point, {
            x: e.clientX,
            y: e.clientY,
        })

        var lastPointermoveData = Object.assign({}, this.state.lastPointermove, {
            x: e.clientX,
            y: e.clientY,
        })

        this.setState({
            point: pointData,
            lastPointermove: lastPointermoveData,
        })
    }

    handlePointermove(e) {
        e.preventDefault();

        if (this.state.isPointerdown) {
            const current1 = { x: e.clientX, y: e.clientY };
            var diffData = Object.assign({}, this.state.diff, {
                x: current1.x - this.state.lastPointermove.x,
                y: current1.y - this.state.lastPointermove.y,
            })
            var lastPointermoveData = Object.assign({}, this.state.lastPointermove, {
                x: current1.x,
                y: current1.y,
            })

            var x = this.state.x + diffData.x
            var y = this.state.y + diffData.y
            this.setState({
                diff: diffData,
                lastPointermove: lastPointermoveData,
                x: x,
                y: y,
            })
            this.imgRef.current.style.transform = 'translate3d(' + x + 'px, ' + y + 'px, 0) scale(' + this.state.scale + ')';
            this.logRef.current.innerHTML = `this.state.x = ${x.toFixed(0)}<br>y = ${y.toFixed(0)}<br> this.state.scale = ${this.state.scale.toFixed(5)}`;

            this.updateCloseButtonPosition(); // 拖动后更新按钮位置
        }
    }

    handlePointerup(e) {
        if (this.state.isPointerdown) {
            this.setState({
                isPointerdown: false,
            })
        }
    }

    handlePointercancel(e) {
        if (this.state.isPointerdown) {
            this.setState({
                isPointerdown: false,
            })
        }
    }

    hanldeWheel(e) {
        e.preventDefault();

        let ratio = 1.1;
        // 缩小
        if (e.deltaY > 0) {
            ratio = 1 / 1.1;
        }
        // 限制缩放倍数
        const _scale = this.state.scale * ratio;
        let newScale = 1
        if (_scale > this.state.maxScale) {
            ratio = this.state.maxScale / this.state.scale
            newScale = this.state.maxScale
        } else if (_scale < this.state.minScale) {
            ratio = this.state.minScale / this.state.scale
            newScale = this.state.minScale
        } else {
            newScale = _scale
        }

        let offsetX = this.state.x;
        let offsetY = this.state.y;
        // 目标元素是img说明鼠标在img上，以鼠标位置为缩放中心，否则默认以图片中心点为缩放中心
        if (e.target.tagName === 'IMG') {
            const origin = {
                x: (ratio - 1) * this.state.result.width * 0.5,
                y: (ratio - 1) * this.state.result.height * 0.5
            };

            // 计算偏移量
            offsetX = this.state.x - ((ratio - 1) * (e.clientX - this.state.x) - origin.x)
            offsetY = this.state.y - ((ratio - 1) * (e.clientY - this.state.y) - origin.y)
        }

        // this.imgRef.current.style.transform = 'translate3d(' + this.state.x + 'px, ' + this.state.y + 'px, 0) scale(' + this.state.scale + ')';
        // this.logRef.current.innerHTML = `this.state.x = ${this.state.x.toFixed(0)}<br>y = ${this.state.y.toFixed(0)}<br>this.state.scale = ${this.state.scale.toFixed(5)}`;

        this.setState({
            scale: newScale,
            x: offsetX,
            y: offsetY,
        }, () => {
            this.imgRef.current.style.transform = `translate3d(${offsetX}px, ${offsetY}px, 0) scale(${newScale})`;
            this.logRef.current.innerHTML = `x = ${offsetX.toFixed(0)}<br>y = ${offsetY.toFixed(0)}<br>scale = ${newScale.toFixed(5)}`;
            this.updateCloseButtonPosition(); // 缩放后更新按钮位置
        });

    }

    updateCloseButtonPosition() {
        const img = this.imgRef.current;
        if (!img) return;

        // 获取图片的边界信息
        const rect = img.getBoundingClientRect();

        // 计算关闭按钮的位置
        const top = rect.top + window.scrollY; // 加上滚动偏移
        const right = window.innerWidth - rect.right + window.scrollX; // 加上滚动偏移

        // 设置关闭按钮的样式
        const closeButton = this.closeButtonRef.current;
        if (closeButton) {
          closeButton.style.position = 'absolute';
          closeButton.style.top = `${top + 16}px`;
          closeButton.style.right = `${right + 16}px`;
          closeButton.style.zIndex = 1000; // 确保按钮显示在最上层
        }
    }

    render() {
        return (
            <>
                {/* {this.state.isLoading && <div className={styles.loader}>Loading...</div>} */}
                <div
                    className={styles.container}
                    id="id_container"
                    ref={this.containerRef}
                >
                    <img
                        id="id_show_img_url"
                        className={styles.img_limit}
                        src={this.props.showImageUrl}
                        alt="show img"
                        ref={this.imgRef}
                        onLoad={this.handleImgLoad.bind(this)}
                        style={{ opacity: this.state.isLoading ? 0 : 1 }}
                    />
                    <div
                        ref={this.closeButtonRef}
                        onClick={this.handleFileImgVideoVisible.bind(this)}
                        className={styles.close_button}
                    >
                    </div>
                </div>
                <div
                    id="id_log"
                    ref={this.logRef}
                >
                </div>
            </>
        )
    }

    handleFileImgVideoVisible() {
        this.props.setIsModalOpen(false)
        this.props.setShowImageUrl('')
        // this.props.handleMessageImageVisibleAction(false)
        // this.props.handleMessageItemImagePathAction('')
    }
}

const mapStateToProps = (state) => {
    return {
        // message_item_image_path: state.get('home').get('message_item_image_path'),
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // handleMessageImageVisibleAction(status) {
        //     dispatch(actionCreators.messageImageVisibleAction(status))
        // },
        // handleMessageItemImagePathAction(path) {
        //     dispatch(actionCreators.messageItemImagePathAction(path))
        // },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalImage)