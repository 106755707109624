import { transformDateList } from './formatChatData';

const experienceIcons = {
    'lv1': require('../statics/lv1_icon.png'),
    'lv2': require('../statics/lv2_icon.png'),
    'lv3': require('../statics/lv3_icon.png'),
    'lv4': require('../statics/lv4_icon.png'),
    'lv5': require('../statics/lv5_icon.png'),
    'lv6': require('../statics/lv6_icon.png'),
}

function checkExperience(levels, exp) {
    for (let i = 0; i < levels.length; i++) {
        const lvStartExp = i === 0 ? 0 : levels[i - 1];
        const lvExp = levels[i] - lvStartExp;
        const nextLvExp = exp === levels[i] + 1;

        // 计算1/3和2/3的经验点
        const oneThirdMark = lvStartExp + Math.ceil(lvExp / 3);
        const twoThirdsMark = lvStartExp + Math.ceil((lvExp * 2) / 3);

        if (nextLvExp) {
            return { showModal: true, status: '升级' };
        } else if (exp === oneThirdMark) {
            return { showModal: true, status: '还差 2/3 就达到' };
        } else if (exp === twoThirdsMark) {
            return { showModal: true, status: '还差 1/3 就达到' };
        }
    }

    return { showModal: false, status: '' }; // 默认情况，不显示弹窗
}

function getLevelFromExperience(current_experience, levelThresholds) {
    let levelInfo = {
        level: 1,
        totalExperience: levelThresholds[0]
    }

    let userLevel = 1;  // 默认最小等级为 lv1
    let userTotalExperience = levelThresholds[0];  // 默认 lv1 对应的总经验值
    for (let i = 0; i < levelThresholds.length; i++) {
        if (current_experience <= levelThresholds[i]) {
            userTotalExperience = levelThresholds[i];
            break;
        }
        userLevel = i + 2;
        userTotalExperience = levelThresholds[i + 1];
    }

    levelInfo['level'] = userLevel
    levelInfo['totalExperience'] = userTotalExperience

    return levelInfo
}

export {
    experienceIcons,
    checkExperience,
    getLevelFromExperience,
}
