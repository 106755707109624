import React, { Component } from 'react';
import { connect } from 'react-redux'

import styles from './index.module.css'
import { actionCreators } from '../../store'
import SideBarChatHistory from '../SideBarChatHistory'
import CustomCollapse from '../../../../common/CustomCollapse'


class SideBarChatHistoryList extends Component {
    generateItems(chat_date_list, all_chat_data) {
        let items = []

        for (let item of chat_date_list) {
            if (all_chat_data[item.value].length !== 0) {
                items.push({
                    key: item.value,
                    label: (
                        <div className={styles.expert_butn_box}>
                            <div className={styles.item_title}>{item.label}</div>
                        </div>
                    ),
                    children: (
                        <SideBarChatHistory
                            key={item.value}
                            ChatDataDate={item.value}
                            ChatData={all_chat_data[item.value]}
                        />
                    ),
                })
            }
        }

        return items
    }

    render() {
        let all_chat_data = this.props.all_chat_data.toJS()
        let chat_date_list = this.props.chat_date_list.toJS()
        let items = this.generateItems(chat_date_list, all_chat_data)

        return (
            <div className={styles.container}>
                {
                    <CustomCollapse items={items} />
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        all_chat_data: state.get('home').get('all_chat_data'),
        chat_date_list: state.get('home').get('chat_date_list'),
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SideBarChatHistoryList)