export const SET_ACTIVE_NAVBAR_ACTION = 'admin/SET_ACTIVE_NAVBAR_ACTION'
export const SET_USER_STATISTICS_DATA_ACTION = 'admin/SET_USER_STATISTICS_DATA_ACTION'
export const SET_USER_STATISTICS_TODAY_LOGIN_NUM = 'admin/SET_USER_STATISTICS_TODAY_LOGIN_NUM'
export const SET_USER_STATISTICS_WEEK_LOGIN_NUM = 'admin/SET_USER_STATISTICS_WEEK_LOGIN_NUM'
export const SET_USER_STATISTICS_MONTH_DAY_AVG = 'admin/SET_USER_STATISTICS_MONTH_DAY_AVG'
export const SET_CONVERSATION_COUNT_DATA_ACTION = 'admin/SET_CONVERSATION_COUNT_DATA_ACTION'
export const SET_ACTIVE_UUID_ACTION = 'admin/SET_ACTIVE_UUID_ACTION'
export const SET_PERSONAL_CONVERSATION_DATA_ACTION = 'admin/SET_PERSONAL_CONVERSATION_DATA_ACTION'
export const SET_ACTIVE_MODEL_ACTION = 'admin/SET_ACTIVE_MODEL_ACTION'
export const SET_KNOWLEDGE_BASE_MENU_ACTION = 'admin/SET_KNOWLEDGE_BASE_MENU_ACTION'
export const SET_KNOWLEDGE_BASE_LIST_ACTION = 'admin/SET_KNOWLEDGE_BASE_LIST_ACTION'
export const SET_KNOWLEDGE_BASE_LIST_SEARCH_NAME = 'admin/SET_KNOWLEDGE_BASE_LIST_SEARCH_NAME'
export const SET_KNOWLEDGE_BASE_SHOW_CONTENT = 'admin/SET_KNOWLEDGE_BASE_SHOW_CONTENT'
export const SET_FILE_SLIT_LIST_ACTION = 'admin/SET_FILE_SLIT_LIST_ACTION'
export const SET_VERSION_UPDATE_LIST_ACTION = 'admin/SET_VERSION_UPDATE_LIST_ACTION'
export const IS_SHOW_VERSION_UPDATE_IMAGE = 'admin/IS_SHOW_VERSION_UPDATE_IMAGE'
export const SHOW_VERSION_UPDATE_IMAGE_ID = 'admin/SHOW_VERSION_UPDATE_IMAGE_ID'
export const SET_PERMISSION_USER_LIST_ACTION = 'admin/SET_PERMISSION_USER_LIST_ACTION'
export const SET_PERMISSION_USER_PAGE_ACTION = 'admin/SET_PERMISSION_USER_PAGE_ACTION'
export const SET_PERMISSION_USER_UUID_ACTION = 'admin/SET_PERMISSION_USER_UUID_ACTION'
export const SET_PERMISSION_GROUP_LIST_ACTION = 'admin/SET_PERMISSION_GROUP_LIST_ACTION'
export const SET_PERMISSION_GROUP_PAGE_ACTION = 'admin/SET_PERMISSION_GROUP_PAGE_ACTION'
export const SET_PERMISSION_GROUP_NAME_ACTION = 'admin/SET_PERMISSION_GROUP_NAME_ACTION'
export const SET_KNOWLEDGE_BASE_OPTIONS_ACTION = 'admin/SET_KNOWLEDGE_BASE_OPTIONS_ACTION'
export const SET_DEFAULT_KNOWLEDGE_BASE_OPTIONS = 'admin/SET_DEFAULT_KNOWLEDGE_BASE_OPTIONS'
export const SET_EDIT_KNOWLEDGE_BASE_NAME = 'admin/SET_EDIT_KNOWLEDGE_BASE_NAME'
export const SET_FILTER_DATES = 'admin/SET_FILTER_DATES'
export const ADMIN_CHAT_TYPE_OPTIONS_ACTION = 'admin/ADMIN_CHAT_TYPE_OPTIONS_ACTION'
export const ADMIN_EXPERT_CATEGORY_LIST_ACTION = 'admin/ADMIN_EXPERT_CATEGORY_LIST_ACTION'
export const COMPONENT_PICTURE_LISTED = 'admin/COMPONENT_PICTURE_LISTED'
export const COMPONENT_DOCUMENT_LISTED = 'admin/COMPONENT_DOCUMENT_LISTED'
export const CURRENT_EDIT_COMPONENT_NAME = 'admin/CURRENT_EDIT_COMPONENT_NAME'
export const CURRENT_EDIT_COMPONENT_TYPE = 'admin/CURRENT_EDIT_COMPONENT_TYPE'
export const COMPONENT_LISTED_SELECT_MENU = 'admin/COMPONENT_LISTED_SELECT_MENU'
