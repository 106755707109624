var transformknowledgeBase = function(originalData) {
    let a = []
    originalData.forEach((item) => {
        if (item.name !== '图片知识库') {
            a.push(
                {
                    id: item.id,
                    label: item.name,
                    value: item.label,
                    info: item.overview,
                    permissions: item.permissions,
                    status: item.status,
                    active: item.pinned,
                    r_type: item.r_type,
                    weight: item.weight,
                    category: item.category,
                    avatar: item.avatar,
                    avatar_tb: item.avatar_tb,
                    is_active: item.is_active,
                }
            )
        }
    })

    return a
}

var containsWord = function(array, word) {
    return array.some(element => typeof element === 'string' && element.toLowerCase() === word);
}


export {
    transformknowledgeBase,
    containsWord,
}

/*
[{
        id: 3,
        label: '测试版规范专家',
        value: 'specification_expert',
        info: '这是一个测试专家模型',

        r_type: 'llm_chat',
        active: true,
        weight: 11.8,
        category: 'auxiliary_model',
},]

[{
        id: 3,
        name: "测试版规范专家",
        collection: "specification_expert",
        intro: "这是一个测试专家模型",

        created_at: "2024-06-27 06:41:51",
        embed_model: "text-embedding-v1",
        file_count: 1,
        updated_at: "2024-06-28 02:52:42",
        vector_store: "adb-pg",
},]
*/