import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Dropdown } from 'antd'

import { actionCreators } from '../../store'
import { actionCreators as loginActionCreators } from '../../../login/store'
import styles from './index.module.css'

import userAvatar from '../../../../statics/avatar.png'
import userWomanAvatar from '../../../../statics/avatar_woman.png'
import logoutImg from '../../../../statics/logout.png'
import logoutRedImg from '../../../../statics/logout_red.png'
import userSettingImg from '../../../../statics/userinfo_setting.png'
import { isQYWechatAppEnvironment } from '../../../../utils';

class SideBarUserInfo extends Component {
    handleUserInfoSetting() {
        console.log("handleUserInfoSetting");
        this.props.handleUserinfoModalOpenAction()
    }

    handleLogout() {
        console.log("handleLogout");
        this.props.history.push({ pathname: "/" })
        this.props.handleLogoutAction()
    }

    handleJumpToAdmin() {
        console.log("handleJumpToAdmin");
        this.props.history.push({ pathname: "/admin" })
    }

    generateItems(user_data) {
        let items = [
            {
                label: <div className={styles.userinfo_butn} onClick={this.handleUserInfoSetting.bind(this)}>
                            <div className={styles.userinfo_text_box}>
                                <span className={styles.userinfo_username}>{user_data.nickname ? user_data.nickname : ''}</span>
                                <span className={styles.userinfo_email}>{user_data.email}</span>
                            </div>
                            <img src={userSettingImg} className={styles.userinfo_setting} />
                        </div>,
                key: '1',
            },
        ]

        if (!isQYWechatAppEnvironment()) {
            items.push(
                {
                    type: 'divider',
                }
            )

            items.push(
                {
                    label: <div className={styles.logout_butn} onClick={this.handleLogout.bind(this)}>
                                <img src={logoutRedImg} className={styles.logout_red_img} />
                                <span className={styles.logout_text}>退出账户</span>
                            </div>,
                    key: '2',
                }
            )
        }

        if (user_data.admin) {
            items.unshift(
                {
                    label: <div className={styles.userinfo_butn} onClick={this.handleJumpToAdmin.bind(this)}>
                                <div className={styles.admin_text_box}>
                                    跳转到【后台管理】画面
                                </div>
                            </div>,
                    key: '0',
                }
            )
        }

        return items
    }

    render() {
        let user_data = this.props.user_data.toJS()
        let items = this.generateItems(user_data)

        return (
            <>
                <Dropdown
                    menu={{
                        items,
                    }}
                    trigger={['click']}
                    placement={'bottom'}
                    overlayClassName={"userinfo_dropdown"}
                >
                    <div className={styles.main}>
                        <img src={user_data.avatar ? user_data.avatar : (user_data.gender === 'woman' ? userWomanAvatar : userAvatar)} className={styles.avatar} />
                        <div className={styles.username}>{user_data.nickname ? user_data.nickname : ''}</div>
                        <img src={logoutImg} className={styles.logout_img} />
                    </div>
                </Dropdown>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user_data: state.get('login').get('user_data'),
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleLogoutAction() {
            dispatch(loginActionCreators.logoutAction())
        },
        handleUserinfoModalOpenAction() {
            dispatch(actionCreators.handleUserinfoModalOpenAction())
        },
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SideBarUserInfo))