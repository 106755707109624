import React, { Component, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Input, Button, Form, DatePicker, TimePicker, message, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import styles from './index.module.css'
import arrowImg from '../../../../statics/update_done_butn_arrow_forward.png'
import { actionCreators } from '../../store';
import { compareVersions, incrementVersion, } from '../../../../utils'

function VersionUpdateModal({ isModalOpen, handleCloseModal }) {
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const [uploading, setUploading] = useState(false)
    const [fileList, setFileList] = useState([])
    const [versions, setVersions] = useState([])
    const [highestVersion, setHighestVersion] = useState('')
    let version_update_list = useSelector(state => state.get('admin').get('version_update_list'))

    useEffect(() => {
        let l = version_update_list.toJS()
        let a = l.map(item => { return item.release_v })
        a.sort(compareVersions)
        setVersions(a)
        let high = a[a.length - 1]
        setHighestVersion(high)
        form.setFieldsValue({
            version: incrementVersion(a)
        })
    }, [version_update_list])

    // 在文件选择后更新 fileList，但不执行上传操作
    const beforeUpload = (file) => {
        setFileList((prevFileList) => [...prevFileList, file]);
        return false; // 返回 false 以停止自动上传
    }

    // 移除文件
    const handleRemove = (file) => {
        setFileList((prevFileList) => prevFileList.filter(item => item.uid !== file.uid));
    }

    const handleClose = () => {
        handleCloseModal()
        setFileList([])
    }

    const onFinish = (values) => {
        console.log('Success:', values);
        const formattedDate = values.date.format('YYYY-MM-DD');
        const formattedTime = values.time.format('HH:mm:ss');
        let published_at = `${formattedDate} ${formattedTime}`
        let newVersion = values.version.trim()
        const versionPattern = /^\d+\.\d+\.\d+$/;

        if (newVersion === '') {
            message.info("版本号不能为空！")
        } else if (!versionPattern.test(newVersion)) {
            message.info('版本号格式错误，请输入格式为 x.x.x 的版本号，其中 x 为数字。')
        } else if (highestVersion && compareVersions(newVersion, highestVersion) <= 0) {
            message.info('新的版本号必须高于当前的最高版本！');
        } else if (versions.includes(newVersion)) {
            message.info('不能添加重复的版本号！');
        } else {
            setUploading(true)
            let formData = new FormData();
            formData.append('published_at', published_at)
            formData.append('release_v', newVersion)
            fileList.map((item) => {
                formData.append('images', item)
            })
            console.log("formData.get('published_at')", formData.get('published_at'));
            console.log("formData.get('release_v')", formData.get('release_v'));
            console.log("formData.get('images')", formData.get('images'));

            dispatch(actionCreators.createVersionUpdate(formData))
                .then(data => { handleClose() })
                .catch(error => {})
                .finally(() => { setUploading(false); setFileList([]) })
        }
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    }

    return (
        <>
            <Modal
                title="添加新的版本更新信息"
                open={isModalOpen}
                onOk={() => {handleClose()}}
                onCancel={() => {handleClose()}}
                // okText="确认"
                // cancelText="取消"
                footer={false}
                destroyOnClose={true}
            >
                <Form
                    name="basic"
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 16,
                    }}
                    style={{
                        maxWidth: 600,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    form={form}
                    preserve={false}
                    initialValues={{ version: incrementVersion(versions) }}
                >
                    {/* <Form.Item
                        label="版本更新信息"
                        name="updateInfo"
                        rules={[{ required: true, message: '请输入版本更新的信息' }]}
                    >
                        <Input.TextArea
                            style={{
                                resize: 'none',
                                width: '100%',
                                height: 100,
                            }}
                            placeholder="版本更新的信息"
                        />
                    </Form.Item> */}

                    <Form.Item
                        label="上传文件"
                        name="upload_files"
                        rules={[{ required: true, message: '请选择要上传的文件' }]}
                    >
                        <Upload.Dragger
                            fileList={fileList}
                            beforeUpload={beforeUpload}
                            onRemove={handleRemove}
                            multiple={true}
                            accept={"image/*"}
                        >
                            <p className="ant-upload-drag-icon">
                                <UploadOutlined />
                            </p>
                            <p className={`ant-upload-text ${styles.notice}`}>
                                建议尺寸：【宽 1139】【高 775】
                            </p>
                            <p className="ant-upload-text">
                                支持多个图片同时上传
                            </p>
                            <p className="ant-upload-text">
                                点击或拖拽图片到此区域上传文件
                            </p>
                        </Upload.Dragger>
                    </Form.Item>

                    <Form.Item
                        label="版本号"
                        name="version"
                        rules={[
                            {
                                required: true,
                                message: '请输入版本号',
                            },
                        ]}
                    >
                        <Input placeholder="版本号信息，如：1.0.1" />
                    </Form.Item>

                    <Form.Item
                        label="日期"
                        name="date"
                        rules={[
                            {
                                required: true,
                                message: '请选择日期',
                            },
                        ]}
                    >
                        <DatePicker />
                    </Form.Item>

                    <Form.Item
                        label="时间"
                        name="time"
                        rules={[
                            {
                                required: true,
                                message: '请选择时间',
                            },
                        ]}
                    >
                        <TimePicker />
                    </Form.Item>

                    <Form.Item
                        wrapperCol={{
                            offset: 8,
                            span: 16,
                        }}
                    >
                        {
                            uploading ? (
                                <Button type="primary" loading>
                                    Uploading
                                </Button>
                            ) : (
                                <Button type="primary" htmlType="submit">
                                    添加
                                </Button>
                            )
                        }
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default VersionUpdateModal
