import styles from './index.module.css'
import CategorizeGenerateManagementUpdate from '../CategorizeGenerateManagementUpdate'

function CategorizeGenerateManagement() {
    return (
        <div className={styles.main}>
            <div className={styles.header}>
                <div className={styles.title}>
                    用户画像的生成
                </div>
            </div>

            <div className={styles.container}>
                <div className={styles.container_top}>
                    <CategorizeGenerateManagementUpdate />
                </div>
                <div className={styles.container_bottom}>

                </div>
            </div>
        </div>
    )
}

export default CategorizeGenerateManagement