import React, { Component, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Input, Button, Form, message, Checkbox, Select } from 'antd';

import styles from './index.module.css'
import { actionCreators } from '../../store';
import { findItemByProperty, arraysAreEqual } from '../../../../utils'

function KnowledgeBaseEditModal({isModalOpen, handleCloseModal, editComponentType}) {
    const dispatch = useDispatch()
    const [creating, setCreating] = useState(false)
    const [form] = Form.useForm();
    let knowledge_base_list = useSelector(state => state.get('admin').get('knowledge_base_list'))
    let edit_knowledge_base_name = useSelector(state => state.get('admin').get('edit_knowledge_base_name'))
    let knowledge_base_menu_immutable = useSelector(state => state.get('admin').get('knowledge_base_menu'))
    let knowledge_base_menu = useSelector(state => state.get('admin').get('knowledge_base_menu')).toJS()
    const [pictureComponents, setPictureComponents] = useState([])
    const [documentComponents, setDocumentComponents] = useState([])

    useEffect(() => {
        let obj = findItemByProperty(knowledge_base_menu, 'key', 'picture_kb')
        if (obj) {
            let picture_components = obj.children.map((item) => {
                return {
                    label: item.label,
                    value: item.label,
                }
            })
            setPictureComponents(picture_components)
        }
    }, [knowledge_base_menu_immutable])

    useEffect(() => {
        let obj = findItemByProperty(knowledge_base_menu, 'key', 'document_kb')
        console.log("useEffect obj = ", obj);
        if (obj) {
            let document_components = obj.children.map((item) => {
                return {
                    label: item.label,
                    value: item.label,
                }
            })
            console.log("document_components = ", document_components);
            setDocumentComponents(document_components)
        }
    }, [knowledge_base_menu_immutable])

    useEffect(() => {
        if (isModalOpen) {
            let list = knowledge_base_list.toJS()
            let obj = list.find(item => item.name === edit_knowledge_base_name) || {}
            console.log("knowledge_base_list = ", list);
            console.log("obj = ", obj);
            console.log("useMemo edit_knowledge_base_name = ", edit_knowledge_base_name);
            form.setFieldsValue({
                model_name: edit_knowledge_base_name,
                overview: obj.overview || '',
                description: obj.description || '',
                bind_components: obj.attached_components || []
            })
        }
    }, [isModalOpen, knowledge_base_list, edit_knowledge_base_name, form])

    const onFinish = (values) => {
        console.log('Success:', values);
        let overview = values.overview.trim()
        let description = values.description.trim()
        let bind_components = values.bind_components

        if (overview === '') {
            message.info("模型描述信息不能为空")
        } else if (description === '') {
            message.info("给后端模型使用的描述信息不能为空")
        } else {
            // dispatch(actionCreators.updateKnowledgeBase(edit_knowledge_base_name, new_model_name, overview, description))
            let list = knowledge_base_list.toJS()
            let obj = list.find(item => item.name === edit_knowledge_base_name) || {}
            let data = {
                "name": edit_knowledge_base_name,
            }
            if (obj.overview !== overview) {
                data['overview'] = overview
            }

            if (obj.description !== description) {
                data['description'] = description
            }

            if (!arraysAreEqual(obj.attached_components, bind_components)) {
                data['attached_component_names'] = bind_components
            }

            console.log("data = ", data);
            if (Object.keys(data).length >= 2) {
                setCreating(true)
                dispatch(actionCreators.updateKnowledgeBase(data))
                .then(data => { handleCloseModalLocal() })
                    .catch(error => {})
                    .finally(() => {setCreating(false)})
            }
        }
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    }

    const handleCloseModalLocal = () => {
        form.resetFields();
        handleCloseModal()
    }

    return (
        <>
            <Modal
                title="编辑组件"
                open={isModalOpen}
                onOk={handleCloseModalLocal}
                onCancel={handleCloseModalLocal}
                // okText="确认"
                // cancelText="取消"
                footer={false}
                destroyOnClose={true}
            >
                <Form
                    name="basic"
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 16,
                    }}
                    style={{
                        maxWidth: 600,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    preserve={false}
                    form={form}
                    // initialValues={{
                    //     model_name: edit_knowledge_base_name,
                    //     overview: current_knowledge_base_obj.overview || '',
                    //     description: current_knowledge_base_obj.description || '',
                    // }}
                >
                    <Form.Item
                        label="组件名称"
                        name="model_name"
                    >
                        <span>{ edit_knowledge_base_name }</span>
                    </Form.Item>

                    <Form.Item
                        label="模型的展示描述"
                        name="overview"
                        rules={[{ required: true, message: '请输入模型的展示描述' }]}
                    >
                        <Input.TextArea
                            // showCount
                            // maxLength={100}
                            style={{
                                resize: 'none',
                                width: '100%',
                                height: 100,
                            }}
                            onChange={(e) => {console.log('Change:', e.target.value)}}
                            placeholder="展示给用户的模型描述信息"
                        />
                    </Form.Item>

                    <Form.Item
                        label="模型的代理描述"
                        name="description"
                        rules={[{ required: true, message: '请输入给后端代理模型的描述信息' }]}
                    >
                        <Input.TextArea
                            // showCount
                            // maxLength={100}
                            style={{
                                resize: 'none',
                                width: '100%',
                                height: 100,
                            }}
                            onChange={(e) => {console.log('Change:', e.target.value)}}
                            placeholder="给后端代理模型的描述信息（灵石据此选择合适的专家模型）"
                        />
                    </Form.Item>

                    {
                        editComponentType === 'document' ? (
                            <Form.Item
                                label="绑定图片组件"
                                name="bind_components"
                                // rules={[{ message: '请选择绑定图片组件' }]}
                            >
                                <Select
                                    mode="multiple"
                                    onChange={(e) => { console.log("选择的数据为 ", e); }}
                                    options={pictureComponents}
                                />
                            </Form.Item>
                        ) : (
                            <Form.Item
                                label="绑定文本组件"
                                name="bind_components"
                                // rules={[{ message: '请选择绑定图片组件' }]}
                            >
                                <Select
                                    mode="multiple"
                                    onChange={(e) => { console.log("选择的数据为 ", e); }}
                                    options={documentComponents}
                                />
                            </Form.Item>
                        )
                    }

                    <Form.Item
                        wrapperCol={{
                            offset: 8,
                            span: 16,
                        }}
                    >
                        {
                            creating ? (
                                <Button type="primary" loading>
                                    保存中
                                </Button>
                            ) : (
                                <Button type="primary" htmlType="submit">
                                    保存
                                </Button>
                            )
                        }
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default KnowledgeBaseEditModal