import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


const MyTextEditor = ({editorText, setEditorText}) => {
    // const [editorText, setEditorText] = useState('')

    const modules = {
        toolbar: {
            container: [
                [{ 'header': [1, 2, false] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                ['image'],
            ],
        }
    }

    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'image'
    ]

    return (
        <div className="text-editor">
            <ReactQuill
                theme="snow"
                value={editorText}
                onChange={setEditorText}
                modules={modules}
                formats={formats}
                style={{height: '200px'}}
            />
        </div>
    )
}

export default MyTextEditor