import styles from './index.module.css'
import CategorizeSettingManagementContent from '../CategorizeSettingManagementContent'

function CategorizeSettingManagement() {
    return (
        <div className={styles.main}>
            <div className={styles.header}>
                <div className={styles.title}>
                    分类项的管理
                </div>
            </div>

            <div className={styles.container}>
                <CategorizeSettingManagementContent />
            </div>
        </div>
    )
}

export default CategorizeSettingManagement