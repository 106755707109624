import React, { Component, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Input, Button, Form, message, Checkbox } from 'antd';

import styles from './index.module.css'

function CategorizeSettingAddKeywordModal({isModalOpen, setIsModalOpen, similarData, setSimilarData, setIsSimilarChange}) {
    const dispatch = useDispatch()
    const [creating, setCreating] = useState(false)
    const [form] = Form.useForm();

    const onFinish = (values) => {
        console.log('Success:', values);
        let new_keyword = values.new_keyword.trim()
        if (new_keyword === '') {
            message.warning("关键词名称不能为空！")
        } else if (similarData.find(item => item.name === new_keyword)) {
            message.warning("关键词名称已存在！")
        } else {
            let newElement = {
                name: new_keyword,
                value: new_keyword,
            }
            setSimilarData(prevData => [newElement, ...prevData]);
            setIsModalOpen(false)
            setIsSimilarChange(true)
        }
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    }

    const handleCloseModalLocal = () => {
        form.resetFields();
        setIsModalOpen(false)
    }

    return (
        <>
            <Modal
                title="添加新的关键词"
                open={isModalOpen}
                onOk={handleCloseModalLocal}
                onCancel={handleCloseModalLocal}
                // okText="确认"
                // cancelText="取消"
                footer={false}
                destroyOnClose={true}
            >
                <Form
                    name="basic"
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 16,
                    }}
                    style={{
                        maxWidth: 600,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    preserve={false}
                    form={form}
                >
                    <Form.Item
                        label="新的关键词名称"
                        name="new_keyword"
                        rules={[
                            {
                                required: true,
                                message: '请输入新的关键词名称',
                            },
                        ]}
                    >
                        <Input placeholder="请输入新的关键词名称" />
                    </Form.Item>

                    <Form.Item
                        wrapperCol={{
                            offset: 8,
                            span: 16,
                        }}
                    >
                        {
                            creating ? (
                                <Button type="primary" loading>
                                    添加中
                                </Button>
                            ) : (
                                <Button type="primary" htmlType="submit">
                                    添加
                                </Button>
                            )
                        }
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default CategorizeSettingAddKeywordModal