import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Radio, Input } from 'antd';

import styles from './index.module.css'
import { actionCreators } from '../../store'
import menuSearchIcon from '../../../../statics/menu_search_icon.png'


class FilterBox extends Component {
    render() {
        let filter_category_list = this.props.filter_category_list.toJS()

        return (
            <div className={styles.container}>
                <div className={styles.section}>
                    <div className={styles.section_box}>
                        {
                            filter_category_list.map((item) => {
                                return (
                                    <div
                                        key={item.value}
                                        className={`${styles.section_butn} ${item.active ? styles.section_butn_active : ''}`}
                                        onClick={() => {this.handleSelectFilter(item.value)}}
                                    >
                                        {item.label}
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

                <div className={styles.search_box}>
                    <input
                        type={"text"}
                        placeholder={'搜索'}
                        className={styles.search}
                        // onChange={this.onSearchChange.bind(this)}
                        // value={this.props.menu_search_content}
                    />
                    <img src={menuSearchIcon} className={styles.search_icon} />
                </div>
            </div>
        )
    }

    handleSelectFilter(value) {
        let filter_category_list = this.props.filter_category_list.toJS()
        filter_category_list.forEach(element => {
            if (element.value === value) {
                element.active = true
            } else {
                element.active = false
            }
        })

        this.props.handleFilterCategoryListAction(filter_category_list)
    }
}

const mapStateToProps = (state) => {
    return {
        filter_butn: state.get('collect').get('filter_butn'),
        filter_category_list: state.get('collect').get('filter_category_list'),
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleFilterButnAction(value) {
            dispatch(actionCreators.filterButnAction(value))
        },
        handleFilterCategoryListAction(data) {
            dispatch(actionCreators.filterCategoryListAction(data))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterBox)