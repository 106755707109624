import React, { useContext, useState, useEffect } from 'react';
import { Tooltip } from 'antd'

import styles from './index.module.css'
import { ServiceWorkerContext } from '../../../../ServiceWorkerContext';

const AddToHomeScreenButton = () => {
    let deferredPrompt = useContext(ServiceWorkerContext);
    // const [deferredPrompt, setDeferredPrompt] = useState(null);

    // useEffect(() => {
    //     const handler = (e) => {
    //         console.log('beforeinstallprompt event was fired'); // 添加这行来打印日志
    //         e.preventDefault();
    //         setDeferredPrompt(e);
    //     };

    //     window.addEventListener('beforeinstallprompt', handler);

    //     return () => {
    //         window.removeEventListener('beforeinstallprompt', handler);
    //     };
    // }, []);

    const handleAddToHomeScreen = () => {
        if (deferredPrompt) {
            deferredPrompt.prompt(); // 显示安装提示
            deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the A2HS prompt');
                } else {
                    console.log('User dismissed the A2HS prompt');
                }
                // 不再需要保存该事件
                deferredPrompt = null;
            });
        }
    };

    return (
        <Tooltip placement="left" title={"添加到桌面"}>
            <div
                className={styles.add_to_desktop}
                onClick={handleAddToHomeScreen}
            >

            </div>
        </Tooltip>
    );
};

export default AddToHomeScreenButton;