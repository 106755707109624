import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Modal, Input, message } from 'antd'

import { actionCreators as homeActionCreators } from '../../pages/home/store'
import styles from './index.module.css'
import wearyFace from '../../statics/emoji_weary_face.png'
import frowningFace from '../../statics/emoji_slightly_frowning_face.png'
import smilingFace from '../../statics/emoji_slightly_smiling_face.png'
import grinningFace from '../../statics/emoji_grinning_face.png'
import heartEyesFace from '../../statics/emoji_smiling_face_with_heart_eyes.png'
import emojiHookImg from '../../statics/emoji_hook.png'
import MyButton from '../../common/MyButton'


class ModalFeedback extends Component {
    handleOk() {
        this.props.handleIsFeedbackModalOpenAction(false)
    }

    handleCancel() {
        this.props.handleIsFeedbackModalOpenAction(false)
        this.props.handleFeedbackScoreAction(0)
        this.props.handleFeedbackContentAction("")
    }

    render() {
        // 创建一个映射表情名称到图标路径的对象
        const iconMap = {
            wearyFace: wearyFace,
            frowningFace: frowningFace,
            smilingFace: smilingFace,
            grinningFace: grinningFace,
            heartEyesFace: heartEyesFace,
        }

        let feedback_face_list = this.props.feedback_face_list.toJS()

        return (
            <Modal
                open={this.props.isFeedbackModalOpen}
                onOk={this.handleOk.bind(this)}
                onCancel={this.handleCancel.bind(this)}
                footer={null}
                width={560}
                className={styles.modal_container}
                // closeIcon={
                //     <div className={styles.close_butn}></div>
                // }
            >
                <div className={styles.main}>
                    <div className={styles.header}>
                        <div className={styles.header_title}>您的许愿池</div>
                        <div className={styles.header_explain}>
                            我们希望听到您的愿望，以改进我们的网站，让灵石更好地为您解决问题， 我们承诺你的愿望会在72小时内得到回复， 在未来成为现实！
                        </div>
                    </div>

                    <div className={styles.section}>
                        <div className={styles.section_title}>您对灵石的体验感觉如何？</div>
                        <div className={styles.feel_butn_box}>
                            {
                                feedback_face_list.map((item) => {
                                    // 直接从映射中获取图标路径
                                    const icon = iconMap[item.name] ? iconMap[item.name] : iconMap['smilingFace']

                                    return (
                                        <div
                                            className={`${styles.feel_butn} ${this.props.feedback_score === item.score ? styles.selected : ''}`}
                                            onClick={() => { this.handleSelectScore(item.score) }}
                                            key={item.name}
                                        >
                                            <img src={icon} className={styles.feel_butn_img} />
                                            {
                                                this.props.feedback_score === item.score ? (
                                                    <img src={emojiHookImg} className={styles.hook_img} />
                                                ) : ''
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>

                    <div className={styles.section2}>
                        <div className={styles.section2_title}>请写下您的愿望：</div>
                        <Input.TextArea
                            style={{
                                resize: 'none',
                                width: '100%',
                                height: 150,
                            }}
                            placeholder="请在这里写下您的愿望..."
                            onChange={this.onTextareaChange.bind(this)}
                            value={this.props.feedback_content}
                            className={styles.custom_textarea}
                        >

                        </Input.TextArea>
                    </div>

                    <div className={styles.footer}>
                        <MyButton
                            width={'100%'}
                            height={'48px'}
                            onClick={this.handleSubmitWish.bind(this)}
                            text="提交愿望"
                            type="primary"
                        />
                    </div>
                </div>
          </Modal>
        )
    }

    handleSelectScore(value) {
        this.props.handleFeedbackScoreAction(value)
    }

    onTextareaChange(e) {
        console.log('Change:', e.target.value)
        let value = e.target.value
        this.props.handleFeedbackContentAction(value)
    }

    handleSubmitWish() {
        let feedback_score = this.props.feedback_score
        let feedback_content = this.props.feedback_content.trim()

        if (feedback_score === 0) {
            message.info("请先选中评分")
            return
        }

        if (feedback_content === '') {
            message.info("请输入要反馈的信息")
            return
        }

        this.props.handlePostFeedbackAction(feedback_score, feedback_content)
    }
}

const mapStateToProps = (state) => {
    return {
        isFeedbackModalOpen: state.get('home').get('isFeedbackModalOpen'),
        feedback_content: state.get('home').get('feedback_content'),
        feedback_score: state.get('home').get('feedback_score'),
        feedback_face_list: state.get('home').get('feedback_face_list'),
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleIsFeedbackModalOpenAction(status) {
            dispatch(homeActionCreators.isFeedbackModalOpenAction(status))
        },
        handleFeedbackContentAction(value) {
            dispatch(homeActionCreators.feedbackContentAction(value))
        },
        handleFeedbackScoreAction(value) {
            dispatch(homeActionCreators.feedbackScoreAction(value))
        },
        handlePostFeedbackAction(feedback_score, feedback_content) {
            dispatch(homeActionCreators.postFeedbackAction(feedback_score, feedback_content))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalFeedback)