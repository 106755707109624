import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Modal, Button, Form, message, Upload, Table, Space, Input, InputNumber, Checkbox, } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import styles from './index.module.css'
import { actionCreators } from '../../store'
import MyBannerCarousel from '../../../../common/MyBannerCarousel'


const BannerImageUploadModal = ({ isModalOpen, setIsModalOpen, handleGetBannerImagesList }) => {
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const [uploading, setUploading] = useState(false)
    const [fileList, setFileList] = useState([])

    // 在文件选择后更新 fileList，但不执行上传操作
    const beforeUpload = (file) => {
        setFileList((prevFileList) => [...prevFileList, file]);
        return false; // 返回 false 以停止自动上传
    }

    // 移除文件
    const handleRemove = (file) => {
        setFileList((prevFileList) => prevFileList.filter(item => item.uid !== file.uid));
        return true
    }

    const handleClose = () => {
        setIsModalOpen(false)
        setFileList([])
    }

    const onFinish = (values) => {
        console.log('Success:', values);

        if (fileList.length === 0) {
            message.info("请先选择要上传的文件！")
        } else {
            setUploading(true)
            let formData = new FormData();
            fileList.map((item) => {
                formData.append('images', item)
            })

            dispatch(actionCreators.uploadAdminBannerImages(formData))
                .then(data => {
                    handleClose();
                    handleGetBannerImagesList();
                })
                .catch(error => {})
                .finally(() => { setUploading(false); })
        }
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    }

    return (
        <>
            <Modal
                title="添加新的 banner 图片"
                open={isModalOpen}
                onOk={() => {handleClose()}}
                onCancel={() => {handleClose()}}
                // okText="确认"
                // cancelText="取消"
                footer={false}
                destroyOnClose={true}
            >
                <Form
                    name="basic"
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 16,
                    }}
                    style={{
                        maxWidth: 600,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    form={form}
                    preserve={false}
                >
                    <Form.Item
                        label="上传文件"
                        name="upload_files"
                    >
                        <Upload.Dragger
                            fileList={fileList}
                            beforeUpload={beforeUpload}
                            onRemove={handleRemove}
                            multiple={true}
                            accept={"image/*"}
                        >
                            <p className="ant-upload-drag-icon">
                                <UploadOutlined />
                            </p>
                            <p className={`ant-upload-text ${styles.notice}`}>
                                建议尺寸：【宽 1145 px】【高 133 px】
                            </p>
                            <p className="ant-upload-text">
                                支持多个图片同时上传
                            </p>
                            <p className="ant-upload-text">
                                点击或拖拽图片到此区域上传文件
                            </p>
                        </Upload.Dragger>
                    </Form.Item>

                    <Form.Item
                        wrapperCol={{
                            offset: 8,
                            span: 16,
                        }}
                    >
                        {
                            uploading ? (
                                <Button type="primary" loading>
                                    添加中...
                                </Button>
                            ) : (
                                <Button type="primary" htmlType="submit">
                                    添加
                                </Button>
                            )
                        }
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

const BannerImageTable = ({bannerList, setCurrentItem, setIsEditModalOpen, handleGetBannerImagesList, handleGetAdminBannerImagesPreview}) => {
    const dispatch = useDispatch()

    const handleShowImage = (id) => {
        dispatch(actionCreators.showVersionUpdateImageId(id))
        dispatch(actionCreators.isShowVersionUpdateImage(true))
    }

    const handleEdit = (id) => {
        for (let item of bannerList) {
            if (item.id === id) {
                setCurrentItem(item)
                break;
            }
        }
        setIsEditModalOpen(true)
    }

    const handleDelete = (id) => {
        Modal.confirm({
            title: '删除图片',
            content: `请确认要删除当前的图片`,
            okText: '确认',
            cancelText: '取消',
            onOk() {
                console.log('OK');
                let a = [id]
                dispatch(actionCreators.deleteAdminBannerImages(a))
                .then(() => {
                    message.success("确认成功！")
                    handleGetBannerImagesList()
                    handleGetAdminBannerImagesPreview()
                })
                .catch(() => {message.error("删除失败，请联系管理员！")})
                .finally(() => {})
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    };

    const columns = [
        {
            title: '图片的描述文字',
            dataIndex: 'caption',
            key: 'caption',
        },
        {
            title: '优先级',
            dataIndex: 'order',
            key: 'order',
        },
        {
            title: '激活状态',
            render: (_, record) => (
                record.is_active ? '已激活' : '未激活'
            ),
            key: 'id',
        },
        {
            title: '操作',
            // dataIndex: 'gender',
            key: 'id',
            render: (_, record) => (
                <Space size="middle">
                    <Button onClick={() => {handleEdit(record.id)}}>编辑</Button>
                    <Button onClick={() => {handleDelete(record.id)}} danger>删除</Button>
                </Space>
            ),
        },
    ];

    return (
        <Table
            columns={columns}
            dataSource={bannerList}
            onChange={onChange}
        />
    )
}

function BannerImageItemEditModal({ isModalOpen, setIsModalOpen, currentItem, handleGetBannerImagesList, handleGetAdminBannerImagesPreview }) {
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const [uploading, setUploading] = useState(false)
    const [isActive, setIsActive] = useState(true)

    const handleClose = () => {
        setIsModalOpen(false)
    }

    useEffect(() => {
        console.log("BannerImageItemEditModal currentItem = ", currentItem);
        if (isModalOpen && Object.keys(currentItem).length !== 0) {
            form.setFieldsValue({
                caption: currentItem.caption,
                order: currentItem.order,
            })
            setIsActive(currentItem.is_active)
        }
    }, [isModalOpen, form])

    const onFinish = (values) => {
        console.log('Success:', values);
        let caption = values.caption.trim()
        let order = values.order

        if (caption === '') {
            message.info('图片描述不能为空！');
        } else {
            let obj_data = {}
            if (caption !== currentItem.caption) {
                obj_data['caption'] = caption
            }

            if (order !== currentItem.order) {
                obj_data['order'] = order
            }

            if (isActive !== currentItem.is_active) {
                obj_data['is_active'] = isActive
            }

            if (Object.keys(obj_data).length === 0) {
                message.info("没有改动！")
            } else {
                setUploading(true)
                obj_data['id'] = currentItem.id
                let result_data = {
                    "banners": [obj_data]
                }

                dispatch(actionCreators.updateAdminBannerImages(JSON.stringify(result_data)))
                    .then(data => {
                        handleClose();
                        handleGetBannerImagesList()
                        handleGetAdminBannerImagesPreview()
                    })
                    .catch(error => {})
                    .finally(() => { setUploading(false) })
            }
        }
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    }

    return (
        <>
            <Modal
                title="修改图片相关信息"
                open={isModalOpen}
                onOk={() => {handleClose()}}
                onCancel={() => {handleClose()}}
                // okText="确认"
                // cancelText="取消"
                footer={false}
                destroyOnClose={true}
            >
                <Form
                    name="basic"
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 16,
                    }}
                    style={{
                        maxWidth: 600,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    form={form}
                    preserve={false}
                >
                    <Form.Item
                        label="图片描述"
                        name="caption"
                        rules={[
                            {
                                required: true,
                                message: '请输入图片描述',
                            },
                        ]}
                    >
                        <Input placeholder="请输入图片描述" />
                    </Form.Item>

                    <Form.Item
                        label="排列优先级"
                        name="order"
                        rules={[
                            {
                                required: true,
                                message: '请输入排列优先级数字',
                            },
                        ]}
                    >
                        <InputNumber
                            min={0}
                            max={100}
                        />
                    </Form.Item>

                    <Form.Item
                        label="是否激活"
                        name="is_active"
                    >
                        <Checkbox
                            checked={isActive}
                            onChange={() => {setIsActive(!isActive)}}
                        >
                        </Checkbox>
                    </Form.Item>

                    <Form.Item
                        wrapperCol={{
                            offset: 8,
                            span: 16,
                        }}
                    >
                        {
                            uploading ? (
                                <Button type="primary" loading>
                                    修改中...
                                </Button>
                            ) : (
                                <Button type="primary" htmlType="submit">
                                    修改
                                </Button>
                            )
                        }
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

function BannerRelated() {
    const dispatch = useDispatch()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isEditModalOpen, setIsEditModalOpen] = useState(false)
    const [bannerList, setBannerList] = useState([])
    const [currentItem, setCurrentItem] = useState({})
    const [previewList, setPreviewList] = useState([])

    useEffect(() => {
        handleGetBannerImagesList()
        handleGetAdminBannerImagesPreview()
    }, [])

    const handleGetBannerImagesList = () => {
        dispatch(actionCreators.getAdminBannerImagesList())
            .then((data) => {setBannerList(data.data)})
            .catch((error) => {console.log(error);})
            .finally(() => {})
    }

    const handleGetAdminBannerImagesPreview = () => {
        dispatch(actionCreators.getAdminBannerImagesPreview())
            .then((data) => {setPreviewList(data.data)})
            .catch((error) => {console.log(error);})
            .finally(() => {})
    }

    const handlePublish = () => {
        dispatch(actionCreators.getAdminBannerImagesPublish())
        .then((data) => {message.success("发布成功！")})
        .catch(() => {})
        .finally(() => {})
    }

    return (
        <div className={styles.main}>
            <div className={styles.header}>
                <div className={styles.title}>
                    Banner 相关
                </div>
            </div>

            <div className={styles.container}>
                <div className={styles.add_box}>
                    <Button
                        type="primary"
                        onClick={() => {setIsModalOpen(true)}}
                    >
                        添加新的 banner 图片
                    </Button>
                </div>

                <div className={styles.table_box}>
                    <BannerImageTable
                        bannerList={bannerList}
                        setCurrentItem={setCurrentItem}
                        setIsEditModalOpen={setIsEditModalOpen}
                        handleGetBannerImagesList={handleGetBannerImagesList}
                        handleGetAdminBannerImagesPreview={handleGetAdminBannerImagesPreview}
                    />
                </div>

                <div className={styles.preview_box}>
                    <div className={styles.preview_title}>
                        预览效果
                    </div>
                    <div className={styles.preview_box_banner}>
                        <MyBannerCarousel data={previewList} />
                    </div>
                    <div className={styles.publish_box}>
                        <Button
                            type="primary"
                            onClick={handlePublish}
                        >
                            发布
                        </Button>
                    </div>
                </div>
            </div>

            <BannerImageUploadModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                handleGetBannerImagesList={handleGetBannerImagesList}
            />
            <BannerImageItemEditModal
                isModalOpen={isEditModalOpen}
                setIsModalOpen={setIsEditModalOpen}
                currentItem={currentItem}
                handleGetBannerImagesList={handleGetBannerImagesList}
                handleGetAdminBannerImagesPreview={handleGetAdminBannerImagesPreview}
            />
        </div>
    )
}

export default BannerRelated