import React from 'react';
import { Table, Space } from 'antd';
import { connect, useDispatch, useSelector } from 'react-redux'
import { actionCreators } from '../../store'


const PersonalConversationTable = () => {
    const columns = [
        {
            title: '提问',
            dataIndex: 'query',
            key: 'query',
        },
        {
            title: '回答',
            dataIndex: 'response',
            key: 'query',
        },
        {
            title: '提问时间',
            dataIndex: 'query_at',
            key: 'query_at',
            sorter: {
                compare: (a, b) => {
                    if (a.query_at > b.query_at) {
                        return 1
                    } else if (a.query_at < b.query_at) {
                        return -1
                    } else {
                        return 0
                    }
                },
            },
        },
        {
            title: '问答的模型',
            dataIndex: 'chat_type',
            key: 'chat_type',
        },
        // {
        //     title: '查看个人的对话信息',
        //     render: (_, record) => {
        //         return (
        //             <Space size="middle">
        //                 <a onClick={() => {onClick(record.uuid)}}>查看</a>
        //             </Space>
        //         )
        //     },
        //     key: 'uuid',
        // }
    ];

    const dispatch = useDispatch()
    let personal_conversation_data = useSelector(state => state.get('admin').get('personal_conversation_data')).toJS()

    return (
        <Table
            columns={columns}
            dataSource={personal_conversation_data}
        />
    )
}

export default PersonalConversationTable