import React, { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Tooltip, Popover } from 'antd'
import { format, parseISO, addHours } from 'date-fns';

import styles from './index.module.css'
import { actionCreators } from '../../store'
import { actionCreators as homeActionCreators } from '../../../home/store'
import { generateOptionsByChatData, chatTypeIcons, } from '../../../../utils'
import CollectItemInput from '../CollectItemInput'

import openVector from '../../../../statics/collect_open_vector.png'
import closeVector from '../../../../statics/collect_close_vector.png'
import dragVector from '../../../../statics/collect_drag_vector.png'
import messageVector from '../../../../statics/collect_message_vector.png'
import conversationImg from '../../../../statics/collect_text_conversation.png'
import writeImg from '../../../../statics/collect_text_write.png'
import markImg from '../../../../statics/collect_popover_mark.png'
import editImg from '../../../../statics/collect_popover_edit.png'


const CustomPopover = (props) => {
    const [open, setOpen] = useState(false)
    let conversation = props.conversation

    const popoverOpenChange = (open) => {
        setOpen(open)
    };

    const hidePopover = () => {
        setOpen(false)
    }

    const handleItemCancelMark = () => {
        console.log("handleItemCancelMark")
        hidePopover()
        props.handleItemCancelMark(props.ChatDataDate, conversation.topic_id, conversation.conversation_id)
    }

    const handleItemRename = () => {
        hidePopover()
        props.handleItemRename(props.ChatDataDate, conversation.topic_id)
    }

    let content = (
        <div className={styles.popover_box}>
            <div
                className={styles.popover_item}
                onClick={(e) => { e.preventDefault(); e.stopPropagation(); handleItemRename() }}
            >
                <img src={editImg} className={styles.popover_butn_img} />
                <span className={styles.popover_item_text}>重命名</span>
            </div>

            <div
                className={styles.popover_item}
                onClick={(e) => { e.preventDefault(); e.stopPropagation(); handleItemCancelMark()}}
            >
                <img src={markImg} className={styles.popover_butn_img} />
                <span className={styles.popover_item_text}>移除星标</span>
            </div>
        </div>
    )

    return (
        <Popover
            overlayClassName='custom_popover_collect'
            content={content}
            placement="right"
            trigger="click"
            open={open}
            onOpenChange={(e) => {popoverOpenChange(e)}}
        >
            <Tooltip placement="top" title={"更多"}>
                <div className={styles.tool_more}></div>
            </Tooltip>
        </Popover>
    )
}

const ListItem = (props) => {
    const [open, setOpen] = useState(false)
    const [isHovering, setIsHovering] = useState(false)
    let conversation = props.conversation

    const handleEnterConversation = () => {
        props.handleEnterConversation(props.ChatDataDate, conversation.topic_id)
    }

    const handleDeleteConversation = () => {
        props.handleDeleteConversation(props.ChatDataDate, conversation.topic_id, conversation.conversation_id)
    }

    const convertToBeijingTime = (backendDate) => {
        // 将ISO格式的时间字符串解析为日期对象
        const parsedDate = parseISO(backendDate);

        // 将时间增加8小时，转换为东八区时间
        const beijingDate = addHours(parsedDate, 8);

        return beijingDate;
    }

    const formatCreatedTime = (backendDate) => {
        try {
            const beijingDate = convertToBeijingTime(backendDate);

            if (props.ChatDataDate === 'today') {
                const time24Hour = format(beijingDate, 'HH:mm');
                const amPm = format(beijingDate, 'a').toUpperCase();
                return `${time24Hour} ${amPm}`;
            } else {
                return format(beijingDate, 'dd MMM yyyy')
            }
        } catch {
            return '------'
        }
    }

    return (
        <div
            className={`${styles.list_item} ${open ? styles.list_item_open : ''}`}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
        >
            <div className={styles.item_top}>
                <div className={styles.vector_box}>
                    {/* <img src={dragVector} className={styles.vector_drag} /> */}
                    <img src={messageVector} className={styles.vector_message} />
                </div>

                <div className={styles.abstract_box}>
                    {
                        conversation.is_edit_name ? (
                            <CollectItemInput
                                ChatDataDate={props.ChatDataDate}
                                CurrentTopicid={conversation.topic_id}
                                ConversationNameEdit={conversation.conversation_name_edit}
                            />
                        ) : (
                            <div className={styles.abstract}>
                                { conversation.conversation_name }
                            </div>
                        )
                    }
                </div>

                <div className={styles.tool_box}>
                    {
                        isHovering ? (
                            <>
                                <Tooltip placement="top" title={"进入对话"}>
                                    <div
                                        className={styles.tool_enter}
                                        onClick={handleEnterConversation}
                                    >
                                    </div>
                                </Tooltip>
                                <Tooltip placement="top" title={"删除对话"}>
                                    <div
                                        className={styles.tool_delete}
                                        onClick={handleDeleteConversation}
                                    >
                                    </div>
                                </Tooltip>
                                <CustomPopover
                                    conversation={props.conversation}
                                    ChatDataDate={props.ChatDataDate}
                                    handleItemRename={props.handleItemRename}
                                    handleItemCancelMark={props.handleItemCancelMark}
                                />
                            </>
                        ) : (
                            <div className={styles.time}>{ `创建于 ${formatCreatedTime(conversation.created_at)}` }</div>
                        )
                    }
                </div>

                <div className={styles.vector_open_box} onClick={() => setOpen(!open)}>
                    {
                        open ? (
                            <img src={openVector} className={styles.vector_open} />
                        ) : (
                            <img src={closeVector} className={styles.vector_open} />
                        )
                    }
                </div>
            </div>

            <div className={styles.item_bottom}>
                <div className={styles.model_box}>
                    {/* <img src={obj?.avatar} className={styles.model_img} />
                    <div className={styles.model_name}>
                        { obj?.label }
                    </div> */}
                    <img src={chatTypeIcons['agent_chat']} className={styles.model_img} />
                    <div className={styles.model_name}>
                        { "我的灵石" }
                    </div>
                </div>

                <div className={styles.num_box}>
                    <div className={styles.text_box}>
                        <div className={styles.text_title}>总共对话</div>
                        <img src={conversationImg} className={styles.text_img} />
                        <div className={styles.num}>{ conversation.data.length }</div>
                    </div>

                    <div className={styles.text_box}>
                        <div className={styles.text_title}>AI修改</div>
                        <img src={writeImg} className={styles.text_img} />
                        <div className={styles.num}>0</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

class ShowBox extends Component {
    render() {
        let filter_category_list = this.props.filter_category_list.toJS()
        let all_chat_data = this.props.all_chat_data.toJS()
        let list = generateOptionsByChatData(filter_category_list, all_chat_data)
        let count_item = 0

        return (
            <div className={styles.container}>
                <div className={styles.main}>
                    {
                        list.map((item, index) => {
                            if (item.data.length !== 0) {
                                count_item += 1;

                                return (
                                    <div className={styles.filter_box} key={item.value}>
                                        <div className={styles.filter_title}>{item.value}</div>
                                        {
                                            count_item === 1 ? (
                                                <div className={styles.filter_hint}>请点击对话进入修改</div>
                                            ) : null
                                        }
                                        <div className={styles.list_box}>
                                            {
                                                item.data.map((child_item) => {
                                                    return (
                                                        <div
                                                            key={child_item.topic_id}
                                                            className={styles.list_item_box}
                                                        >
                                                            <ListItem
                                                                handleEnterConversation={this.handleEnterConversation.bind(this)}
                                                                handleDeleteConversation={this.handleDeleteConversation.bind(this)}
                                                                handleItemRename={this.handleItemRename.bind(this)}
                                                                handleItemCancelMark={this.handleItemCancelMark.bind(this)}
                                                                conversation={child_item}
                                                                ChatDataDate={item.data_date}
                                                            />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            }
                        })
                    }
                </div>
            </div>
        )
    }

    handleEnterConversation(active_date, active_topicid) {
        this.props.handleIsShowScreenPersonalOverview(false)
        this.props.handleIsShowScreenStoneModelMall(false)
        this.props.handleIsShowScreenPersonalBackpack(false)
        this.props.handleActiveTopicIdAction(active_date, active_topicid)
        this.props.history.push({pathname: '/home'})
        // this.props.handleSwitchItemAction(active_date, active_topicid)
    }

    handleDeleteConversation(active_date, active_topicid, conversation_id) {
        this.props.handleDeleteConversationAction(active_date, active_topicid, conversation_id)
    }

    handleItemRename(active_date, active_topicid) {
        this.props.handleConversationRenameAction(active_date, active_topicid)
    }

    handleItemCancelMark(active_date, active_topicid, conversation_id) {
        this.props.handleCollectConversationAction(active_date, active_topicid, '', conversation_id)
    }
}

const mapStateToProps = (state) => {
    return {
        filter_category_list: state.get('collect').get('filter_category_list'),
        all_chat_data: state.get('home').get('all_chat_data'),
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleFilterButnAction(value) {
            dispatch(actionCreators.filterButnAction(value))
        },
        handleFilterCategoryListAction(data) {
            dispatch(actionCreators.filterCategoryListAction(data))
        },
        handleActiveTopicIdAction(active_date, active_topicid) {
            dispatch(homeActionCreators.activeTopicIdAction(active_date, active_topicid))
        },
        handleDeleteConversationAction(active_date, active_topicid, conversation_id) {
            dispatch(homeActionCreators.deleteConversationAction(active_date, active_topicid, conversation_id))
        },
        handleConversationRenameAction(active_date, active_topicid) {
            dispatch(homeActionCreators.conversationRenameAction(active_date, active_topicid))
        },
        handleCollectConversationAction(active_date, active_topicid, favorites_type, conversation_id) {
            dispatch(homeActionCreators.collectConversationAction(active_date, active_topicid, favorites_type, conversation_id))
        },
        handleIsShowScreenPersonalOverview(status) {
            dispatch(homeActionCreators.isShowScreenPersonalOverview(status))
        },
        handleIsShowScreenStoneModelMall(status) {
            dispatch(homeActionCreators.isShowScreenStoneModelMall(status))
        },
        handleIsShowScreenPersonalBackpack(status) {
            dispatch(homeActionCreators.isShowScreenPersonalBackpack(status))
        },
        handleSwitchItemAction(active_date, active_topicid) {
            dispatch(homeActionCreators.switchItemAction(active_date, active_topicid))
        },
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ShowBox))