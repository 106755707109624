import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Divider } from 'antd'

import styles from './index.module.css'
import SideBarChatBox from '../SideBarChatBox'
import SideBarUserInfo from '../SideBarUserInfo'
import upgradeButnImg from '../../../../statics/upgrade_butn_img.png'


class SideBar extends Component {
    render() {
        return (
            <div className={styles.container}>
                <div className={styles.userinfo_box}>
                    <SideBarUserInfo />
                </div>

                <div className={styles.divider_box}>
                    <Divider style={{margin: "7px 0 15px 0", backgroundColor: "rgba(203, 209, 216, 0.5)"}} />
                </div>

                <div className={styles.newchat_box}>
                    <SideBarChatBox />
                </div>

                <div className={styles.upgrade_butn_container}>
                    <div className={styles.upgrade_butn_box}>
                        <div className={styles.upgrade_butn}>
                            升级成为PRO用户
                            <div className={styles.upgrade_img_box}>
                                <img src={upgradeButnImg} className={styles.upgrade_img} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {

    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SideBar)