import React, { useState, useEffect } from 'react';
import styles from './index.module.css';
import stopImg from '../../../../statics/stop_responding.png'

const MessageStopFetchResponding = () => {
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(false);
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <div className={`${styles.bubble} ${isVisible ? styles.appear : styles.disappear}`}>
            <img src={stopImg} className={styles.img} />
            <div className={styles.text}>停止响应</div>
        </div>
    );
};

export default MessageStopFetchResponding;