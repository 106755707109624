// 引入 ECharts 核心模块
import * as echarts from 'echarts/core';

// 引入饼图模块
import { PieChart } from 'echarts/charts';

// 引入需要的组件
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from 'echarts/components';

// 引入渲染器
import { CanvasRenderer } from 'echarts/renderers';

// 使用 echarts-for-react
import ReactECharts from 'echarts-for-react';
import React from 'react';

// 注册 ECharts 模块
echarts.use([
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  PieChart,
  CanvasRenderer
]);


const MyPieChart = ({title, data, onChartClick}) => {
    const option = {
        title: {
            text: title,
            // text: '用户年龄',
            // subtext: 'Fake Data',
            left: 'center'
        },
        tooltip: {
            trigger: 'item'
        },
        legend: {
            orient: 'vertical',
            left: 'left'
        },
        series: [
            {
                // name: 'Access From',
                type: 'pie',
                radius: '50%',
                // data: [
                //     { value: 1048, name: '分类1' },
                //     { value: 735, name: '分类2' },
                //     { value: 580, name: '分类3' },
                //     { value: 284, name: '分类4' },
                //     { value: 184, name: '分类5' },
                //     { value: 300, name: '分类6' }
                // ],
                data: data,
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                },
                label: {
                    show: true,
                    position: 'outside', // 你可以选择 'inside', 'outside', 'center'
                    formatter: '{b}: {c} ({d}%)', // 自定义格式
                }
            }
        ]
    }

    const onClick = () => {
        if (onChartClick) {
            console.log("已经传递进来函数");
            onChartClick()
        } else {
            console.log("没有传递进来函数");
        }
    }

    const onEvents = {
        click: (params) => {
            if (onChartClick) {
                console.log("已经传递进来函数");
                onChartClick(params); // 确保将参数传递给外层函数
            } else {
                console.log("没有传递进来函数");
            }
        },
    };

    return (
        <ReactECharts
            option={option}
            style={{ width: '100%', height: '100%' }}
            onEvents={onEvents}
        />
    )
}

export default MyPieChart