import React from 'react';
import { Table, Space, Button } from 'antd';
import { connect, useDispatch, useSelector } from 'react-redux'
import { convertUTCToLocalTime } from '../../../../utils'
import { actionCreators } from '../../store'

const onChange = (pagination, filters, sorter, extra) => {
  console.log('params', pagination, filters, sorter, extra);
};

const UserPermissionTable = () => {
    const columns = [
        {
            title: '姓名',
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: '用户名',
            render: (_, record) => (
                <span>{record.nickname ? record.nickname : ''}</span>
            ),
            key: 'nickname',
        },
        {
            title: '头像',
            dataIndex: 'avatar',
            key: 'avatar',
        },
        {
            title: '创建时间',
            render: (_, record) => (
                <span>{`${convertUTCToLocalTime(record.registered_on)}`}</span>
            ),
            sorter: {
                compare: (a, b) => {
                    if (a.registered_on > b.registered_on) {
                        return 1
                    } else if (a.registered_on < b.registered_on) {
                        return -1
                    } else {
                        return 0
                    }
                },
            },
            key: 'registered_on',
        },
        {
            title: '邮箱',
            dataIndex: 'email',
            sorter: {
                compare: (a, b) => {
                    if (a.email > b.email) {
                        return 1
                    } else if (a.email < b.email) {
                        return -1
                    } else {
                        return 0
                    }
                },
            },
            key: 'email',
        },
        {
            title: '年龄',
            dataIndex: 'age',
            key: 'age',
        },
        {
            title: '性别',
            dataIndex: 'gender',
            key: 'gender',
        },
        {
            title: '城市',
            dataIndex: 'city',
            key: 'city',
        },
        {
            title: '职业',
            dataIndex: 'occupation',
            key: 'occupation',
        },
        {
            title: '操作',
            key: 'id',
            render: (_, record) => (
                <Space size="middle">
                    <Button type="primary" onClick={() => {handleClickEdit(record.uuid)}}>编辑</Button>
                    <Button type="primary" danger disabled>删除</Button>
                </Space>
            ),
        },
    ];

    const dispatch = useDispatch()
    let permission_user_list = useSelector(state =>
        state.get('admin').get('permission_user_list')
    )

    const handleClickEdit = (uuid) => {
        console.log("uuid = ", uuid);
        dispatch(actionCreators.setPermissionUserPageAction('personal'))
        dispatch(actionCreators.setPermissionUserUuidAction(uuid))
    }

    return (
        <Table
            columns={columns}
            dataSource={permission_user_list.toJS()}
            onChange={onChange}
            rowKey={"id"}
        />
    )
}

export default UserPermissionTable