import React, { Component } from 'react'
import { connect } from 'react-redux'

import { actionCreators } from '../../store'
import ModalImage from '../ModalImage'
import styles from './index.module.css'

class ModalImageView extends Component {
    render() {
        if (this.props.is_message_image_visible) {
            return (
                <div
                    className={styles.main}
                    onClick={this.handleFileImgVideoVisible.bind(this)}
                >
                    <ModalImage />
                </div>
            )
        } else {
            return null
        }
    }

    handleFileImgVideoVisible(e) {
        let id = e.target.id
        if (id !== 'id_show_img') {
            this.props.handleMessageImageVisibleAction(false)
            this.props.handleMessageItemImagePathAction('')
        }
    }
}

const mapStateToProps = (state) => {
    return {
        is_message_image_visible: state.get('home').get('is_message_image_visible'),
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        handleMessageImageVisibleAction(status) {
            dispatch(actionCreators.messageImageVisibleAction(status))
        },
        handleMessageItemImagePathAction(path) {
            dispatch(actionCreators.messageItemImagePathAction(path))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalImageView)