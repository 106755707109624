import React, { Component } from 'react';
import { Collapse } from 'antd';
import styles from './index.module.css';

import expandImg from '../../statics/chat_type_expand.png'
import shrinkImg from '../../statics/chat_type_shrink.png'


class CustomCollapse extends Component {
    render() {
        return (
            <Collapse
                className={styles.custom_collapse}
                items={this.props.items}
                expandIcon={this.customExpandIcon.bind(this)}
                expandIconPosition='end'
                defaultActiveKey={['today']}
                ghost
            >
            </Collapse>
        )
    }

    // 自定义展开图标函数
    customExpandIcon(panelProps) {
        if (panelProps.isActive) {
            return <img src={expandImg} className={styles.shrink_img} />; // 当面板展开时使用IconA
        }

        return <img src={shrinkImg} className={styles.shrink_img} />; // 当面板关闭时使用IconB
    }
}

export default CustomCollapse;
