import React from 'react';
import { Table, Space, Button } from 'antd';
import { connect, useDispatch, useSelector } from 'react-redux'
import { convertUTCToLocalTime } from '../../../../utils'
import { actionCreators } from '../../store'

const onChange = (pagination, filters, sorter, extra) => {
  console.log('params', pagination, filters, sorter, extra);
};

const GroupPermissionTable = () => {
    const columns = [
        {
            title: '名称',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '创建时间',
            render: (_, record) => (
                <span>{`${convertUTCToLocalTime(record.created_at)}`}</span>
            ),
            sorter: {
                compare: (a, b) => {
                    if (a.created_at > b.created_at) {
                        return 1
                    } else if (a.created_at < b.created_at) {
                        return -1
                    } else {
                        return 0
                    }
                },
            },
            key: 'created_at',
        },
        {
            title: '用户',
            render: (_, record) => (
                <div style={{maxWidth:'200px',textOverflow:'ellipsis',whiteSpace: 'nowrap',overflow: 'hidden',}}>
                    { record.users.map((item) => {
                        return (
                            <span style={{marginRight: '5px'}} key={item.id}>{item.nickname}</span>
                        )
                    })}
                </div>
            ),
            key: 'organization_id',
        },
        {
            title: '操作',
            render: (_, record) => (
                <Space size="middle">
                    <Button type="primary" onClick={() => {handleClickEdit(record.name)}}>编辑</Button>
                    <Button type="primary" danger disabled>删除</Button>
                </Space>
            ),
            key: 'action',
        },
    ];

    const dispatch = useDispatch()
    let permission_group_list = useSelector(state =>
        state.get('admin').get('permission_group_list')
    )

    const handleClickEdit = (name) => {
        console.log("name = ", name);
        dispatch(actionCreators.setPermissionGroupPageAction('personal'))
        dispatch(actionCreators.setPermissionGroupIdAction(name))
    }

    return (
        <Table
            columns={columns}
            dataSource={permission_group_list.toJS()}
            onChange={onChange}
            rowKey={"id"}
        />
    )
}

export default GroupPermissionTable