import React, { useState } from 'react';

import styles from './index.module.css'
import QYWXImg from '../../../../statics/qywechat.png'

import QYWXSelectModal from '../QYWXSelectModal'


export default function QYWXLogin() {
    const [isModalOpen, setIsModalOpen] = useState(false)

    return (
        <>
            <div
                className={styles.container}
                title={"企业微信登录"}
                onClick={() => { setIsModalOpen(true) }}
            >
                <img src={QYWXImg} className={styles.img} alt="qywx" />
                <span className={styles.text}>使用企业微信登录</span>
            </div>
            <QYWXSelectModal
                isModalOpen={isModalOpen}
                handleCloseModal={() => { setIsModalOpen(false) }}
            />
        </>
    )
}
