// 辅助函数：将字符串日期转换为Date对象
function parseDateString(dateString) {
    return new Date(dateString);
}

// 辅助函数：判断日期是否是今天
function isToday(date) {
    const today = new Date();
    return date.getDate() === today.getDate() &&
           date.getMonth() === today.getMonth() &&
           date.getFullYear() === today.getFullYear();
}

// 辅助函数：判断日期是否在本周内
function isWithinWeek(date) {
    const today = new Date();
    const firstDayOfWeek = new Date(today.setDate(today.getDate() - today.getDay()));
    const lastDayOfWeek = new Date(firstDayOfWeek.getTime());
    lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6);

    return date >= firstDayOfWeek && date <= lastDayOfWeek;
}

// 辅助函数：判断日期是否在过去7天内
function isWithinLast7Days(date) {
    const today = new Date();
    const sevenDaysAgo = new Date(today.setDate(today.getDate() - 7));

    return date >= sevenDaysAgo && date <= new Date();
}

// 计算今天访问的用户数
function getTodaysVisitors(users) {
    return users.filter((user) => {
        const lastLoginDate = parseDateString(user.last_login);
        return isToday(lastLoginDate);
    }).length;
}

// 计算本周访问的用户数
function getThisWeeksVisitors(users) {
    return users.filter((user) => {
        const lastLoginDate = parseDateString(user.last_login);
        return isWithinWeek(lastLoginDate);
    }).length;
}

  // 计算过去7天新增的用户数
function getLast7DaysNewUsers(users) {
    return users.filter((user) => {
        const registeredDate = parseDateString(user.registered_on);
        return isWithinLast7Days(registeredDate);
    }).length;
}

export {
    getTodaysVisitors,
    getThisWeeksVisitors,
    getLast7DaysNewUsers,
}
