import { fromJS } from 'immutable'

import * as actionTypes from './actionTypes'

const defaultState = fromJS({
    filter_butn: 'all',
    filter_category_list: [
        { label: '所有', value: 'all', active: true, },
        { label: '今天', value: 'today', active: false },
        { label: '过去3天', value: 'third_day', active: false },
        { label: '过去7天', value: 'seventh_day', active: false },
        { label: '过去30天', value: 'thirtieth_day', active: false },
    ],
})

const reducer = (state=defaultState, action) => {
    switch(action.type) {
        case actionTypes.FILTER_BUTN_ACTION:
            return state.set('filter_butn', action.value)
        case actionTypes.FILTER_CATEGORY_LIST_ACTION:
            return state.merge({
                'filter_category_list': fromJS(action.data),
            })
        default:
            return state
    }
}

export default reducer
