import { Button } from 'antd'
import { connect, useDispatch, useSelector } from 'react-redux'
import { actionCreators } from '../../store'
import styles from './index.module.css'

function KnowledgeBaseGoBack() {
    const dispatch = useDispatch()

    return (
        <div className={styles.goback}>
            <Button
                onClick={() => {dispatch(actionCreators.setKnowledgeBaseShowContentAction(''))}}
            >
                返回列表展示页
            </Button>
        </div>
    )
}

export default KnowledgeBaseGoBack