import React, { Component } from 'react';
import { connect } from 'react-redux'

import { actionCreators } from '../../store'
import styles from './index.module.css'


class ModalBlockUsage extends Component {
    render() {
        if (this.props.is_show_block_usage) {
            return (
                <div className={styles.container}></div>
            )
        } else {
            return null
        }
    }
}

const mapStateToProps = (state) => {
    return {
        is_show_block_usage: state.get('home').get('is_show_block_usage'),
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        // handleIsFeedbackModalOpenAction(status) {
        //     dispatch(actionCreators.isFeedbackModalOpenAction(status))
        // },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalBlockUsage)