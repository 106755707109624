import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux'

import AssistantMessageItem from '../AssistantMessageItem'
import styles from './index.module.css'
import { actionCreators } from '../../store';
import { scrollToBottom, isDivScrolledToBottom } from '../../../../utils'


function AssistantMessageList() {
    const dispatch = useDispatch()
    let messages_immutable = useSelector(state => state.get('home').get('assistant_chat_message_data'))
    let messages = useSelector(state => state.get('home').get('assistant_chat_message_data')).toJS()
    let allow_assistant_content_scroll = useSelector(state => state.get('home').get('allow_assistant_content_scroll'))
    const scrollRef = useRef(null)

    useEffect(() => {
        const handleWheel = () => {
            if (isDivScrolledToBottom(scrollRef.current)) {
                dispatch(actionCreators.allowAssistantContentScrollAction(true))
            } else if (allow_assistant_content_scroll) {
                console.log('useEffect handleWheel Mouse wheel event detected!')
                dispatch(actionCreators.allowAssistantContentScrollAction(false))
            }
        }

        // 组件挂载时，如果 scrollRef 已存在，则注册 wheel 事件
        if (scrollRef.current) {
            scrollRef.current.addEventListener('wheel', handleWheel)
            scrollToBottom(scrollRef.current)
        }

        return () => {
            // 组件将要卸载时，移除 wheel 事件监听
            if (scrollRef.current) {
                scrollRef.current.removeEventListener('wheel', handleWheel)
            }
        }
    }, [])

    useEffect(() => {
        if (allow_assistant_content_scroll && scrollRef) {
            scrollToBottom(scrollRef.current)
        }
    }, [scrollRef.current, messages_immutable])

    return (
        <div
            className={styles.content}
            ref={scrollRef}
        >
            <div className={styles.item_box}>
                {
                    messages.map((item) => {
                        return (
                            <AssistantMessageItem
                                ItemData={item}
                                key={item.topic_message_id}
                            />
                        )
                    })
                }
            </div>
        </div>
    )
}

export default AssistantMessageList