import React, { Component, useState } from 'react';
import { connect } from 'react-redux'
import { Select, Tooltip } from 'antd'

import styles from './index.module.css'
import { actionCreators } from '../../store'
import bgImg from '../../../../statics/ai_link_stone.gif'
import manImg from '../../../../statics/gender_man.png'
import womanImg from '../../../../statics/gender_woman.png'
import anonymousImg from '../../../../statics/gender_anonymous.png'
import MyButton from '../../../../common/MyButton';


const CustomSelectInfo = ({ value, valueOptions, onValueChange, placeholder, selectTitle }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const handleDropdownVisibilityChange = (open) => {
        setDropdownOpen(open);
    }

    const onValueSelectChange = (value) => {
        onValueChange(value)
    }

    return (
        <div className={styles.common_info_box}>
            <div className={styles.common_bg_img_box}>
                <img src={bgImg} className={styles.gender_bg_img} />
            </div>
            <div className={styles.common_ask}>
                {selectTitle}
            </div>
            <div className={styles.common_butn_box}>
                <Select
                    // defaultValue="请选择你的年龄"
                    popupClassName="custom_select"
                    // placeholder="请选择你的年龄"
                    value={value || placeholder}
                    dropdownAlign={{ offset: [0, 0] }}
                    className={`${styles.select} ${dropdownOpen ? styles.select_open : ''}`}
                    onDropdownVisibleChange={handleDropdownVisibilityChange}
                    options={valueOptions}
                    onChange={(e) => {onValueSelectChange(e)}}
                />
            </div>
        </div>
    )
}

const GenderInfo = ({ gender, onGenderClick }) => {
    return (
        <div className={styles.gender_info_box}>
            <div className={styles.gender_topic}>
                让我们相互了解一下对方！
            </div>
            <div className={styles.gender_bg_img_box}>
                <img src={bgImg} className={styles.gender_bg_img} />
            </div>
            <div className={styles.gender_ask}>
                请问你的性别？
            </div>
            <div className={styles.gender_butn_box}>
                <div className={styles.gender_item}>
                    <img
                        src={manImg}
                        className={`${styles.gender_item_img} ${styles.gender_man_img}`}
                        onClick={() => {onGenderClick('man')}}
                    />
                    <div className={styles.item_butn_box}>
                        <div
                            className={`${styles.item_butn} ${gender === 'man' ? styles.item_butn_active : ''}`}
                            onClick={() => {onGenderClick('man')}}
                        >
                            男
                        </div>
                    </div>
                </div>
                <div className={styles.gender_item}>
                    <img
                        src={womanImg}
                        className={styles.gender_item_img}
                        onClick={() => {onGenderClick('woman')}}
                    />
                    <div className={styles.item_butn_box}>
                        <div
                            className={`${styles.item_butn} ${gender === 'woman' ? styles.item_butn_active : ''}`}
                            onClick={() => {onGenderClick('woman')}}
                        >
                            女
                        </div>
                    </div>
                </div>
                <div className={styles.gender_item}>
                    <img
                        src={anonymousImg}
                        className={styles.gender_item_img}
                        onClick={() => {onGenderClick('other')}}
                    />
                    <div className={styles.item_butn_box}>
                        <div
                            className={`${styles.item_butn} ${gender === 'other' ? styles.item_butn_active : ''}`}
                            onClick={() => {onGenderClick('other')}}
                        >
                            不方便回答
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const StepComponent = ({ step, clickStep }) => {
    return (
        <div className={styles.step_box}>
            <div className={`${styles.step} ${step >= 1 ? styles.step_active : ''}`} onClick={() => {clickStep(1)}}></div>
            <div className={`${styles.step} ${step >= 2 ? styles.step_active : ''}`} onClick={() => {clickStep(2)}}></div>
            <div className={`${styles.step} ${step >= 3 ? styles.step_active : ''}`} onClick={() => {clickStep(3)}}></div>
            <div className={`${styles.step} ${step >= 4 ? styles.step_active : ''}`} onClick={() => {clickStep(4)}}></div>
        </div>
    )
}

class UserResearch extends Component {
    constructor(props) {
        super(props);
    }

    renderButtonWithTooltip(research_step, user_research_data) {
        // 创建一个映射关系，将每个步骤与对应的数据字段关联起来
        const stepDataFields = {
            1: 'gender',
            2: 'age',
            3: 'occupation',
            4: 'city'
        };

        // 获取当前步骤对应的数据字段名
        const currentField = stepDataFields[research_step];

        // 判断用户是否在当前步骤选择了数据
        const is_select = user_research_data[currentField] !== '';

        if (is_select) {
            return (
                <MyButton
                    width={'133px'}
                    height={'43px'}
                    onClick={() => {this.onNextClick(research_step)}}
                    text={ research_step === 4 ? "完成" : "下一步" }
                    type="primary"
                />
            )
        } else {
            return (
                <Tooltip placement="topRight" title={"未点击选项，无法进行下一步"}>
                    <div className={styles.my_button}>
                        <MyButton
                            width={'133px'}
                            height={'43px'}
                            text={ research_step === 4 ? "完成" : "下一步" }
                            type="primary"
                        />
                    </div>
                </Tooltip>
            )
        }
    }

    render() {
        let user_research_data = this.props.user_research_data.toJS()
        let research_step = user_research_data.step

        let main_element = ''
        if (research_step === 2) {
            main_element = (
                <CustomSelectInfo
                    value={user_research_data.age}
                    valueOptions={user_research_data.age_options}
                    onValueChange={this.onSelectChange.bind(this, 'age')}
                    placeholder={"请选择你的年龄"}
                    selectTitle={"请问你的年龄？"}
                />
            )
        } else if (research_step === 3) {
            main_element = (
                <CustomSelectInfo
                    value={user_research_data.occupation}
                    valueOptions={user_research_data.occupation_options}
                    onValueChange={this.onSelectChange.bind(this, 'occupation')}
                    placeholder={"请选择你的职业"}
                    selectTitle={"请问你的职业？"}
                />
            )
        } else if (research_step === 4) {
            main_element = (
                <CustomSelectInfo
                    value={user_research_data.city}
                    valueOptions={user_research_data.city_options}
                    onValueChange={this.onSelectChange.bind(this, 'city')}
                    placeholder={"请选择你所在的城市"}
                    selectTitle={"请问你所在的城市？"}
                />
            )
        } else {
            // research_step === 1
            main_element = (
                <GenderInfo
                    onGenderClick={this.onGenderClick.bind(this)}
                    gender={user_research_data.gender}
                />
            )
        }


        return (
            <div className={styles.container}>
                <div className={styles.header}>
                    <StepComponent step={research_step} clickStep={this.handleClickStep.bind(this)} />
                </div>

                <div className={styles.main}>
                    {
                        main_element
                    }
                </div>

                <div className={styles.footer}>
                    <div className={styles.footer_butn_box}>
                        {
                            research_step === 1 ? (
                                null
                            ) : (
                                <div className={styles.previous}>
                                    <MyButton
                                        width={'133px'}
                                        height={'43px'}
                                        text={ "上一步" }
                                        onClick={() => {this.onPreviousClick(research_step)}}
                                        type="light"
                                    />
                                </div>
                            )
                        }

                        {
                            this.renderButtonWithTooltip(research_step, user_research_data)
                        }
                    </div>
                </div>
            </div>
        )
    }

    onGenderClick(value) {
        let user_research_data = this.props.user_research_data.toJS()
        if (user_research_data.gender !== value) {
            user_research_data.gender = value
            this.props.handleUserResearchDataAction(user_research_data)
        }
    }

    onPreviousClick(current_step) {
        let step = 1
        if (current_step > 1) {
            step = current_step - 1
        }

        let user_research_data = this.props.user_research_data.toJS()
        user_research_data.step = step
        this.props.handleUserResearchDataAction(user_research_data)
    }

    onNextClick(current_step) {
        let user_research_data = this.props.user_research_data.toJS()
        if (current_step === 4) {
            this.props.handlePostUserResearchData()
        } else {
            user_research_data.step = current_step + 1
        }

        this.props.handleUserResearchDataAction(user_research_data)
    }

    onSelectChange(type, value) {
        let user_research_data = this.props.user_research_data.toJS()

        if (type === 'age') {
            user_research_data.age = value
        } else if (type === 'occupation') {
            user_research_data.occupation = value
        } else if (type === 'city') {
            user_research_data.city = value
        }

        this.props.handleUserResearchDataAction(user_research_data)
    }

    handleClickStep(step) {
        let user_research_data = this.props.user_research_data.toJS()
        user_research_data.step = step
        this.props.handleUserResearchDataAction(user_research_data)
    }
}

const mapStateToProps = (state) => {
    return {
        user_research_data: state.get('home').get('user_research_data'),
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleUserResearchDataAction(data) {
            dispatch(actionCreators.userResearchDataAction(data))
        },
        handlePostUserResearchData() {
            dispatch(actionCreators.postUserResearchData())
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserResearch)