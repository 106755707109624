import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styles from './index.module.css'
import { actionCreators } from '../../store'
import MyButton from '../../../../common/MyButton'


const SideBarNewChatButton = () => {
    const dispatch = useDispatch()

    const addNewConversation = () => {
        dispatch(actionCreators.createNewConversationAction())
    }

    return (
        <div className={styles.container}>
            <MyButton
                width={'100%'}
                height={'40px'}
                onClick={addNewConversation}
                text={
                    <>
                        <div className={styles.img}></div>
                        <span className={styles.text}>创建新对话</span>
                    </>
                }
                type="light"
            />
        </div>
    )
}

export default SideBarNewChatButton