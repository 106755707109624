import React, { Component, useState } from 'react';
import { connect } from 'react-redux'
import { Divider, Tooltip, Popover } from 'antd'

import styles from './index.module.css'
import { actionCreators } from '../../store'
import SideBarChatHistoryInput from '../SideBarChatHistoryInput'

import expandImg from '../../../../statics/chat_type_expand.png'
import shrinkImg from '../../../../statics/chat_type_shrink.png'
import messageImg from '../../../../statics/message.png'
import menuImg from '../../../../statics/menu.png'
import collectionImg from '../../../../statics/collection.png'
import collectionActiveImg from '../../../../statics/collection_active.png'
import popverEditImg from '../../../../statics/popover_edit.png'
import popoverTrashImg from '../../../../statics/popover_trash.png'


const CustomPopover = (props) => {
    const [open, setOpen] = useState(false)
    let item = props.item

    const popoverOpenChange = (open) => {
        console.log("popoverOpenChange open = ", open);
        setOpen(open)
    };

    const hidePopover = () => {
        setOpen(false)
    }

    const handleItemDelete = (active_date, active_topicid, conversation_id) => {
        hidePopover()
        props.handleItemDelete(active_date, active_topicid, conversation_id)
    }

    const handleItemRename = (active_date, active_topicid) => {
        hidePopover()
        props.handleItemRename(active_date, active_topicid)
    }

    let content = (
        <div className={styles.popover_box}>
            <div className={styles.popover_item} onClick={(e) => { e.preventDefault(); e.stopPropagation(); handleItemRename(props.ChatDataDate, item.topic_id) }}>
                <img src={popverEditImg} className={styles.popover_item_img} />
                <span className={styles.popover_item_edit}>重命名</span>
            </div>

            <Divider style={{margin: "5px 0"}} />

            <div
                className={styles.popover_item}
                onClick={(e) => { e.preventDefault(); e.stopPropagation(); handleItemDelete(props.ChatDataDate, item.topic_id, item.conversation_id)}}
            >
                <img src={popoverTrashImg} className={styles.popover_item_img} />
                <span className={styles.popover_item_delete}>删除</span>
            </div>
        </div>
    )

    return (
        <div className={styles.butns_box}>
            <div
                className={styles.collection_box}
                onClick={(e) => {e.preventDefault(); e.stopPropagation(); props.handleItemCollect(props.ChatDataDate, item.topic_id, item.favorites_type === '' || item.favorites_type === null ? 'collect' : '', item.conversation_id)}}
            >
                    {
                        item.favorites_type === '' || item.favorites_type === null ? (
                            <Tooltip title="收藏" placement="top">
                                <img src={collectionImg} className={styles.butns_collection_img} />
                            </Tooltip>
                        ) : (
                            <img src={collectionActiveImg} className={styles.butns_collection_img} />
                        )
                    }
            </div>

            <Popover
                overlayClassName='custom_popover_setting'
                content={content}
                placement="right"
                trigger="click"
                open={open}
                onOpenChange={(e) => {popoverOpenChange(e)}}
            >
                <Tooltip title="更多" placement="top">
                    <img
                        src={menuImg}
                        alt={"菜单"}
                        className={styles.butns_more_img}
                        onClick={(e) => {e.preventDefault(); e.stopPropagation();}}
                    />
                </Tooltip>
            </Popover>
        </div>
    )
}

class SideBarChatHistory extends Component {
    handleItemRename(active_date, active_topicid) {
        this.props.handleConversationRenameAction(active_date, active_topicid)
    }

    handleItemDelete(active_date, active_topicid, conversation_id) {
        if (this.props.is_thinking && this.props.active_topicid === active_topicid) {
            console.log("正在问答的记录，不能被删除");
        } else {
            this.props.handleDeleteConversationAction(active_date, active_topicid, conversation_id)
        }
    }

    handleItemCollect(active_date, active_topicid, favorites_type, conversation_id) {
        this.props.handleCollectConversationAction(active_date, active_topicid, favorites_type, conversation_id)
    }

    render() {
        let date_conversations = this.props.ChatData

        return (
            <div>
                {
                    date_conversations.map((item) => {
                        return (
                            <div
                                className={`${styles.item} ${item.is_edit_name ? styles.item_active_edit : ''} ${item.active ? styles.item_active : ''}`}
                                key={item.topic_id}
                                onClick={() => {
                                    if (!item.is_edit_name) {
                                        this.switchItem(this.props.ChatDataDate, item.topic_id)
                                    }
                                }}
                            >
                                <img src={messageImg} className={styles.item_img} />
                                <div className={styles.item_text_box}>
                                    {
                                        item.is_edit_name ? (
                                            <SideBarChatHistoryInput
                                                ChatDataDate={this.props.ChatDataDate}
                                                CurrentTopicid={item.topic_id}
                                                ConversationNameEdit={item.conversation_name_edit}
                                            />
                                        ) : (
                                            <div className={styles.item_text}>{item.conversation_name}</div>
                                        )
                                    }
                                </div>
                                <div className={styles.butn_box}>
                                    <CustomPopover
                                        item={item}
                                        ChatDataDate={this.props.ChatDataDate}
                                        handleItemRename={this.handleItemRename.bind(this)}
                                        handleItemDelete={this.handleItemDelete.bind(this)}
                                        handleItemCollect={this.handleItemCollect.bind(this)}
                                    />
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    switchItem(active_date, active_topicid) {
        if (this.props.active_date !== active_date || this.props.active_topicid !== active_topicid) {
            this.props.handleSwitchItemAction(active_date, active_topicid)
        }
    }
}

const mapStateToProps = (state) => {
    return {
        active_date: state.get('home').get('active_date'),
        active_topicid: state.get('home').get('active_topicid'),
        is_thinking: state.get('home').get('is_thinking'),
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleSwitchItemAction(active_date, active_topicid) {
            dispatch(actionCreators.switchItemAction(active_date, active_topicid))
        },
        handleCollectConversationAction(active_date, active_topicid, favorites_type, conversation_id) {
            dispatch(actionCreators.collectConversationAction(active_date, active_topicid, favorites_type, conversation_id))
        },
        handleDeleteConversationAction(active_date, active_topicid, conversation_id) {
            dispatch(actionCreators.deleteConversationAction(active_date, active_topicid, conversation_id))
        },
        handleConversationRenameAction(active_date, active_topicid) {
            dispatch(actionCreators.conversationRenameAction(active_date, active_topicid))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SideBarChatHistory)