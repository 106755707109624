import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Tooltip } from 'antd'

import { actionCreators } from '../../store'
import styles from './index.module.css'

import sideArrowCloseImg from '../../../../statics/side_arrow_close.png'
import sideArrowOpenImg from '../../../../statics/side_arrow_open.png'


class SideBarArrow extends Component {
    render() {
        return (
            <>
                <Tooltip placement="right" title={this.props.zoom_butn_text}>
                    <div
                        className={styles.side_arrow_box}
                        onClick={this.handleZoomSidebar.bind(this)}
                    >
                        {
                            this.props.is_zoom ? (
                                <img src={sideArrowOpenImg} className={styles.side_arrow_open} />
                            ) : (
                                <img src={sideArrowCloseImg} className={styles.side_arrow_close} />
                            )
                        }
                    </div>
                </Tooltip>
            </>
        )
    }

    handleZoomSidebar() {
        let result = !this.props.is_zoom
        let text = result ? "打开侧边栏" : "关闭侧边栏"
        this.props.handleIsZoomAction(result)
        this.props.handleZoomButnTextAction(text)
    }
}

const mapStateToProps = (state) => {
    return {
        zoom_butn_text: state.get('home').get('zoom_butn_text'),
        is_zoom: state.get('home').get('is_zoom'),
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleIsZoomAction(status) {
            dispatch(actionCreators.isZoomAction(status))
        },
        handleZoomButnTextAction(value) {
            dispatch(actionCreators.zoomButnTextAction(value))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SideBarArrow)