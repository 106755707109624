import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter, Redirect } from 'react-router-dom'

import styles from './index.module.css'
import { actionCreators } from './store'
import { actionCreators as homeActionCreators } from '../home/store'
import FilterBox from './components/FilterBox'
import ShowBox from './components/ShowBox'
import NoneContentBox from './components/NoneContentBox'
import Header from './components/Header'
import ModalFeedback from '../../common/ModalFeedback'
import ModalFeedbackDone from '../../common/ModalFeedbackDone'
import ModalUserInfo from '../../common/ModalUserInfo'


class Collect extends Component {
    componentDidMount() {
        let all_chat_data = this.props.all_chat_data.toJS()

        let hasData = Object.values(all_chat_data).some(array => array.length > 0);

        if (hasData) {
            console.log('有数据')
        } else {
            console.log('没数据')
            if (this.props.has_login) {
                console.log("获取所有历史记录");
                // this.props.handleInitAllChatHistory()
                this.props.handleInitAllConversationHistory()
            }
        }
    }

    render() {
        if (!this.props.has_login) {
            return <Redirect to="/" />
        }

        return (
            <div className={styles.container}>
                <div className={styles.main}>
                    <div className={styles.header}>
                        <Header />
                    </div>

                    <div className={styles.title_box}>
                        <span className={styles.title}>收藏夹</span>
                    </div>

                    <div className={styles.filter_box}>
                        <FilterBox />
                    </div>

                    <div className={styles.content_box}>
                        <div className={styles.show_box}>
                            <ShowBox />
                        </div>
                    </div>

                    {/* <div className={styles.none_box}>
                        <NoneContentBox />
                    </div> */}
                </div>

                <ModalFeedback />
                <ModalFeedbackDone />
                <ModalUserInfo />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        has_login: state.get('login').get('has_login'),
        all_chat_data: state.get('home').get('all_chat_data'),
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleInitAllChatHistory() {
            dispatch(homeActionCreators.initAllChatHistory())
        },
        handleInitAllConversationHistory() {
            dispatch(homeActionCreators.initAllConversationHistory())
        },
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Collect))