import React from 'react'
import { Modal } from 'antd'
import { useDispatch } from 'react-redux'

import styles from './index.module.css'
import { isDevelopment, getOriginWithExplicitPort } from '../../../../utils'
import { actionCreators } from '../../store'


const QYWXSelectModal = ({isModalOpen, handleCloseModal}) => {
    const dispatch = useDispatch()

    const handleCancel = () => {
        handleCloseModal()
    }

    const handleRedirect = (value) => {
        sessionStorage.setItem('what_company', value)

        let agent_id = ''
        let appid = ''
        let login_status = ''
        if (isDevelopment()) {
            if (value === 'jietu') {
                agent_id = '1000097'        // 后面申请的企业微信应用，但是需要配置可信域名
            } else {
                agent_id = '1000039'        // 使用了很久之前就申请好的 ParaDesign 后台管理这个企业微信应用
            }
            appid = 'wwdfc157b24bebba67'
            login_status = 'new'
        } else {
            if (value === 'jietu') {
                agent_id = '1000010'
                appid = 'ww8a57b5cd8b30618a'
                login_status = 'new'
            } else {
                agent_id = '1000040'
                appid = 'wwdfc157b24bebba67'
                login_status = 'old'
            }
        }

        if (login_status === 'new') {
            redirectToOldQYWXLogin(appid, agent_id)
        } else {
            redirectToOldQYWXLogin(appid, agent_id)
        }
    }

    const redirectToNewQYWXLogin = (appid, agent_id) => {
        let wxlogin = {
            login_type: 'CorpApp',
            appid: appid,
            agentid: agent_id,
          //   redirect_uri: encodeURIComponent(window.location.origin + "/auth/callback"),
            redirect_uri: encodeURIComponent(getOriginWithExplicitPort()),
            state: "qywx_login",
            href: "",
        };

          let wxurl = `https://login.work.weixin.qq.com/wwlogin/sso/login?login_type=${wxlogin.login_type}`;
          wxurl += "&appid=" + wxlogin.appid;
          wxurl += "&agentid=" + wxlogin.agentid + "&redirect_uri=" + wxlogin.redirect_uri + "&state=" + wxlogin.state;

          window.location.href = wxurl;
    }

    const redirectToOldQYWXLogin = (appid, agent_id) => {
        let wxlogin = {
            appid: appid,
            agentid: agent_id,
          //   redirect_uri: encodeURIComponent(window.location.origin + "/auth/callback"),
            redirect_uri: encodeURIComponent(getOriginWithExplicitPort()),
            state: "test",
            href: "",
          };

          let wxurl = "https://open.work.weixin.qq.com/wwopen/sso/qrConnect?appid=" + wxlogin.appid;
          wxurl += "&agentid=" + wxlogin.agentid + "&redirect_uri=" + wxlogin.redirect_uri + "&state=" + wxlogin.state;
          wxurl += wxlogin.style ? "&style=" + wxlogin.style : "";
          wxurl += wxlogin.href ? "&href=" + wxlogin.href : "";

          window.location.href = wxurl;
    }

    return (
        <>
            <Modal
                open={isModalOpen}
                onOk={handleCancel}
                onCancel={handleCancel}
                centered
                footer={false}
                width={850}
                closeIcon={false}
                className={styles.custom_modal}
            >
                <div className={styles.main}>
                    <div className={styles.butn_box}>
                        <div
                            className={`${styles.butn} ${styles.butn_en}`}
                            onClick={() => {handleRedirect('jieen')}}
                        >
                        </div>
                    </div>

                    <div
                        className={styles.butn_transition}
                        onClick={handleCancel}
                    ></div>

                    <div className={styles.butn_box}>
                        <div
                            className={`${styles.butn} ${styles.butn_tu}`}
                            onClick={() => {handleRedirect('jietu')}}
                        >
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default QYWXSelectModal