import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Menu, Button, Modal, message } from 'antd'
import { DeleteOutlined, ExclamationCircleOutlined, EditOutlined } from '@ant-design/icons';

import KnowledgeBaseContent from '../../components/KnowledgeBaseContent'
import { actionCreators } from '../../store'
import styles from './index.module.css'
import KnowledgeBaseAddModal from '../../components/KnowledgeBaseAddModal'
import KnowledgeBaseContentSlice from '../../components/KnowledgeBaseContentSlice'
import KnowledgeBaseFileUploadButton from '../KnowledgeBaseFileUploadButton'
import KnowledgeBaseFileUpload from '../KnowledgeBaseFileUpload'
import KnowledgeBaseImageUpload from '../KnowledgeBaseImageUpload'
import KnowledgeBaseEditModal from '../KnowledgeBaseEditModal'
import { findItemByProperty } from '../../../../utils'


const KnowledgeBaseMenu = ({ handleShowEditModal }) => {
    const dispatch = useDispatch()
    let knowledge_base_menu = useSelector(state => state.get('admin').get('knowledge_base_menu')).toJS()
    let knowledge_base_list = useSelector(state => state.get('admin').get('knowledge_base_list')).toJS()
    let active_model = useSelector(state => state.get('admin').get('active_model'))

    const handleDelete = (name) => {
        console.log("delete name = ", name);
        if (name === '图片知识库') {
            message.info("该知识库不能被删除，请联系管理员")
            return
        }

        Modal.confirm({
            title: '删除模型',
            icon: <ExclamationCircleOutlined />,
            content: '请确认是否要删除该模型以及该模型的相关文件？',
            okText: '确认',
            cancelText: '取消',
            onOk() {
                console.log('OK');
                dispatch(actionCreators.deleteKnowledgeBase(name))
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    const handleEdit = (name) => {
        let obj = knowledge_base_list.find(item => item.name === name)
        if (obj.r_type === 'picture_kb') {
            handleShowEditModal('picture_kb')
        } else {
            handleShowEditModal('document_kb')
        }
        dispatch(actionCreators.setEditKnowledgeBaseName(name))
    }

    const modifyFirstLevelChildren = () => {
        return knowledge_base_menu.map(item => {
            if (item.children) {
                // 只修改第一层 children
                const modifiedChildren = item.children.map(child => ({
                    ...child,
                    label: (
                        child.r_type === 'picture_kb' ? (
                            // child.label === '图片知识库' ? (
                            //     <>
                            //         {child.label}
                            //     </>
                            // ) : (
                                <>
                                    {child.label}
                                    <Button
                                        type="text"
                                        icon={<DeleteOutlined />}
                                        onClick={(e) => {
                                            e.stopPropagation(); // 防止点击事件冒泡到 Menu.Item
                                            handleDelete(child.label);
                                        }}
                                        style={{ float: 'right', marginLeft: 'auto' }}
                                        danger
                                    />
                                </>
                            // )
                        ) : (
                            child.r_type !== 'picture_kb' && child.r_type !== 'document_kb' ? (
                                <>
                                    {child.label}
                                    <Button
                                        type="text"
                                        icon={<EditOutlined />}
                                        onClick={(e) => {
                                            e.stopPropagation(); // 防止点击事件冒泡到 Menu.Item
                                            handleEdit(child.label);
                                        }}
                                        style={{ float: 'right', marginLeft: 'auto' }}
                                    />
                                </>
                            ) : (
                                <>
                                    {child.label}
                                    <Button
                                        type="text"
                                        icon={<EditOutlined />}
                                        onClick={(e) => {
                                            e.stopPropagation(); // 防止点击事件冒泡到 Menu.Item
                                            handleEdit(child.label);
                                        }}
                                        style={{ float: 'right', marginLeft: 'auto' }}
                                    />
                                    <Button
                                        type="text"
                                        icon={<DeleteOutlined />}
                                        onClick={(e) => {
                                            e.stopPropagation(); // 防止点击事件冒泡到 Menu.Item
                                            handleDelete(child.label);
                                        }}
                                        style={{ float: 'right', marginLeft: 'auto' }}
                                        danger
                                    />
                                </>
                            )
                        )
                    ),
                }))

                return {
                    ...item,
                    children: modifiedChildren,
                };
            }

            return item; // 如果没有 children，保持不变
        });
    }

    const onClick = (e) => {
        console.log('click ', e.key);
        let active_model = e.key
        let obj = findItemByProperty(knowledge_base_menu, 'key', active_model)
        dispatch(actionCreators.setActiveModelAction(obj.key, obj.label))
        dispatch(actionCreators.setKnowledgeBaseShowContentAction(''))
        dispatch(actionCreators.setKnowledgeBaseListSearchName(''))
    }

    return (
        <Menu
            onClick={onClick}
            selectedKeys={[active_model]}
            mode="inline"
            items={modifyFirstLevelChildren()}
            className={styles.menu}
        />
    )
}


const KnowledgeBase = () => {
    const dispatch = useDispatch()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isEditModalOpen, setIsEditModalOpen] = useState(false)
    const [componentType, setComponentType] = useState('document_kb')
    const [editComponentType, setEditComponentType] = useState('document_kb')

    let knowledge_base_show_content = useSelector(state => state.get('admin').get('knowledge_base_show_content'))
    let active_model_name = useSelector(state => state.get('admin').get('active_model_name'))
    let knowledge_base_list = useSelector(state => state.get('admin').get('knowledge_base_list'))
    const [activeModelRType, setActiveModelRType] = useState('picture_kb')

    useEffect(() => {
        console.log("获取模型列表");
        dispatch(actionCreators.fetchKnowledgeBaseList())
        dispatch(actionCreators.getAdminKnowledgeBaseCategorys())
    }, [])


    useEffect(() => {
        let obj = knowledge_base_list.toJS().find(item => item.name === active_model_name)
        if (obj) {
            if (obj.r_type === 'picture_kb') {
                setActiveModelRType('picture_kb')
            } else {
                setActiveModelRType('document_kb')
            }
        }
    }, [active_model_name, knowledge_base_list])

    const handleShowModal = (r_type) => {
        setIsModalOpen(true)
        setComponentType(r_type)
    }

    const handleCloseModal = () => {
        setIsModalOpen(false)
    }

    const handleShowEditModal = (r_type) => {
        setIsEditModalOpen(true)
        setEditComponentType(r_type)
    }

    const handleCloseEditModal = () => {
        setIsEditModalOpen(false)
    }

    return (
        <div className={styles.main}>
            <div className={styles.header}>
                <div className={styles.title}>
                    数据管理
                </div>
            </div>

            <div className={styles.container}>
                <div className={styles.left}>
                    <div className={styles.butn_box}>
                        <Button type="primary" onClick={() => handleShowModal('document_kb')}>添加文本组件</Button>
                        <Button type="primary" onClick={() => handleShowModal('picture_kb')}>添加图片组件</Button>
                    </div>
                    <div className={styles.menu_box}>
                        <KnowledgeBaseMenu
                            handleShowEditModal={handleShowEditModal}
                        />
                    </div>
                </div>
                <div className={styles.right}>
                    {
                        knowledge_base_show_content === 'slice' ? (
                            <KnowledgeBaseContentSlice />
                        ) : (
                            knowledge_base_show_content === 'upload' ? (
                                activeModelRType === 'picture_kb' ? (
                                    <KnowledgeBaseImageUpload />
                                ) : (
                                    <KnowledgeBaseFileUpload />
                                )
                            ) : (
                                <>
                                    <KnowledgeBaseFileUploadButton />
                                    <KnowledgeBaseContent />
                                </>
                            )
                        )
                    }

                </div>
            </div>

            <KnowledgeBaseAddModal
                isModalOpen={isModalOpen}
                handleCloseModal={handleCloseModal}
                componentType={componentType}
            />
            <KnowledgeBaseEditModal
                isModalOpen={isEditModalOpen}
                handleCloseModal={handleCloseEditModal}
                editComponentType={editComponentType}
            />
        </div>
    )
}

export default KnowledgeBase;