import * as actionTypes from './actionTypes'
import { actionCreators as homeActionCreators} from '../../home/store'
import { message } from 'antd'
import { fetchWithCookieRefresh, handleResponseError } from '../../../utils'


export const setEmailAction = (value) => {
    return {
        type: actionTypes.SET_EMAIL_ACTION,
        value: value,
    }
}

export const setPasswordAction = (value) => {
    return {
        type: actionTypes.SET_PASSWORD_ACTION,
        value: value,
    }
}

export const testPostAccountLoginAction = (email, password, history) => {
    return (dispatch, getState) => {
        fetch('/api/account/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                username: email,
                password: password,
            })
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('HTTP error: ' + response.status);
            } else {
                history.push({ pathname: "/home" })
                dispatch(userDataAction({
                    "uuid": "ea311f4d7b374a7b8298fe352973e51b",
                    "username": "测试",
                    "avatar": "",
                }))
            }
        })
        .catch(error => {
            console.log("/account/login error = ", error);
        })
    }
}

export const isLoginLoadingAction = (status) => {
    return {
        type: actionTypes.IS_LOGIN_LOADING_ACTION,
        status: status,
    }
}

export const hasLoginAction = (status) => {
    return {
        type: actionTypes.HAS_LOGIN_ACTION,
        status: status,
    }
}

export const logoutAction = () => {
    return (dispatch, getState) => {
        // 清除登录的状态和部分数据
        dispatch(hasLoginAction(false))
        dispatch(userDataAction({}))
        // 清空 localStorage 中的状态
        localStorage.removeItem("loginState")
        dispatch(homeActionCreators.resetState())

        fetch('/api/account/logout', {
            method: 'DELETE',
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('HTTP error: ' + response.status);
            }
        })
        .catch(error => {
            console.log("/account/logout error = ", error);
        })
    }
}

export const userDataAction = (data) => {
    return {
        type: actionTypes.USER_DATA_ACTION,
        data: data,
    }
}

export const postAccountLoginAction = (email, password, history) => {
    console.log("email = ", email);
    console.log("password = ", password);
    console.log("history = ", history);
    return (dispatch, getState) => {
        fetch('/api/account/email_password_login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: email,
                password: password,
            })
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('HTTP error: ' + response.status);
            }

            return response.json()
        })
        .then(data => {
            console.log("/api/account/email_password_login data = ", data);
            if (data.code === 400) {
                message.error(data.msg)
            } else {
                let d = data.data
                dispatch(userDataAction(d))
                dispatch(hasLoginAction(true))
                if (d.is_first_login) {
                    // 首次登录
                    dispatch(homeActionCreators.isShowUserResearchAction(true))
                } else if (d.has_details === false) {
                    dispatch(homeActionCreators.isShowUserResearchAction(true))
                }

                // 清空输入框和报错信息
                dispatch(setEmailAction(""))
                dispatch(setPasswordAction(""))

                history.push({ pathname: "/home" })
            }
        })
        .catch(error => {
            console.log("/api/account/email_password_login error = ", error);
        })
    }
}

export const postUserInfoAction = (nickname) => {
    return (dispatch, getState) => {
        fetchWithCookieRefresh('/api/account/update_userinfo', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                nickname: nickname,
            })
        })
        .then(data => {
            console.log("/api/account/update_userinfo data = ", data);
            let user_data = getState().get("login").get("user_data").toJS()

            user_data.nickname = nickname

            dispatch(userDataAction(user_data))
            dispatch(homeActionCreators.isUserinfoModalOpenAction(false))
            message.success("用户信息修改成功")
        })
        .catch(error => {
            handleResponseError(message, error)
        })
    }
}

export const setPageShowAction = (value) => ({
    type: actionTypes.SET_PAGE_SHOW_ACTION,
    value: value,
})

export const setRegisterNickname = (value) => ({
    type: actionTypes.SET_REGISTER_NICKNAME ,
    value: value,
})

export const setRegisterEmail = (value) => ({
    type: actionTypes.SET_REGISTER_EMAIL ,
    value: value,
})

export const setRegisterPassword = (value) => ({
    type: actionTypes.SET_REGISTER_PASSWORD ,
    value: value,
})

export const setRegisterSecondPassword = (value) => ({
    type: actionTypes.SET_REGISTER_SECOND_PASSWORD ,
    value: value,
})

export const getEmailVerificationCode = (email) => {
    return (dispatch, getState) => {
        // 注册：register
        // 更改密码：update_password
        let code_category = 'register'
        let params = `?email=${email}&code_category=${code_category}`

        fetch('/api/account/get_email_verification_code' + params, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json'
            },
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('HTTP error: ' + response.status);
            }

            return response.json()
        })
        .then(data => {
            console.log("/api/account/get_email_verification_code data = ", data);
            message.success(data.msg)
        })
        .catch(error => {
            console.log("/api/account/get_email_verification_code error = ", error);
        })
    }
}

export const postAccountEmailRegister = (nickname, email, password, verification_code) => {
    return async (dispatch, getState) => {
        return fetch('/api/account/email_register', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                nickname: nickname,
                email: email,
                password: password,
                verification_code: verification_code,
            })
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('HTTP error: ' + response.status);
            }

            return response.json()
        })
        .then(data => {
            console.log("/api/account/email_register data = ", data);
            message.success(data.msg)
            return true
        })
        .catch(error => {
            console.log("/api/account/email_register error = ", error);
        })
    }
}
