import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Dropdown } from 'antd'

import { actionCreators } from '../../store'
import { actionCreators as loginActionCreators } from '../../../login/store'
import styles from './index.module.css'


class SideBarChatHistoryInput extends Component {
    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
    }

    componentDidMount() {
        this.inputRef.current && this.inputRef.current.focus()
    }

    render() {
        return (
            <>
                <input
                    className={styles.input}
                    type="text"
                    value={this.props.ConversationNameEdit}
                    onChange={(event) => { this.handleConversationNameChange(event, this.props.ChatDataDate, this.props.CurrentTopicid) }}
                    onBlur={() => { this.handleConversationNameBlur(this.props.ChatDataDate, this.props.CurrentTopicid) }}
                    ref={this.inputRef}
                />
            </>
        )
    }

    handleConversationNameChange(event, active_date, active_topicid) {
        let value = event.target.value
        this.props.handleConversationNameChangeAction(value, active_date, active_topicid)
    }

    handleConversationNameBlur(active_date, active_topicid) {
        this.props.handleConversationNameBlurAction(active_date, active_topicid)
    }
}

const mapStateToProps = (state) => {
    return {
        // user_data: state.get('login').get('user_data'),
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleConversationNameBlurAction(active_date, active_topicid) {
            dispatch(actionCreators.conversationNameBlurAction(active_date, active_topicid))
        },
        handleConversationNameChangeAction(value, active_date, active_topicid) {
            dispatch(actionCreators.conversationNameChangeAction(value, active_date, active_topicid))
        },
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SideBarChatHistoryInput))