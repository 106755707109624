import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState, useMemo } from 'react'
import { Button, Modal, Card, Space, Spin, Table, Input } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons';

import styles from './index.module.css'
import { actionCreators } from '../../store'


const ComponentListedPublish = ({setPageStatus}) => {
    const dispatch = useDispatch()
    const [unpublishedList, setUnpublishedList] = useState([])
    const [publishedList, setPublishedList] = useState([])
    const [creating, setCreating] = useState(false)
    let component_document_listed = useSelector(state => state.get('admin').get('component_document_listed'))
    let component_picture_listed = useSelector(state => state.get('admin').get('component_picture_listed'))
    let current_edit_component_type = useSelector(state => state.get('admin').get('current_edit_component_type'))

    useEffect(() => {
        let unpublish_list = []
        let publish_list = []
        if (current_edit_component_type === 'document_kb') {
            component_document_listed.toJS().forEach(element => {
                if (element.is_active) {
                    publish_list.push(element)
                } else {
                    unpublish_list.push(element)
                }
            });
        } else {
            component_picture_listed.toJS().forEach(element => {
                if (element.is_active) {
                    publish_list.push(element)
                } else {
                    unpublish_list.push(element)
                }
            });
        }

        setUnpublishedList(unpublish_list)
        setPublishedList(publish_list)
    }, [current_edit_component_type, component_document_listed, component_picture_listed])

    let currentComponent = useMemo(() => {
        let list = []
        let obj = {}
        if (current_edit_component_type === 'document_kb') {
            list = component_document_listed.toJS()
            obj = list.find(item => item.has_select === true)
        } else {
            list = component_picture_listed.toJS()
            obj = list.find(item => item.has_select === true)
        }

        return obj ? obj : {}
    }, [component_document_listed, component_picture_listed])

    const handleClickComponent = (id) => {
        let list = []

        if (current_edit_component_type === 'document_kb') {
            list = component_document_listed.toJS()
        } else {
            list = component_picture_listed.toJS()
        }

        let new_list = list.map((item) => {
            if (item.id === id) {
                item.has_select = true
            } else {
                item.has_select = false
            }
            return item
        })

        if (current_edit_component_type === 'document_kb') {
            dispatch(actionCreators.componentDocumentListedAction(new_list))
        } else {
            dispatch(actionCreators.componentPictureListedAction(new_list))
        }
    }

    const handleSubmit = () => {
        console.log("currentComponent.name = ", currentComponent.name);
        Modal.confirm({
            title: '上架组件',
            icon: <ExclamationCircleOutlined />,
            content: `请确认是否要上架组件《${currentComponent.name}》？`,
            okText: '确认',
            cancelText: '取消',
            onOk() {
                setCreating(true)
                dispatch(actionCreators.getAdminComponentActivate(currentComponent.name))
                    .then((data) => {
                        if (current_edit_component_type === 'document_kb') {
                            dispatch(actionCreators.getComponentListed('document_kb'))
                        } else {
                            dispatch(actionCreators.getComponentListed('picture_kb'))
                        }
                        setPageStatus('component')
                    })
                    .catch(() => {})
                    .finally(() => {setCreating(false)})
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    const handleReback = () => {
        setPageStatus('component')
    }

    return (
        <div className={styles.main}>
            <div className={styles.container_component_header}>
                <Button onClick={handleReback}>返回</Button>
            </div>
            <div className={styles.container_component_content}>
                <div className={styles.container_content_left}>
                    <div className={styles.left_components_title}>未上架</div>
                    <div className={styles.left_components}>
                        {
                            unpublishedList.map((item) => {
                                return (
                                    <div
                                        className={`${styles.shopping_model_item_box} ${item.has_select ? styles.shopping_model_item_box_select : null}`}
                                        key={item.id}
                                        onClick={() => {handleClickComponent(item.id)}}
                                    >
                                        <div
                                            className={styles.shopping_model_item}
                                        >
                                            <div className={styles.shopping_model_img_box}>
                                                <img src={item.avatar} className={styles.shopping_model_img} alt="icon" />
                                            </div>

                                            <div className={styles.shopping_model_text_box}>
                                                <div className={styles.shopping_model_text_title}>
                                                    {item.name}
                                                </div>
                                                <div className={styles.shopping_model_text_info}>
                                                    {item.overview}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

                <div className={styles.container_content_right}>
                    <div className={styles.right_info}>
                        {
                            Object.keys(currentComponent).length === 0 ? null : (
                                <>
                                    <div className={styles.right_header_img_box}>
                                        <img src={currentComponent.avatar} className={styles.right_header_img} alt="icon" />
                                    </div>
                                    <div className={styles.right_header_text_box}>
                                        <div className={styles.right_header_text}>{currentComponent.name}</div>
                                    </div>
                                    <div className={styles.introduce_box}>
                                        <div className={styles.introduce_title}>介绍</div>
                                        <div className={styles.introduce_text}>{currentComponent.overview}</div>
                                    </div>
                                    <div className={styles.example_box}>
                                        <div className={styles.example_title}>例子</div>
                                            {
                                                currentComponent.metadata?.rich_text ? (
                                                    <div className={styles.example_text} dangerouslySetInnerHTML={{ __html: currentComponent.metadata?.rich_text }} />
                                                ) : (
                                                    // <div className={styles.example_text} >{currentComponent.description}</div>
                                                    <div className={styles.example_text} >{""}</div>
                                                )
                                            }
                                    </div>
                                </>
                            )
                        }
                    </div>
                </div>

                <div className={styles.container_content_side}>
                    {
                        creating ? (
                            <Button type="primary" loading>
                                上架中...
                            </Button>
                        ) : (
                            <Button
                                type="primary"
                                className={styles.side_butn}
                                onClick={handleSubmit}
                            >
                                上架
                            </Button>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default ComponentListedPublish