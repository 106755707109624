import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'

import styles from './index.module.css'
import { actionCreators } from '../../store'

const ResendVerificationCode = () => {
    // 设置初始状态
    const dispatch = useDispatch()
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [timer, setTimer] = useState(60); // 假设倒计时时长为60秒
    let register_email = useSelector(state => state.get('login').get('register_email'))

    useEffect(() => {
        let interval;

        // 如果按钮被禁用，启动倒计时
        if (isButtonDisabled && timer > 0) {
            interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        }

        // 当倒计时结束时，重置状态
        if (timer === 0) {
            setIsButtonDisabled(false);
            clearInterval(interval);
        }

        // 清除interval以防止内存泄漏
        return () => clearInterval(interval);
    }, [isButtonDisabled, timer]);

    const handleResendClick = () => {
        // 模拟发送验证码操作
        console.log('验证码已发送!');

        // 禁用按钮并启动倒计时
        setIsButtonDisabled(true);
        setTimer(60); // 重置倒计时时间
        dispatch(actionCreators.getEmailVerificationCode(register_email))
    };

    return (
        <>
            {
                !isButtonDisabled ? (
                    <div className={styles.register}>
                        没有收到代码？
                        <a className={styles.register_link}
                            // onClick={() => { this.props.handleSetPageShowAction('register') }}
                            onClick={handleResendClick}
                        >
                            重新发送代码
                        </a>
                    </div>
                ) : (
                    <div className={styles.register}>已发送代码；重新发送需等待 {timer} 秒</div>
                )
            }
        </>
    );
};

export default ResendVerificationCode;
